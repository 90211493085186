
import React from "react";
import ReactTooltip from "react-tooltip";
import {API, Auth} from 'aws-amplify'

import {CONST} from '../../environments/environment'

// 1つのアプリケーションに属する環境の数（Web環境＋DBコネクト環境。現時点では2固定）
export const NUMBER_OF_ENV = 2

// 環境一覧取得APIの実行用
export async function executeGetAccount(){
	let session = await Auth.currentSession();
	let api_name = CONST.API_NAME;
	let path = CONST.API_PATH.ACCOUNT;
	let myInit = {
		headers: {
			Authorization: session.getIdToken().getJwtToken()
		},
		queryStringParameters: {
			accessToken: session.getAccessToken().getJwtToken()
		}
	};
	let response = await API.get(api_name, path, myInit);
	if ("errorMessage" in response) {
		throw response;
	}
	return response
}

// ### 