
// IE11対策
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {createStore} from 'redux'

// export defaultを書いてないと、{}でimportをするものを選ぶ
// import {switchReducer} from './reducers/SwitchComponent'
import {rootReducer} from './reducers/root'
import {Provider} from 'react-redux'

import {environment} from './environments/environment'
import Amplify from 'aws-amplify'

// IE対策（CDNの代わり）
import "es6-promise/auto"
import "fetch-polyfill"

// JQuery
import "jquery"

// popper.js (required by bootstrap4.js)
import "popper.js"

// bootstrap4.js
import "bootstrap"

// bootstrap.css
import './css/bootstrap-20190124.min.css'
import "./css/bootstrap-theme-20190124.min.css"

// w3.css
import "./css/w3.css"

// font awesome icon
import "./css/all.min.css"

// ### ローディングトーストテスト
import './css/loadingToast_test.css'

Amplify.configure(environment.amplify)

const store = createStore(rootReducer)
// const store2 = createStore(switchReducer)

ReactDOM.render(
    <Provider store={store}>
        {/* ここではAppコンポーネントにStoreを渡す→App内でContainerを呼び出している */}
        <App></App>
        {/* {console.log(store.getState())} */}
        {/* {console.log(store2.getState())} */}
        {/* {console.log(store.getState().switchComponent)} */}
    </Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
