import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import {CONST} from '../environments/environment'

import {switchEnvHealth} from './common/switchEnvHealth'

import {showEnvListByApplicationName, executeGetEnvList, SERVICE_TYPE} from './common/handlerEnv'


export default class EbEnvListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notExistFlag : false,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
		this.setModalMessage = this.setModalMessage.bind(this)
		this.recordCreater = this.recordCreater.bind(this)
  }
  componentWillMount() {
		// ローディングフラグがFalse（読み込み中でない）and 環境リストの長さが0の時だけ
    if (
      !this.props.ebInformation.loadingListFlag &&
      this.props.ebInformation.envList.length === 0
    ) {
      // console.log(this.props);
      this.getEnvList();
    }
  }

  refreshEnvList() {
    this.getEnvList();
  }

  // 【機能１】EB環境一覧取得
  async getEnvList() {
    try {
      await this.props.startLoading();
      await this.props.setListLoadingFlag();

			let response = await executeGetEnvList()

			// 現在は使用していないが、読み込んだものの、結果が0件だった場合にフラグを立てる
			if (response.length <= 0) {
				await this.setState({ notExistFlag: true})
			}
      await this.props.setEbEnvList(response);
      // await console.log(this.props.ebInformation.envList);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
        error_function: "実行環境一覧取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
      // エラー発生時のみローディングフラグをリセットする
      await this.props.resetListLoadingFlag();
    } finally {
      this.props.finishLoading();
    }
  }

	// 環境リストを受け取り、表に展開する関数
  recordCreater(array) {
    let list = [];
    array.map((data, index) => {
      list.push(
        <tr key={index}>
          {/* <td> {data.EnvId} </td> */}
          <td>
					{switchEnvHealth(data.EnvHealth)}
            <a
              href="#"
              className="text-primary"
              onClick={event =>
								// this.props.showEnvDetail(event, data.EnvName, data.CNAME, data.dbInstanceId, index, data.applicationType)
								this.props.showEnvDetail(event, data.EnvName, (data.EnvNumber === 0 ? "http://" : "https://") + data.CNAME, data.dbInstanceId, SERVICE_TYPE[data.applicationType])
              }
            >
              {data.EnvName}
            </a>
          </td>
          {/* <td> {data.EnvNumber} </td> */}
          {/* <td> {data.EnvHealth} </td>
          <td> {data.AppHealth} </td>
          <td> {data.EnvOperationalStatus} </td> */}
          <td> {data.VersionLabel} </td>
          {/* <td> {data.UpdateDate} </td> */}
          <td> {data.dbInstanceId} </td>
          <td>
            <a href={`${data.EnvNumber === 0 ? "http://" : "https://"}${data.CNAME}`} className="text-primary"
							target="blank"
							rel="noreferrer noopener"
						>
              {/* https://{data.CNAME} */}
							{/* 表示するURLは、DBコネクト環境はHTTP、Web環境はHTTPS固定
							※環境作成時の申込書にHTTPSか否かを選択する欄は設けない。DBコネクト環境はWPの制約でHTTPアクセス固定のため */}
							{data.EnvNumber === 0 ? "http://" : "https://"}{data.CNAME}
            </a>
          </td>
          {/* <td>
            <a
              href="#"
              className="text-primary"
              onClick={() => this.props.showEnvAccessKey(data.EnvName, index)}
            >
              アクセスキー
            </a>
          </td> */}
          <td>
            <a
              href="#"
              className="text-primary"
              onClick={event =>
                this.props.showEnvDeploy(
                  event,
                  data.EnvName,
                  data.VersionLabel,
									data.applicationName, 
									SERVICE_TYPE[data.applicationType]
                )
              }
            >
              デプロイ・
              <br />
              バージョン管理
            </a>
          </td>
        </tr>
      );
      return null;
    });
    return list;
	}

	showProductionEnvList(envList){
		let component = null
		// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
		envList = typeof envList === "undefined" ? [] : envList
		// 環境が存在しない場合はセクションごと表示しない
		if (envList.length > 0) {
			component = (
				<div className="card-body custom-scroll small m-3 border border-primary">
					<div className="h5 mb-4">
						<i className="fas fa-window-restore mr-2" />
						{SERVICE_TYPE["P"]}
					</div>
					{/* AWSアプリケーションごとに環境一覧をテーブルで表示する */}
					{showEnvListByApplicationName({envList: this.props.ebInformation.envList.productionEnvList, recordFunctionObject: this.recordCreater})}
					
					{/* 環境が存在しない場合にメッセージを表示する */}
					{/* {this.showNotExist()} */}
				</div>
			)
		}
		return component
	}
	showStagingEnvList(envList){
		let component = null
		// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
		envList = typeof envList === "undefined" ? [] : envList
		// 環境が存在しない場合はセクションごと表示しない
		if (envList.length > 0) {
			component = (
				<div className="card-body custom-scroll small m-3 border border-warning">
					<div className="h5 mb-4">
						<i className="fas fa-window-restore mr-2" />
						{SERVICE_TYPE["S"]}
					</div>
					{/* AWSアプリケーションごとに環境一覧をテーブルで表示する */}
					{showEnvListByApplicationName({envList: this.props.ebInformation.envList.stagingEnvList, recordFunctionObject: this.recordCreater})}

					{/* 環境が存在しない場合にメッセージを表示する */}
					{/* {this.showNotExist()} */}
				</div>
			)
		}
		return component
	}

	// 現在は使用していないが、環境取得結果が0件だったメッセージを返す関数
	showNotExist(){
		let message = null;
		if (this.state.notExistFlag) {
			message="実行環境は存在しません。"
		}
		return message
	}
	// エラーモーダルを表示する
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}

  render() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card mb-3">
          <div className="card-header h5">
            <div className="float-left mt-2">
              <i className="fas fa-cubes mr-2" />
              実行環境一覧
            </div>
            {/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
              onClick={() => this.refreshEnvList()}
              data-tip="アプリケーション・実行環境の一覧が更新されます。"
              data-html={true}
              data-for="refreshEnvToolTip"
            >
              <i className="fas fa-sync-alt" />

              <ReactTooltip id="refreshEnvToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>

					{/* ### 本番環境表示用　→　showEnvListByApplicationNameの引数に本番用の配列を入れる */}
					{this.showProductionEnvList(this.props.ebInformation.envList.productionEnvList)}
					
					{/* ### 検証環境表示用 */}
					{this.showStagingEnvList(this.props.ebInformation.envList.stagingEnvList)}
					
        </div>

        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
