// 定数群を宣言
export const VAR_TYPE = {
  // EB環境一覧のセット
  SET_EB_ENV_LIST: "SET_EB_ENV_LIST",
  CLEAR_EB_ENV_LIST: "RESET_EB_ENV_LIST",
  // ローディングフラグのセット
  SET_LIST_LOADING_FLAG: "SET_LIST_LOADING_FLAG",
	RESET_LIST_LOADING_FLAG: "RESET_LIST_LOADING_FLAG",
	
	SET_ENV_LIST_NOT_EXIST_FLAG: "SET_ENV_LIST_NOT_EXIST_FLAG",
	RESET_ENV_LIST_NOT_EXIST_FLAG: "RESET_ENV_LIST_NOT_EXIST_FLAG"
};

// #########################   EB LIST
export const setEbEnvList = envList => {
  return {
    type: VAR_TYPE.SET_EB_ENV_LIST,
    payload: {envList}
  };
};
export const clearEbEnvList = () => {
  return {
    type: VAR_TYPE.CLEAR_EB_ENV_LIST,
    payload: {}
  };
};
export const setListLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_LIST_LOADING_FLAG,
    payload: {}
  };
};
export const resetListLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_LIST_LOADING_FLAG,
    payload: {}
  };
};
export const setEnvListNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_ENV_LIST_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetEnvListNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_ENV_LIST_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
  setEbEnvList: setEbEnvList,
	clearEbEnvList: clearEbEnvList,
	setListLoadingFlag: setListLoadingFlag,
	resetListLoadingFlag: resetListLoadingFlag,
	setEnvListNotExistFlag: setEnvListNotExistFlag,
	resetEnvListNotExistFlag: resetEnvListNotExistFlag,
};
