import { connect } from "react-redux";

import UserListSection from "../components/UserListSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
		// combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
		userList:state.userListSection.userList,
		loadingUserFlag: state.userListSection.loadingUserFlag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// userList: list
		setUsers(userList){
			dispatch(actionCreater.userListSection.setUsers(userList))
		},
		// index: int, email: string
		updateUser(index,email){
			dispatch(actionCreater.userListSection.updateUser(index,email))
		},
		// index: int
		deleteUser(index){
			dispatch(actionCreater.userListSection.deleteUser(index))
		},
		// user: JSON
		addUser(user){
			dispatch(actionCreater.userListSection.addUser(user))
		},

		// 
		clearUsers(){
			dispatch(actionCreater.userListSection.clearUsers())
		},
		setUserLoadingFlag(){
			dispatch(actionCreater.userListSection.setUserLoadingFlag())
		},
		resetUserLoadingFlag(){
			dispatch(actionCreater.userListSection.resetUserLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListSection);
