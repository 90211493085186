import { connect } from "react-redux";

import GitCredentialSection from "../components/GitCredentialSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    bastionInfomation:{
			bastionGitCredential: state.gitCredentialSection.bastionGitCredential,
			loadingGitCredentialFlag: state.gitCredentialSection.loadingGitCredentialFlag,
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// AccessKey
		// env
		setBastionGitCredential(bastionGitCredential){
			dispatch(actionCreater.gitCredentialSection.setBastionGitCredential(bastionGitCredential))
		},
		// 
		clearBastionGitCredential(){
			dispatch(actionCreater.gitCredentialSection.clearBastionGitCredential())
		},
		// 
		setGitCredentialLoadingFlag(){
			dispatch(actionCreater.gitCredentialSection.setGitCredentialLoadingFlag())
		},
		// 
		resetGitCredentialLoadingFlag(){
			dispatch(actionCreater.gitCredentialSection.resetGitCredentialLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GitCredentialSection);
