import React, { Component } from "react";

export default class AppliVersionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_id: "id",
      customer_name: "name",
      customer_email: "email"
    };
  }
  async componentDidMount() {
    console.log("account section");
  }

  render() {
    return (
      // 左右の中央寄せは効くが、上下の中央寄せは、親要素の高さが無い？から出来ない
      <div className="section d-flex justify-content-center">
        {/* 全方向のマージンを入れているから中央寄せになっている？ */}
        <div className="card w-75 custom-card-height mt-5">
          <div className="card-header h5">
            <div className="float-left mt-2">
              {/* <i className="fas fa-users-cog mr-2" /> */}
              アプリケーション　バージョン管理
            </div>
            {/* 更新ボタン */}
            <button className={`btn btn-light float-right`} onClick={() => console.log("refresh")}>
              <i className="fas fa-sync-alt" />
            </button>
            <button
              type="button"
              className="btn btn-light float-right"
              onClick={() => this.props.showEnvList()}
            >
              環境一覧に戻る
              {/* <i className="ml-2 fas fa-angle-down" id="addCollapse" /> */}
            </button>
          </div>
          <div className="card-body">
					{this.props.envName}
            <table className="table table-bordered w-100">
              <tbody>
                <tr>
                  <th scope="row" className="custom-td-width">
                    アカウントID
                  </th>
                  <td className="">{this.state.account_id}</td>
                </tr>
                <tr>
                  <th scope="row" className="custom-td-width">
                    名前
                  </th>
                  <td>{this.state.customer_name}</td>
                </tr>
                <tr>
                  <th scope="row" className="custom-td-width">
                    メールアドレス
                  </th>
                  <td>{this.state.customer_email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
