import { connect } from "react-redux";

import AppliVersionSection from "../component/AppliVersionSection";
import { actionCreater } from "../../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    ebInfomation:{
			envVersion: state.appliVersionSection.envVersion,
			loadingVersionFlag: state.appliVersionSection.loadingVersionFlag,
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// Version
		// envVersion
		setEbEnvVersion(envVersion){
			dispatch(actionCreater.appliVersionSection.setEbEnvVersion(envVersion))
		},
		// 
		clearEbEnvVersion(){
			dispatch(actionCreater.appliVersionSection.clearEbEnvVersion())
		},
		// 
		setVersionLoadingFlag(){
			dispatch(actionCreater.appliVersionSection.setVersionLoadingFlag())
		},
		// 
		resetVersionLoadingFlag(){
			dispatch(actionCreater.appliVersionSection.resetVersionLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppliVersionSection);
