import React, { Component } from "react";

// フッターにレスポンシブはしない方が良い（トグルの方向を操作できない＋ドロップアップがレスポンシブ時にドロップダウンになる）
class Footer extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-bottom justify-content-around footer-height text-white">
        <small className="">© Canon IT Solutions Inc.</small>
        {/* 左マージンをAutoにすると、表示が右に寄るらしい */}
        {/* <ul className="nav ml-auto">
          <li className="nav-item ml-2">
            <small>
              <a href="#" target="blank">
                個人情報の取り扱い
              </a>
            </small>
          </li>
          <li className="nav-item ml-2">
            <small>
              <a href="#" target="blank">
                運営会社
              </a>
            </small>
          </li>
          <li className="nav-item ml-2">
            <small>
              <a href="#" target="blank">
                利用規約
              </a>
            </small>
          </li>
          <li className="nav-item ml-2">
            <small>
              <a href="#" target="blank">
                問い合わせ
              </a>
            </small>
          </li>
        </ul> */}
      </nav>
    );
  }
}

export default Footer;
