import React from "react";

// import './App.css';

import { RequireNewPassword } from "aws-amplify-react";

// どうやらAmplify　Configureは親コンポーネントにさえ書いてあればいいらしい
import { Auth } from "aws-amplify";
// import NavbarFooter from './NavbarFooter'

import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import { CONST } from "../../environments/environment";
import { checkAuthFlowApply } from "./checkAuthFlow";
import $ from "jquery";
import { showModal } from "../common/showModal";
import {
  // パスワード用バリデーション
  checkPasswordLength,
  checkPasswordContainsSpecialSymbol,
  checkPasswordContainsNumber,
  checkPasswordContainsLowerCase,
  checkPasswordContainsUpperCase,
  checkPasswordContainsSafeChar
} from "../common/cognitoInputValidation";
import { switchCognitoError } from "./switchCognitoError";

// const CONST_LOCAL = {
//   ID: {
//     INPUT_PASS1: "newPassword",
//     INPUT_PASS2: "newPasswordAgain"
//   },
//   VALUE: {
//     HEADER_PASS: "新しいパスワードを入力",
//     INPUT_PASS1: "新しいパスワード",
//     INPUT_PASS2: "新しいパスワード（確認）",
//     SUBMIT_PASS: "新しいパスワードを設定",
//     BACK_SIGN_IN: "サインイン画面に戻る"
//   },
//   MESSAGE: {
//     ERROR: {
//       PASS_NOSAME: "パスワードが一致していません"
//     }
//   }
// };

class MyRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      password1: "",
			password2: "",
			
			error_function: null,
			error_message: null,
    };
		this.completeNewPasswordFunction = this.completeNewPasswordFunction.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
	}
	// ### Stateを更新する関数群
  handleChangePassword1(event) {
    this.setState({ password1: event.target.value });
  }
  handleChangePassword2(event) {
    this.setState({ password2: event.target.value });
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
	}
	// パスワードの全バリデーション結果を返す関数
  passwordAllValidationFlag() {
    return (
      checkPasswordLength(this.state.password1) &&
      checkPasswordContainsLowerCase(this.state.password1) &&
      checkPasswordContainsUpperCase(this.state.password1) &&
      checkPasswordContainsNumber(this.state.password1) &&
      checkPasswordContainsSpecialSymbol(this.state.password1) &&
      checkPasswordContainsSafeChar(this.state.password1) &&
      this.state.password1 === this.state.password2
    );
	}
	// バリデーション結果によってアイコンを切り替える
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	// 入力規則チェック用セクション
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th scope="row" rowSpan="7" className="custom-td-width">
                {this.switchValidationIcon(this.passwordAllValidationFlag())}パスワード
              </th>
              <td>
                {this.switchValidationIcon(checkPasswordLength(this.state.password1))}
                長さは8文字以上、256文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsLowerCase(this.state.password1))}
                小文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsUpperCase(this.state.password1))}
                大文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsNumber(this.state.password1))}
                数字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(
                  checkPasswordContainsSpecialSymbol(this.state.password1)
                )}
                次の特殊文字が1文字以上必要です。　特殊文字：　
                {"^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"}
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsSafeChar(this.state.password1))}
                半角アルファベットの小文字・大文字、数字、上記特殊文字、「+」、「=」以外の文字はご使用いただけません。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(this.state.password1 === this.state.password2)}
                「新しいパスワード」と「新しいパスワード（確認）」でご入力いただいたパスワードが一致している必要があります。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

	// 新しいパスワードを設定する関数
  async completeNewPasswordFunction() {
    try {
			// 念のためパスワード一致チェックをしているが、ボタンが非活性になっているはずのため、不要か。
      if (this.state.password1 !== this.state.password2) {
        throw "パスワードが一致していません。";
      }
      const password = this.state.password1;
      await this.setState({ loading: true });
      let user = await Auth.completeNewPassword(this.props.authData, password);
      // 基本ここでsignedInになることは無い
      await checkAuthFlowApply.apply(this, [user]);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "新しいパスワードの設定",
				error_message: switchCognitoError(error)
			})
      $("#generalFailure").modal();
    } finally {
			// ローディングを終える
      await this.setState({ loading: false });
    }
  }

	// サインイン画面に戻る用
	showSignIn(event){
		event.preventDefault()
		this.changeState(CONST.AMPLIFY.AUTH_STATE.SIGN_IN)
	}

	// カードボディ
  cardBody() {
    let component = null;
    component = (
      <React.Fragment>
        {/* 新しいパスワード入力 */}
        {/* 入力ボックス */}
        <div>
				<i className="fas fa-info-circle text-primary mr-2" />この画面では、お客様ご自身でパスワードを変更していただくことが出来ます。<br/>
				<i className="fas fa-info-circle text-primary mr-2" />パスワードはセキュリティ向上のため、ページ下部に記載されている入力規則を満たす必要があります。<br/>
				<br/>
          {/* labelが指定されている場合のみ表示する */}
          <label htmlFor="newPassword"> 新しいパスワード： </label>
          <div className={`input-group mb-3`}>
            {/* iconが指定されている場合のみ表示する */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.PASSWORD} />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              placeholder="新しいパスワード"
              autoComplete="new-password"
              value={this.state.password1}
              onChange={event => this.handleChangePassword1(event)}
            />
          </div>
        </div>
        {/* 入力ボックス：終了 */}
        {/* 新しいパスワード入力（確認） */}

        {/* 入力ボックス */}
        <div>
          {/* labelが指定されている場合のみ表示する */}
          <label htmlFor="newPasswordAgain"> 新しいパスワード（確認）： </label>
          <div className={`input-group mb-3`}>
            {/* iconが指定されている場合のみ表示する */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.PASSWORD} />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              id="newPasswordAgain"
              placeholder="新しいパスワード（確認）"
              autoComplete="new-password"
              value={this.state.password2}
              onChange={event => this.handleChangePassword2(event)}
            />
          </div>
        </div>
				{/* 入力ボックス：終了 */}

				{/* 入力規則チェック用セクション */}
				{this.showValidationComponent()}
				
        <div className="mt-4">
          {/* 新しいパスワード設定ボタン */}
          <button
            className="btn btn-block btn-primary"
            onClick={() => this.completeNewPasswordFunction()}
            disabled={!this.passwordAllValidationFlag() || this.state.loading}
          >
            新しいパスワードを設定
          </button>
					<hr/>
          {/* サインイン画面に戻るボタン */}
					<a
						href="#"
            className="text-primary"
            onClick={(event) => this.showSignIn(event)}
          >
            サインイン画面に戻る
          </a>
        </div>
      </React.Fragment>
    );
    return component;
	}
	// カード全体
	showCardComponent() {
    return (
      <div className="section d-flex justify-content-center">
        {/* 全方向のマージンを入れているから中央寄せになっている？ */}
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-lock mr-2" />
              新しいパスワードを入力
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.cardBody()}</div>
        </div>
      </div>
    );
  }

	// AuthStateが新しいパスワード設定の場合だけコンポーネントを表示する
  showComponent() {
    var component = null;
    if (this.props.authState === CONST.AMPLIFY.AUTH_STATE.PASSWORD.FIRST_SIGN_IN) {
      component = (
        <div className="">
          {/* ################################### */}
          {/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
          <div className="content mybgcolor-grey mb-4">
            <div className="">
              <NavbarForLogin />
              <div id="" className="">
                <div id="" className="">
                  <div id="" className="hiddenSidebarSignIn">
                    {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                    {showLoadingToast(this.state.loading)}
                  </div>
                  {/* カード */}
                  {this.showCardComponent()}
                </div>
              </div>
							<Footer />
            </div>
						
          </div>
          {/* ################################### */}
          {/* モーダル：エラー */}
          {showModal({
            modalId: "generalFailure",
            modalTitle: (
              <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                エラー
              </span>
            ),
            modalBody: this.setModalMessage(),
            executeButtonLabel: "OK",
            showCancelButtonFlag: false,
            executeFunctionObject: this.emptyReturn
          })}
        </div>
      );
    }
    return component;
  }


  render() {
    return this.showComponent();
  }
}

export default MyRequireNewPassword;
