import React from "react";

import { SignIn } from "aws-amplify-react";

// どうやらAmplify　Configureは親コンポーネントにさえ書いてあればいいらしい
import { Auth } from "aws-amplify";
import { CONST } from "../../environments/environment";
import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";
import { checkAuthFlowApply } from "./checkAuthFlow";
import { switchCognitoError } from './switchCognitoError'

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import $ from "jquery";
import { showModal } from "../common/showModal";

import {
  // パスワード用バリデーション
  checkPasswordLength,
  checkPasswordContainsSpecialSymbol,
  checkPasswordContainsNumber,
  checkPasswordContainsLowerCase,
  checkPasswordContainsUpperCase,
  checkPasswordContainsSafeChar,

  // ユーザー名用バリデーション
  checkUserNameLength,
  checkUserNameContainsSafeChar
} from "../common/cognitoInputValidation";

class MyLogin extends SignIn {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      radio: null,
      user_name: "",
      password: "",
			update_state: false,
			
			error_function: null,
			error_message: null,
    };
		this.signInFunction = this.signInFunction.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
  }

	// ### Stateを更新する関数群
  handleChangeUserName(event) {
    this.setState({ user_name: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
	}
	
	// ユーザー名の全バリデーション結果を返す関数
  userNameAllValidationFlag() {
    return (
      checkUserNameLength(this.state.user_name) &&
      checkUserNameContainsSafeChar(this.state.user_name)
    );
	}
	
	// パスワードの全バリデーション結果を返す関数
  passwordAllValidationFlag() {
    return (
      checkPasswordLength(this.state.password) &&
      checkPasswordContainsLowerCase(this.state.password) &&
      checkPasswordContainsUpperCase(this.state.password) &&
      checkPasswordContainsNumber(this.state.password) &&
      checkPasswordContainsSpecialSymbol(this.state.password) &&
      checkPasswordContainsSafeChar(this.state.password)
    );
	}
	
	// バリデーション結果によってアイコンを切り替える関数
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	// 入力規則チェック用セクション
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th scope="row" rowSpan="2" className="custom-td-width">
                {this.switchValidationIcon(this.userNameAllValidationFlag())}
                ユーザー名
              </th>
              <td>
                {this.switchValidationIcon(checkUserNameLength(this.state.user_name))}
                長さは1文字以上、128文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkUserNameContainsSafeChar(this.state.user_name))}
                半角アルファベットの小文字・大文字、数字、「-」、「_」以外の文字はご使用いただけません。
              </td>
            </tr>
            <tr>
              <th scope="row" rowSpan="6">
                {this.switchValidationIcon(this.passwordAllValidationFlag())}パスワード
              </th>
              <td>
                {this.switchValidationIcon(checkPasswordLength(this.state.password))}
                長さは8文字以上、256文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsLowerCase(this.state.password))}
                小文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsUpperCase(this.state.password))}
                大文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsNumber(this.state.password))}
                数字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsSpecialSymbol(this.state.password))}
                次の特殊文字が1文字以上必要です。　特殊文字：　
                {"^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"}
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsSafeChar(this.state.password))}
                半角アルファベットの小文字・大文字、数字、上記特殊文字、「+」、「=」以外の文字はご使用いただけません。
              </td>
            </tr>
          </tbody>
        </table>
        ※ブラウザのオートコンプリート機能を使用されている場合は、一度入力ボックスをクリックしていただくことで入力規則チェックが適用されます。
      </div>
    );
  }

	// サインインする関数
  async signInFunction() {
    try {
      const userName = this.state.user_name;
      const passWord = this.state.password;
      await this.setState({ loading: true });
      await this.authSignIn(userName, passWord);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "サインイン",
				error_message: switchCognitoError(error)
			})
      $("#generalFailure").modal();
    } finally {
      // tryだろうがcatchに入ろうが、必ず実行されるセクション。必ずloading:falseにする
      await this.setState({ loading: false });
    }
	}
	// エラーモーダルにメッセージをセットする
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
  }

	// サインインをし、次の画面に遷移させる関数
  async authSignIn(userName, passWord) {
    // ここでエラーハンドリングしなくても、上のトライ句でキャッチできる
    let user = await Auth.signIn(userName, passWord); //.catch(error => { throw error })
    checkAuthFlowApply.apply(this, [user]);
  }

	// パスワードリセット画面を表示する関数
	showForgotPassword(event){
		// URLが変わらないようにする
		event.preventDefault()
		this.changeState(CONST.AMPLIFY.AUTH_STATE.PASSWORD.FORGOT)
	}

	// カードボディ
  cardBody() {
    let component = null;
    component = (
      <React.Fragment>
        {/* ユーザー名入力 */}
        {/* 入力ボックス */}
        <div>
          <label htmlFor="inputUserName"> ユーザー名： </label>
          <div className={`input-group mb-3`}>
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.USER} />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="inputUserName"
              placeholder="ユーザー名"
              // ブラウザに記憶させている場合、貫通してくる。Stateに値は設定されている
              autoComplete="new-password"
              value={this.state.user_name}
              onChange={event => this.handleChangeUserName(event)}
            />
          </div>
        </div>
        {/* 入力ボックス：終了 */}
        {/* 入力ボックス */}
        <div>
          <label htmlFor="inputPassword"> パスワード： </label>
          <div className={`input-group mb-3`}>
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.PASSWORD} />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              id="inputPassword"
              placeholder="パスワード"
              autoComplete="new-password"
              value={this.state.password}
              onChange={event => this.handleChangePassword(event)}
            />
          </div>
        </div>
				{/* 入力規則チェック用セクション */}
        {this.showValidationComponent()}
        {/* 入力ボックス：終了 */}
        <div className="mt-4">
          {/* サインインボタン */}
          <button
            id="signInButton"
            className="btn btn-block btn-primary"
            onClick={() => this.signInFunction()}
						disabled={!(this.userNameAllValidationFlag() && this.passwordAllValidationFlag()) 
							|| this.state.loading}
          >
            サインイン
          </button>
					<hr/>
          {/* パスワードリセットボタン */}
          <a
						href="#"
            className="text-primary"
            onClick={(event) => this.showForgotPassword(event)}
          >
            パスワードを忘れた方はこちら
          </a>
        </div>
      </React.Fragment>
    );
    return component;
	}
	// カード全体
  showCardComponent() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-sign-in-alt mr-2" />
              サインイン
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.cardBody()}</div>
        </div>
      </div>
    );
  }

	// AuthStateがサインインの時だけサインイン画面を表示する
  showComponent() {
    var component = null;
    if (this.props.authState === CONST.AMPLIFY.AUTH_STATE.SIGN_IN) {
      component = (
        <div className="">
          {/* ################################### */}
          {/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
          <div className="content mybgcolor-grey mb-4">
            <div className="">
              <NavbarForLogin />
              <div id="" className="">
                <div id="" className="">
                  <div id="" className="hiddenSidebarSignIn">
                    {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                    {showLoadingToast(this.state.loading)}
                  </div>
                  {/* カード */}
                  {this.showCardComponent()}
                </div>
              </div>
							<Footer />
            </div>
          </div>
          {/* ################################### */}
          {/* モーダル：エラー */}
          {showModal({
            modalId: "generalFailure",
            modalTitle: (
              <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                エラー
              </span>
            ),
            modalBody: this.setModalMessage(),
            executeButtonLabel: "OK",
            showCancelButtonFlag: false,
            executeFunctionObject: this.emptyReturn
          })}
        </div>
      );
    }
    return component;
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
  }

  render() {
    return this.showComponent();
  }
}

export default MyLogin;
