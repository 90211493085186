
// Reactをimportしないと、JSX関連のエラーが出る（HTMLタグに反応している？）
import React from "react";

// 定数　インポート
import { SWITCH_TYPE } from "../../actions/SwitchComponent";

// 表示したい子コンポーネント　インポート
// import TopSection from "../TopSection";
import TopSection from '../../containers/TopSection'
// import AccountSection from "../AccountSection";
import AccountSection from '../../containers/AccountSection'
import AccessKeySection from "../AccessKeySection";
// import EbEnvListSection from "../EbEnvListSection";
// import EbEnvDetailSection from "../EbEnvDetailSection";
import EbEnvDetailSection from '../../containers/EbEnvDetailSection'
import EbSwitchComponent from '../EbSwitchComponent'
//import WorkspaceListSection from '../../containers/WorkspaceListSection'
// import EbSwitchComponent from '../../containers/EbSwitchComponent'
import AppliDeploySection from "../AppliDeploySection";
// import AppliVersionSection from "../AppliVersionSection";
// import UserListSection from "../UserListSection";
import UserListSection from '../../containers/UserListSection'
// import NoticeSection from "../NoticeSection";
import NoticeSection from '../../containers/NoticeSection'
import ClientEnvListSection from "../../containers/ClientEnvListSection";
import GitCredentialSection from '../../containers/GitCredentialSection'

// propsのshow○○を受け取る必要あり
export function switchComponent({
  currentSwitchType = SWITCH_TYPE.SHOW_TOP,
  startLoadingFuncObject = emptyReturn,
	finishLoadingFuncObject = emptyReturn,
	setLoginUserNameFuncObject = emptyReturn,
	loginUserName = "",
	is_sign_out = false,
	// トップから無理やり環境詳細を呼び出すために使用
	rds_name = "",
	env_name = "",
	env_endpoint = "",
	app_type = "環境",
	showEnvListFuncObject = emptyReturn,
	showEnvDetailFuncObject = emptyReturn,
	bastion_name = "",
	showClientEnvListFuncObject = emptyReturn,
	showGitCredentialFuncObject = emptyReturn,

	is_root_loading = false,
}) {
  let component = null;
  switch (currentSwitchType) {
    case SWITCH_TYPE.SHOW_TOP:
      component = (
        <TopSection 
					setLoginUserName={(userName)=>setLoginUserNameFuncObject(userName)}
					startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					loginUserName={loginUserName}
					is_sign_out={is_sign_out}
					isRootLoading={is_root_loading}
					// 環境詳細を無理やり呼び出す。（引数をちゃんと書かないとundefinedが渡る）
					showEnvDetail = {(env_name,rds_name,env_endpoint,app_type)=>showEnvDetailFuncObject(env_name,rds_name,env_endpoint,app_type)}
				/>
      );
      break;
    case SWITCH_TYPE.SHOW_ACCOUNT:
      component = (
        <AccountSection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					isRootLoading={is_root_loading}
        />
      );
      break;

    case SWITCH_TYPE.SHOW_ACCESS_KEY:
      component = (
        <AccessKeySection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					isRootLoading={is_root_loading}
        />
      );
      break;
    case SWITCH_TYPE.SHOW_EB_ENV_LIST:
      component = (
        // <EbEnvListSection
        //   startLoading={() => startLoadingFuncObject()}
        //   finishLoading={() => finishLoadingFuncObject()}
        // />
				<EbSwitchComponent
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					isRootLoading={is_root_loading}
        />
      );
      break;
		case SWITCH_TYPE.SHOW_CLIENT_ENV_LIST:
			component = (
				// <EbEnvListSection
				//   startLoading={() => startLoadingFuncObject()}
				//   finishLoading={() => finishLoadingFuncObject()}
				// />
				<ClientEnvListSection
					startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					showGitCredential={(bastion_name) => showGitCredentialFuncObject(bastion_name)}
					isRootLoading={is_root_loading}
				/>
			);
			break;
		case SWITCH_TYPE.SHOW_GIT_CREDENTIAL:
			component = (
				<GitCredentialSection
					startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					showClientEnvList={() => showClientEnvListFuncObject()}
					bastionName={bastion_name}
					isRootLoading={is_root_loading}
				/>
			);
			break;
    case SWITCH_TYPE.SHOW_EB_ENV_DETAIL:
      component = (
        <EbEnvDetailSection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					showEnvList={()=>showEnvListFuncObject()}
					rdsName={rds_name}
					envName={env_name}
					envEndpoint={env_endpoint}
					appType={app_type}
					isRootLoading={is_root_loading}
        />
      );
      break;
    case SWITCH_TYPE.SHOW_APPLI_DEPLOY:
      component = (
        <AppliDeploySection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					isRootLoading={is_root_loading}
        />
      );
      break;
    // case SWITCH_TYPE.SHOW_APPLI_VERSION:
    //   component = (
    //     <AppliVersionSection
    //       startLoading={() => startLoadingFuncObject()}
    //       finishLoading={() => finishLoadingFuncObject()}
    //     />
    //   );
    //   break;
    case SWITCH_TYPE.SHOW_USER_LIST:
      component = (
        <UserListSection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					login_user_name={loginUserName}
					isRootLoading={is_root_loading}
        />
      );
      break;
    case SWITCH_TYPE.SHOW_NOTICE:
      component = (
        <NoticeSection
          startLoading={() => startLoadingFuncObject()}
					finishLoading={() => finishLoadingFuncObject()}
					isRootLoading={is_root_loading}
        />
      );
      break;

    default:
      // どの状態にも当てはまらない場合の処理。通常はありえない
      component = <div>no switch type</div>;
      break;
  }
  return component;
}

function emptyReturn(){
	return null
}