// 定数群を宣言
export const VAR_TYPE = {
  // EB ACCESS_KEYのセット
  SET_EB_ENV_ACCESS_KEY: "SET_EB_ENV_ACCESS_KEY",
  CLEAR_EB_ENV_ACCESS_KEY: "RESET_EB_ENV_ACCESS_KEY",
  // ローディングフラグのセット
  SET_ACCESS_KEY_LOADING_FLAG: "SET_ACCESS_KEY_LOADING_FLAG",
	RESET_ACCESS_KEY_LOADING_FLAG: "RESET_ACCESS_KEY_LOADING_FLAG",
	
	SET_ACCESS_KEY_NOT_EXIST_FLAG: "SET_ACCESS_KEY_NOT_EXIST_FLAG",
	RESET_ACCESS_KEY_NOT_EXIST_FLAG: "RESET_ACCESS_KEY_NOT_EXIST_FLAG"
};

// #########################   EB ACCESS_KEY
export const setEbEnvAccessKey = envAccessKey => {
  return {
    type: VAR_TYPE.SET_EB_ENV_ACCESS_KEY,
    payload: {envAccessKey}
  };
};
export const clearEbEnvAccessKey = () => {
  return {
    type: VAR_TYPE.CLEAR_EB_ENV_ACCESS_KEY,
    payload: {}
  };
};
export const setAccessKeyLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_ACCESS_KEY_LOADING_FLAG,
    payload: {}
  };
};
export const resetAccessKeyLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_ACCESS_KEY_LOADING_FLAG,
    payload: {}
  };
};
export const setAccessKeyNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_ACCESS_KEY_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetAccessKeyNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_ACCESS_KEY_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
	setEbEnvAccessKey: setEbEnvAccessKey,
  clearEbEnvAccessKey: clearEbEnvAccessKey,
  setAccessKeyLoadingFlag: setAccessKeyLoadingFlag,
	resetAccessKeyLoadingFlag: resetAccessKeyLoadingFlag,
	setAccessKeyNotExistFlag: setAccessKeyNotExistFlag,
	resetAccessKeyNotExistFlag: resetAccessKeyNotExistFlag,
};
