import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { CONST } from "../environments/environment";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

// アクセスキーを取得する件数
export const ACCESS_KEY_MAX_ITEMS = 20

export default class AccessKeySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // アクセスキー一覧（配列にJSONが入って返ってくる）
      access_key_list: [],
      access_key_max_items: 3,
      // グループ内のユーザー数が取り切れなかった場合のページング処理用
      access_key_marker: [null],
      // Trueなら残りがある。Falseなら全て取り切った
      access_key_is_remained_flag: true,
      access_key_page: 0,

      created_access_key_id: null,
      created_secret_access_key: null,
      created_user_number: null,

      // 削除するユーザー名の末尾
      delete_user_number: null,
      delete_access_key_id: null,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
    this.addAccessKey = this.addAccessKey.bind(this);
    this.deleteAccessKey = this.deleteAccessKey.bind(this);
    this.showSecretAccessKey = this.showSecretAccessKey.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
  }
  async componentDidMount() {
    await this.getAccessKeyListPaging(0);
  }

	// 更新するマーカーの位置を切り替える関数
  switchUpdateMarker(continue_flag, marker_list, new_marker, page) {
    if (continue_flag) {
      // ファイルを最後まで取得しきれていない→マーカーを追加する
      if (page >= marker_list.length - 1) {
        // ページ番号がマーカーリストの最後の要素番号であれば、追加する
        marker_list.push(new_marker);
      } else {
        // 現在表示しているページの次のマーカーを更新
        marker_list[page + 1] = new_marker;
      }
    }
    return marker_list;
  }

  // 【機能1】Access Key Listをページングで取得する関数
  async getAccessKeyListPaging(page) {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.ACCESSKEY;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName: this.props.applicationName,
          marker: this.state.access_key_marker[page],
					maxItems: ACCESS_KEY_MAX_ITEMS
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
			}
			// アクセスキーを作成日時でソートする
			let accessKeyListSorted = this.sortObjectArrayDesc(response.accessKeyList)
      await this.setState({
				access_key_list: accessKeyListSorted,
        access_key_is_remained_flag: response.isRemainedFlag
      });
      await this.setState({
        db_log_detail_marker: this.switchUpdateMarker(
          this.state.access_key_is_remained_flag,
          this.state.access_key_marker,
          response.marker,
          page
				),
				// 成功した場合のみページをセットする
				access_key_page: page,
      });
      // await this.props.setEbEnvDetail(response);
      // await console.log(this.props.ebInfomation.envDetail);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
        error_function: "アクセスキー一覧取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
			$("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
	}
	// JSONの配列を、更新日時で降順に並び替える
	sortObjectArrayDesc(object_array){
		object_array.sort(function(a,b){
			if (a.createDate > b.createDate) {
				return -1
			}
			if (a.createDate < b.createDate) {
				return 1
			}
			return 0
		})
		return object_array
	}

	// 【機能2】アクセスキーを発行する関数
  async addAccessKey() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.ACCESSKEY;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName: this.props.applicationName
        }
      };
      let response = await API.post(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      await this.setState({
        created_access_key_id: response.accessKeyId,
        created_secret_access_key: response.secretAccessKey,
        created_user_number: response.userNumber
      });
      // 取得が完了したら表示を更新する。配列にPushも可能だが、ページング処理的に見た目がおかしくなる
      // シークレットアクセスキーを表示するためのモーダルを表示する（背景クリックでモーダルを消させない）
      $("#addSuccess").modal({ backdrop: "static" });
      await this.getAccessKeyListPaging(0);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
        error_function: "アクセスキー追加",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

  // 【機能2】アクセスキーを削除する関数
  async deleteAccessKey() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.ACCESSKEY;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName: this.props.applicationName,
          accessKeyId: this.state.delete_access_key_id,
          // アクセスキー連番　＝　ユーザー末尾の番号
          accessKeySequence: this.state.delete_user_number
        }
      };
      let response = await API.del(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // 取得が完了したら表示を更新する。配列にPushも可能だが、ページング処理的に見た目がおかしくなる
      $("#deleteSuccess").modal();
      await this.getAccessKeyListPaging(0);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
        error_function: "アクセスキー削除",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
	}
	// 削除用モーダルを表示する
  async deleteCommit(userNumber, accessKeyId) {
    await this.setState({
      delete_user_number: userNumber,
      delete_access_key_id: accessKeyId
    });
    await $("#deleteConfirm").modal();
  }

	// アクセスキーリストを表に展開する
  recordCreater(array) {
    let list = [];
    array.map((data, index) => {
      list.push(
        <tr key={index}>
          {/* <td> {data.userNumber} </td> */}
          <td> {data.accessKeyId} </td>
          <td> {data.accessKeyStatus} </td>
          <td> {data.createDate.slice(0, -3)} </td>
          <td>
						<button
              className={`btn btn-danger btn-sm`}
              onClick={() => this.deleteCommit(data.userNumber, data.accessKeyId)}
            >
              {/* 削除 */}
              <i className="fas fa-trash-alt" />
            </button>
          </td>
        </tr>
      );
    });
    return list;
	}
	// アクセスキー発行成功時にシークレットキーを表示するモーダル用コンポーネント
  showSecretAccessKey() {
    let component = null;
    // secret access keyが取得できている間だけ表示する
    if (this.state.created_secret_access_key !== null) {
      component = (
        <div className="">
          {/* No
          <input
            type="text"
            readOnly={true}
            value={this.state.created_user_number}
            className="form-control"
          />
          <br /> */}
          アクセスキーID
          <input
            type="text"
            readOnly={true}
            value={this.state.created_access_key_id}
            className="form-control"
          />
          <br />
          シークレットキー
          <input
            type="text"
            readOnly={true}
            value={this.state.created_secret_access_key}
            className="form-control"
          />
          <br />
          <br />
          <strong className="text-danger">
            ※シークレットキーは今この画面でのみ確認が可能です。再表示することはできませんので、ご注意ください。
          </strong>
        </div>
      );
    }
    return component;
	}
	// 削除成功時のモーダル表示用コンポーネント
  showDeleteSuccess(message) {
    let component = (
      <div>
        {message}
        <br />
        <br />
        {/* No　　　　　　：　{this.state.delete_user_number}
        <br /> */}
        アクセスキーID：　{this.state.delete_access_key_id}
        <br />
      </div>
    );
    return component;
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージを表示する
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
  }

  render() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
            <div className="float-left mt-2">
              <i className="fas fa-key mr-2" />
              アクセスキー
            </div>
            {/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
							onClick={() => this.getAccessKeyListPaging(0)}
							data-tip="アクセスキーの一覧が更新されます。"
              data-html={true}
              data-for="refreshAccessKeyToolTip"
            >
              <i className="fas fa-sync-alt" />
							<ReactTooltip id="refreshAccessKeyToolTip" effect="solid" place="top" html={true} />
            </button>
						{/* 戻るボタン */}
            <button
              type="button"
              className="btn btn-light float-right"
              onClick={() => this.props.showEnvList()}
              data-tip="アプリケーション・環境の一覧画面に戻ります。"
              data-html={true}
              data-for="backAccessKeyToolTip"
            >
              <i className="fas fa-reply mr-2" />
              環境情報一覧に戻る
              <ReactTooltip id="backAccessKeyToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>
          <div className="card-body custom-scroll small">
            <span className="h5">
              <i className="fas fa-window-restore mr-2" />
							AWSアプリケーション名: {this.props.applicationName}
							{/* アクセスキー発行ボタン */}
              <button className="btn btn-primary ml-5" onClick={() => $("#addConfirm").modal()}>
                <i className="fas fa-plus-circle mr-2" />
                アクセスキーを追加
              </button>
            </span>

            <table className="table table-bordered custom-table-width mt-2">
              <thead>
                <tr>
                  {/* <th scope="col" className="custom-td-width-number">
                    No
                  </th> */}
                  <th scope="col" className="custom-td-width-eb-env-name-long">
                    アクセスキーID
                  </th>
                  <th scope="col" className="custom-td-width-alpha7">
                    ステータス
                  </th>
                  <th scope="col" className="custom-td-width-eb-day-long">
                    作成日時
                  </th>
                  <th scope="col" className="custom-td-width-number">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>{this.recordCreater(this.state.access_key_list)}</tbody>
            </table>
          </div>
					{/* ページ用セクション */}
          <div className="text-center">
            <button
              className="btn"
              onClick={() => this.getAccessKeyListPaging(this.state.access_key_page - 1)}
              disabled={this.state.access_key_page <= 0}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {this.state.access_key_page + 1}
            </button>
            <button
              className="btn"
              onClick={() => this.getAccessKeyListPaging(this.state.access_key_page + 1)}
              disabled={!this.state.access_key_is_remained_flag}
            >
              次
            </button>
          </div>
        </div>

        {/* モーダル：アクセスキー追加確認 */}
        {/* 追加確認 */}
        {showModal({
          modalId: "addConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: (
            <div>
              アクセスキーを追加してもよろしいですか？
              <br />
              <br />
              <strong className="text-danger">
                ※シークレットキーは発行時に1度のみ表示され、再表示することはできませんので、ご注意ください。
              </strong>
            </div>
          ),
          executeFunctionObject: this.addAccessKey
        })}
        {/* 追加成功 */}
        {showModal({
          modalId: "addSuccess",
          modalTitle: (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2" />
              成功
            </span>
          ),
          modalBody: this.showSecretAccessKey(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}

        {/* 追加失敗 */}
        {showModal({
          modalId: "addFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: "エラーが発生しました。",
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
        {/* モーダル */}

        {/* モーダル：アクセスキー削除確認・成功・失敗 */}
        {/* 削除確認 */}
        {showModal({
          modalId: "deleteConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: this.showDeleteSuccess("以下のアクセスキーを削除してもよろしいですか？"),
          executeFunctionObject: this.deleteAccessKey,
					executeButtonColor: "danger"
        })}
        {/* 削除成功 */}
        {showModal({
          modalId: "deleteSuccess",
          modalTitle: (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2" />
              成功
            </span>
          ),
          modalBody: this.showDeleteSuccess("以下のアクセスキーを削除しました。"),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
        {/* 削除失敗 */}
        {showModal({
          modalId: "deleteFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: "エラーが発生しました。",
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
				})}
				{/* エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
