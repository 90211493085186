import { VAR_TYPE, actions } from "../actions/UserListSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない。リストじゃないとエラーが出るはず
	userList: [],
	loadingUserFlag: false,
};

// Storeを更新するための関数
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
		// 全てのユーザー情報をセットする
    case VAR_TYPE.SET_USERS:
			// console.log("Set Users in UserListSection Reducer")
			// console.log(action.payload.userList)
      return {
				...state,
				userList: action.payload.userList,
			};
		// ユーザー情報を更新する
		case VAR_TYPE.UPDATE_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を変更する
			let tmpUserList = state.userList
			tmpUserList[action.payload.index].email = action.payload.email
      return {
				...state,
				userList: tmpUserList,
			};
		// ユーザー情報を削除する
		case VAR_TYPE.DELETE_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を削除する
			// index番目のユーザーから1人を削除する
			state.userList.splice(action.payload.index,1)
      return {
				...state,
				// sliceすることで新しい配列にする（React画面を再描画させるため）
				userList: state.userList.slice(),
			};
		// ユーザー情報を追加する
		case VAR_TYPE.ADD_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を追加する
      return {
				...state,
				// 恐らくリストとリストを連結する関数のため、リストで囲む
				userList: state.userList.concat([action.payload.user]),
			};
		// 全てのユーザー情報をリセットする
		case VAR_TYPE.CLEAR_USERS:
			// console.log("Clear Users in UserListSection Reducer")
      return {
				...state,
				userList: [],
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_USER_LOADING_FLAG:
      return {
				...state,
				loadingUserFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_USER_LOADING_FLAG:
			// console.log("Initial User List in UserListSection")
			// console.log(state.userList)
      return {
				...state,
				loadingUserFlag: false
			};
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
