import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import moment, { version } from 'moment'
import { showModal } from "./common/showModal";
import $ from 'jquery'; 
import ReactTooltip from "react-tooltip";

export default class AppliDeploySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filedata : null,
      new_app_version : "",
      description : "",
      deploy_message : null,
      version_list: [], //バージョン情報のJSON配列（version, description, createDate） 
      next_token: [null],
      version_page: 0,
      version_remain_flag: true,
      back_version: "",
      delete_version: "",
      is_updating: false,
      update_id: null,
      version_index: 0,
      is_loading: false,
      error_message: null
    };
    this.initialDisplayVersion = this.initialDisplayVersion.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onClickRefreshButton = this.onClickRefreshButton.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClickPageButton = this.onClickPageButton.bind(this)
    this.onClickBackVersion = this.onClickBackVersion.bind(this)
    this.onClickDeleteVersion = this.onClickDeleteVersion.bind(this)
  }

/*
 * ###### React 処理用 ######  
 */

  componentDidMount(){
    this.initialDisplayVersion()
  }

  onFormSubmit(e){
    e.preventDefault()
    this.onClickDeploy(this.state.filedata)
  }

  onChange(e) {
    var m = moment(); //現在の時刻が入る
    var date = m.format('YYYYMMDD-HHmmss');

    if (e.target.files.length != 0){

      this.setState({
        filedata:e.target.files[0],
        new_app_version : e.target.files[0].name + "-" + date,
        deploy_message: null
      })
    }
  }

/*
 * ###### マネージャー処理 ######  
 */
 
async initialDisplayVersion() {
  try {

    if (!this.state.is_loading) {
      // ローディング設定
      await this.setState({is_loading: true})
      await this.props.startLoading()
    }

    // paging時に黄色枠が残るので、これを削除
    if (this.state.update_id != null) {
      $('#' + this.state.update_id).removeClass("custom-td-updating-version-area");
    } 

    // 非更新時はis_updatingをfalseに、pageを設定
    await this.setState({
      is_updating: this.state.update_id != null,
      version_page: 0
    })

    // バージョン情報再取得（このときだけ、処理終了を待つ）
    let response = await this.getVersion()
    if (!response.result) {
      throw "バージョン情報の取得に失敗しました。"
    }

    this.getVersionManager()
    
  } catch (error) {
    // 更新状態をもとに戻す（ボタンを活性化）
    await this.setState({is_updating: false})

    // エラー用モーダル表示
    this.showError(error);

  } finally {
    // ロード画面非表示
    if (this.state.is_loading) {
      this.props.finishLoading()
      // ローディング解除
      await this.setState({is_loading: false})
    }

    this.sleep(2000)

    // paging時に黄色枠が再付与
    if (this.state.update_id != null) {
      $('#' + this.state.update_id).addClass("custom-td-updating-version-area");
    } 
  }
}

  async onClickPageButton(page) {
    try {
      // ローディング設定されていないときのみ
      // バージョン更新中はローディングしておらず、その時は一部処理を許可する（ローディング表示しない）
      //  - ページング処理（バージョン一覧表示）がこれに該当
      if (!this.state.is_loading) {
        // ローディング設定
        await this.setState({is_loading: true})
        await this.props.startLoading()
      }

      // paging時に黄色枠が残るので、これを削除
      if (this.state.update_id != null) {
        $('#' + this.state.update_id).removeClass("custom-td-updating-version-area");
      } 

      // 非更新時はis_updatingをfalseに設定
      await this.setState({
        is_updating: this.state.update_id != null,
      })

      // バージョン情報再取得（このときだけ、処理終了を待つ）
      let response = await this.getVersionManager(true, page)
      if (!response.result) {
        throw "バージョン情報の取得に失敗しました。"
      }

      // 成功時のみpageを設定
      await this.setState({
        version_page: page
      })
      
    } catch (error) {
      // 更新状態をもとに戻す（ボタンを活性化）
      await this.setState({is_updating: false})

      // エラー用モーダル表示
      this.showError(error);

    } finally {
      // ロード画面非表示
      if (this.state.is_loading) {
        this.props.finishLoading()
        // ローディング解除
        await this.setState({is_loading: false})
      }

      this.sleep(2000)

      // paging時に黄色枠が再付与
      if (this.state.update_id != null) {
        $('#' + this.state.update_id).addClass("custom-td-updating-version-area");
      } 
    }
  }

  async onClickDeploy(filedata) {

    try {
      // ローディング画面表示
      if (!this.state.is_loading) {
        // ローディング設定
        await this.setState({is_loading: true})
        await this.props.startLoading()
      }

      // 更新時はis_updatingをtrueにする
      await this.setState({
        is_updating: true,

      })

      if (filedata == null) {
        throw "ファイルが選択されていません。"
      }

      // バージョン名に禁則文字が含まれていないこと確認
      let check_version = this.isCorrectVersion(this.state.new_app_version)
      if (!check_version.result) {
        throw check_version.errorMessage
      }

      // deploy情報取得
      let deploy_info_response = await this.getDeployInfo(filedata)
      if (!deploy_info_response.result) {
        throw "デプロイ情報取得処理に失敗しました。"
      }

      // デプロイファイル S3アップロード
      let s3_upload_response = await this.putS3Object(deploy_info_response.s3_url, filedata)
      if (!s3_upload_response.result) {
        throw "S3アップロード処理に失敗しました。"
      }

      // EBにデプロイ
      let deploy_response = await this.deploy(
        deploy_info_response.s3_filename,
        this.props.envName,
        this.state.new_app_version,
        this.state.description
      )
      if (!deploy_response.result) {
        throw deploy_response.errorMessage
      }

      this.setState({
        deploy_message : "デプロイ処理を実施しました。詳細は「実行環境情報詳細」より確認ください。",
        update_id: "1-0" // デプロイ処理の時、一番最新のバージョン（1-0）を点滅させるために設定。
      })

      // バージョン情報再取得（非同期処理にて反映）
      this.getVersionManager()

    } catch (error) {
      // 更新状態をもとに戻す（ボタンを活性化）
      await this.setState({is_updating: false})

      // エラー用モーダル表示
      this.showError(error);

    } finally {
      // ロード画面非表示
      if (this.state.is_loading) {
        this.props.finishLoading()
        // ローディング解除
        await this.setState({is_loading: false})
      }
    }
  }

  async onClickBackVersion() {
    try {
      // ローディング画面表示
      if (!this.state.is_loading) {
        // ローディング設定
        await this.setState({
          is_loading: true,
          update_id : (this.state.version_page + 1).toString() + "-" + this.state.version_index.toString()
        })
        await this.props.startLoading()
      }

      // ローディング画面表示
      if (!this.state.is_loading) {
        await this.props.startLoading()
      }

      // 更新時はis_updatingをtrueにする
      await this.setState({
        is_updating: true
      })

      // バージョンをもとに戻す
      let response = await this.backVersion()
      if (!response.result) {
        throw "バージョンの更新処理に失敗しました。"
      }

      // バージョン情報再取得（非同期処理にて反映）
      this.getVersionManager()

    } catch (error) {
      // 更新状態をもとに戻す（ボタンを活性化）
      await this.setState({is_updating: false})

      // エラー用モーダル表示
      this.showError(error);

    } finally {
      // ロード画面非表示
      if (this.state.is_loading) {
        this.props.finishLoading()
        // ローディング解除
        await this.setState({is_loading: false})
      }
    }
  }

  async onClickDeleteVersion() {
    try {
      // ローディング画面表示
      if (!this.state.is_loading) {
        // ローディング設定
        await this.setState({is_loading: true})
        await this.props.startLoading()
      }

      // ローディング画面表示
      if (!this.state.is_loading) {
        await this.props.startLoading()
      }

      // 更新時はis_updatingをtrueにする
      await this.setState({
        is_updating: true
      })

      // バージョンを削除する
      let response = await this.deleteVersion()
      if (!response.result) {
        throw "バージョンの削除処理に失敗しました。"
      }

      // バージョン情報再取得（非同期処理にて反映）
      this.getVersionManager()

    } catch (error) {
      // 更新状態をもとに戻す（ボタンを活性化）
      await this.setState({is_updating: false})

      // エラー用モーダル表示
      this.showError(error);

    } finally {
      // ロード画面非表示
      if (this.state.is_loading) {
        this.props.finishLoading()
        // ローディング解除
        await this.setState({is_loading: false})
      }
    }
  }

  async getVersionManager(isPaging=false, page=0) {

    var response = {}

    try {
      // 初回バージョン情報の取得
      let version_response = await this.getVersion(page)
      if (!version_response.result) {
        throw version_response
      }

      // (更新中の場合)非同期で点滅
      if (this.state.is_updating) {
        this.blink()

        // バージョン状態がUpdatingだったら、5秒スリープ、getVersion問い合わせを100回繰り返す
        for (var i=0; i < 100; i++ ) {
          let version_response = await this.getVersion(page)
          if (!version_response.result) {
            throw version_response
          }

          // ページング処理時は問答無用でbreak
          if (!this.state.is_updating || isPaging) break;
          await this.sleep(5000)
        }
      }

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時※関数のexceptionをそのまま返却）
      response = error
    }
    return response;
  }

  async onClickRefreshButton(e) {
    await this.props.startLoading()
    await this.getVersion(0)
    // null制御は各コンポーネントで実施
    this.setState({
      filedata : null,
      new_app_version : "",
      description : "",
      deploy_message : null
    });
    this.props.finishLoading()
  }


/*
 * ###### リクエスト処理 ######  
 */

  async getVersion(page=0) {

    var response = {}
    
    try {
      let nextToken = this.state.next_token[page]

      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/env/version/";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName : this.props.applicationName,
          envName: this.props.envName,
          nextToken: nextToken
        }
      };

      let version_info_response = await API.get(api_name, path, my_init);
      if (!version_info_response.result) {
        throw "バージョン情報の取得に失敗しました。"
      }

      await this.setState({
        version_list : version_info_response.version_list,
        next_token: this.switchUpdateMarker(
          this.state.version_remain_flag,
          this.state.next_token,
          version_info_response.next_token,
          page
        ),
        version_remain_flag: version_info_response.next_token !== null,
        is_updating: version_info_response.isUpdating
      })

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }

    return response

  }

  async backVersion() {

    var response = {}

    try {

      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/env/version/";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName :  this.props.applicationName,
          envName : this.props.envName,
          newAppVersion : this.state.back_version
        }
      };
      let deploy_response = await API.put(api_name, path, my_init);

      if ("errorCode" in deploy_response) {
        throw deploy_response.errorMessage
      }

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }

    return response

  }

  async deleteVersion() {

    var response = {}

    try {

      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/env/version/";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName : this.props.applicationName,
          envName : this.props.envName,
          appVersion : this.state.delete_version
        }
      };
      let deploy_response = await API.del(api_name, path, my_init);

      if ("errorCode" in deploy_response) {
        throw deploy_response.errorMessage
      }

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    } 

    return response
  }

  async getDeployInfo(filedata) {

    var response = {}

    try {
      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let s3url_path = "/service/env/deploy/";
      let s3url_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName : this.props.applicationName,
          filename : filedata.name,
          contentType : filedata.type
        }
      };
      
      // S3署名付きURLの取得
      response = await API.get(api_name, s3url_path, s3url_init);
      
      if ("errorCode" in response) {
        throw response.errorMessage;
      }

      // 返却値の設定
      response = {
        result : true,
        s3_url : response.s3_url,
        s3_filename : response.s3_filename
      }

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }

    return response

  }

  async putS3Object(s3_url, filedata) {

    var response = {}

    try {
      var axios = require('axios')
      var fileType = filedata.type;

      const config = {
        headers: {
            'content-type': fileType
        }
       }
      await axios.put(s3_url, filedata, config
      ).then(response => {
        
      }).catch(error => {
        throw error
      })

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }

    return response
  }

  async deploy(s3_filename, env_name, new_app_version, description) {

    var response = {}

    try {
      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/env/deploy/";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          applicationName : this.props.applicationName,
          envName : env_name,
          newAppVersion : new_app_version,
          description : description,
          filename : s3_filename
        }
      };
      let deploy_response = await API.put(api_name, path, my_init);

      if ("errorCode" in deploy_response) {
        throw deploy_response.errorMessage
      }

      // 返却値の設定
      response = {result : true}

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }

    return response
  }

/*
 * ###### ユーティリティ処理 ######  
 */

  isCorrectVersion(new_app_version){
    var result = {"result": true}

    if (new_app_version == null) {
      result = {
        "result" : false,
        "errorMessage" : "バージョンラベルに値を指定してください。"
      }
    }

    else if (new_app_version == "") {
      result = {
        "result" : false,
        "errorMessage" : "バージョンラベルに値を指定してください。"
      }
    }

    if (new_app_version.indexOf(' ') != -1 ) {
      result = {
        "result" : false,
        "errorMessage" : "バージョンラベルにスペースを含めることはできません。"
      }
    }

    if (new_app_version.indexOf('/') != -1) {
      result = {
        "result" : false,
        "errorMessage" : "バージョンラベルに「/」を含めることはできません。"
      }
    }

    return result
  }

  async blink () {

    if (this.state.update_id != null) {

      /* 点滅はあきらめ・・・。
      // 対象のcssを追加
      $('#' + this.state.update_id).addClass("custom-td-updating-version-area");

      for (var i=0; i < 500; i++){
        if (!this.state.is_updating) {
          break;
        }

        // 対象が存在したときだけ
        if ($('#' + this.state.update_id).length){
          // 対象を点滅させる
          await $('#' + this.state.update_id).fadeOut(1000, function () {
            $(this).fadeIn(1000)
          });
        }
        await this.sleep(3000)
      }
      // 対象のcssを削除
      $('#' + this.state.update_id).removeClass("custom-td-updating-version-area");

      await this.setState({
        update_id : null
      })

      */

      for (var i=0; i < 500; i++){
        if (!this.state.is_updating) {
          break;
        }

        await this.sleep(3000)
      }
    }

    // update_idを初期化
    await this.setState({
      update_id : null
    })
  }

  switchUpdateMarker(continue_flag, marker_list, new_marker, page) {
    if (continue_flag) {
      // ファイルを最後まで取得しきれていない→マーカーを追加する
      if (page >= marker_list.length - 1) {
        // ページ番号がマーカーリストの最後の要素番号であれば、追加する
        marker_list.push(new_marker);
      } else {
        // 現在表示しているページの次のマーカーを更新
        marker_list[page + 1] = new_marker;
      }
    }
    return marker_list;
  }

  showError(body) {
    this.setState({error_message : body})
    $("#deployFailure").modal();
    // エラー発生時のみローディングフラグをリセットする
    this.props.finishLoading()
  }

  sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

  render() {
    const versionInfoCreater = this.state.version_list.map((data, index) => {
      var id = (this.state.version_page + 1).toString() + "-" + index.toString();
      return (
        <tr id={id} className="custom-td-current-version-area custom-td-updating-version-area"
            key={index}
            is-current-version={data.isCurrentVersion.toString()}
            is-updating-version={(this.state.update_id == id).toString()}
        >
          <td className="custom-td-width-eb-ver-manage-long">{data.version} </td>
          <td className="custom-td-width-eb-ver-manage">{data.description} </td>
          <td className="custom-td-width-eb-ver-manage">{data.createDate} </td>
          <td className="custom-td-width-eb-ver-manage-small">
            <div className="custom-td-buttons-area">
              <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#backVersion"
                onClick={ () =>
                this.setState({
                  back_version: data.version,
                  version_index:index
                })
                }
                disabled={this.state.is_updating}
                >元に戻す</button>
            </div>
          </td>
          <td className="custom-td-width-eb-ver-manage-extra-small">
            <div className="custom-td-buttons-area">
              <button type="button" className="btn btn-danger mb-12" data-toggle="modal" data-target="#deleteVersion"
                onClick={ () =>
                this.setState({
                  delete_version: data.version,
                  version_index:index
                })
                }
                disabled={this.state.is_updating}
                >削除</button>
            </div>
          </td>
        </tr>
      );
    })
    return (
      // 左右の中央寄せは効くが、上下の中央寄せは、親要素の高さが無い？から出来ない
      <div className="section d-flex justify-content-center">
        {/* 全方向のマージンを入れているから中央寄せになっている？ */}
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
            <div className="float-left mt-2">
              {/* <i className="fas fa-users-cog mr-2" /> */}
              <i className="fas fa-file-upload"></i>
							<span className="ml-2">
								<span className="mr-3">{this.props.applicationType}</span>
								アプリケーションデプロイ
							</span>
            </div>
            {/* 更新ボタン */}
						<button className={`btn btn-light float-right`} onClick={this.onClickRefreshButton} 
							data-tip="アプリケーションバージョンの一覧が更新されます。"
              data-html={true}
              data-for="refreshVersionToolTip">
              <i className="fas fa-sync-alt" />
            </button>
            <button
              type="button"
              className="btn btn-light float-right"
              onClick={() => this.props.showEnvList()}
              data-tip="アプリケーション・実行環境の一覧画面に戻ります。"
              data-html={true}
              data-for="backDeployToolTip"
            >
              <i className="fas fa-reply mr-2" />
              実行環境一覧に戻る

            </button>
          </div>

          <ReactTooltip id="refreshVersionToolTip" effect="solid" place="top" html={true} />
          <ReactTooltip id="backDeployToolTip" effect="solid" place="left" html={true} />

          <div className="card-body">
          <div className="input-group">
            <label className="input-group-btn">
              <span className="btn btn-primary"
                    data-tip="デプロイするファイルを選択してください。"
                    data-html={true}
                    data-for="selectWarFileTip"
              >
                ファイルを選択
                <input type="file"
                       onChange={this.onChange}
                       onClick={(e) => {
                         // valueを初期化する
                         e.target.value = '';
                       }}
                       style={{display:"none"}}
                       accept=".war,application/zip"
                />
                
              </span>
            </label>
          </div>

          <ReactTooltip id="selectWarFileTip" effect="solid" place="right" html={true} />

          <div className="card-body">             
            {this.state.deploy_message != null && this.state.deploy_message}
          </div>

          {/* ファイルが選択されたら、以下のセクションが表示される */}
          {
            this.state.filedata != null &&
            <div className="deploy-info">
              <table className="table table-bordered w-100">
                <tbody>
                  <tr>
                    <th scope="row" className="custom-td-extra-width">
                      ファイル名
                    </th>
                    <td className="">
                        {this.state.filedata != null && this.state.filedata.name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="custom-td-extra-width">
                      実行環境名
                    </th>
                    <td className="">{this.props.envName}</td>
                  </tr>
                  <tr>
                    <th scope="row" className="custom-td-extra-width">
                        バージョンラベル
                    </th>
                    <td >
                    <span data-tip="バージョンラベルを入力してください。既存のバージョンラベルを設定することはできません。"
                            data-html={true}
                            data-for="inputVersionLabelTip">
                        <input type="text"
                          className="form-control"
                          value={this.state.new_app_version}
                          onChange={ e =>
                            this.setState({
                            new_app_version: e.target.value
                            })
                          }
                        />
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="custom-td-extra-width">
                      説明
                    </th>
                    <td>
                      <input type="text"
                        className="form-control"
                        value={this.state.description}
                        onChange={ e =>
                          this.setState({
                          description: e.target.value
                          })
                        }
                        data-tip="バージョンに関する説明を記載する必要があれば入力してください。（任意）"
                        data-html={true}
                        data-for="inputDescriptionTip"
                      /></td>
                  </tr>                    
                </tbody>
              </table>
              <ReactTooltip id="inputVersionLabelTip" effect="solid" place="top" html={true} />
              <ReactTooltip id="inputDescriptionTip" effect="solid" place="top" html={true} />

              {/* デプロイアプリケーション指定エリア */}
              <div className="section d-flex justify-content-center">
                <form onSubmit={this.onFormSubmit}>
                <div className="card-body">             
                  <button type="submit" disabled={this.state.is_updating}>デプロイ</button>
                </div>
                </form>
              </div>
            </div>
          }
          </div>
          <div className="card-header h5">
            <div className="float-left mt-2">
              {/* <i className="fas fa-users-cog mr-2" /> */}
              <i className="fas fa-file-alt"></i>
							<span className="ml-2">
								<span className="mr-3">{this.props.applicationType}</span>
								アプリケーションバージョン履歴
							</span>
            </div>
          </div>

          {/* アプリケーションバージョン　ページング指定エリア */}
          <br />
          <div className="text-center">
            <button
              className="btn"
              onClick={() => this.onClickPageButton(this.state.version_page - 1)}
              disabled={this.state.version_page <= 0}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {" "}
              {this.state.version_page + 1}{" "}
            </button>
            <button
              className="btn"
              onClick={() => this.onClickPageButton(this.state.version_page + 1)}
              disabled={!this.state.version_remain_flag}
            >
              次
            </button>
          </div>
          <br />
          {/*<div className="custom-table-height-scroll">*/}
            <div className="">
              <table className="m-0 table table-bordered w-100 small bg-white table-striped sticky_table">
                <thead>
                  <tr>
                    {/* <th scope="col" className="custom-td-width-4">環境ID</th> */}
                    <th scope="col" className="custom-td-width-eb-ver-manage-long bg-white">
                      バージョンラベル
                    </th>
                    <th scope="col" className="custom-td-width-eb-ver-manage bg-white">
                      説明
                    </th>
                    <th scope="col" className="custom-td-width-eb-ver-manage bg-white">
                      作成日時
                    </th>
                    <th scope="col" className="custom-td-width-eb-ver-manage-small bg-white custom-td-version-tips-area">
                      <i className="fas fa-question-circle text-primary ml-2"
                         data-tip="「元に戻す」をクリックして、指定のバージョンに戻すことができます。"
                         data-html={true}
                         data-for="backVersionDescriptionTip"
                      />
                    </th>
                    <th scope="col" className="custom-td-width-eb-ver-manage-extra-small bg-white custom-td-version-tips-area">
                    <i className="fas fa-question-circle text-primary ml-2"
                         data-tip="「削除」をクリックして、指定のバージョンを削除することができます。"
                         data-html={true}
                         data-for="deleteVersionDescriptionTip"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody className="">
                  {versionInfoCreater}
                </tbody>
              </table>

              <ReactTooltip id="backVersionDescriptionTip" effect="solid" place="left" html={true} />
              <ReactTooltip id="deleteVersionDescriptionTip" effect="solid" place="left" html={true} />
              
          {/*</div>*/}
        </div>
        <br />
        ・赤枠で示しているアプリケーションバージョンが現在設定されています。<br />
        ・黄枠で示しているアプリケーションバージョンが現在更新中です。<br />
        <br />
      </div>

      {/* モーダルウィンドウ（バージョンをもとに戻す） */}
      <div className="modal fade" id="backVersion" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">確認</h4>
            </div>
            <div className="modal-body">
              <label>{this.state.back_version}</label>
              <br />
              <label>このバージョンに戻しますか？</label>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">キャンセル</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.onClickBackVersion}>実行</button>
            </div>
          </div>
        </div>
      </div>

      {/* モーダルウィンドウ（バージョンを削除する） */}
      <div className="modal fade" id="deleteVersion" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">確認</h4>
            </div>
            <div className="modal-body">
              <label>{this.state.delete_version}</label>
              <br />
              <label>このバージョンを削除しますか？</label>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">キャンセル</button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.onClickDeleteVersion}>実行</button>
            </div>
          </div>
          <br />
        </div>
      </div>
      {/* モーダル：エラー */}
      {showModal({
        modalId: "deployFailure",
        modalTitle: (
          <span className="text-danger">
            <i className="fas fa-exclamation-circle" />
            　エラー
          </span>
        ),
        modalBody: this.state.error_message,
        executeButtonLabel: "OK",
        showCancelButtonFlag: false,
        executeFunctionObject: this.emptyReturn
      })}
      
    </div>
    );
  }
}
