import { connect } from "react-redux";

import NoticeSection from "../components/NoticeSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    noticeInformation:{
			noticeList: state.noticeSection.noticeList,
			totalRecordNumber: state.noticeSection.totalRecordNumber,
			nowPageNumber: state.noticeSection.nowPageNumber,
			loadingNoticeFlag: state.noticeSection.loadingNoticeFlag,			
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// noticeList: list
		setNoticeList(response, nowPageNumber){
			dispatch(actionCreater.noticeSection.setNoticeList(response, nowPageNumber))
		},
		setNoticePageNumber(nowPageNumber){
			dispatch(actionCreater.noticeSection.setNoticePageNumber(nowPageNumber))
		},
		// 
		clearNoticeList(){
			dispatch(actionCreater.noticeSection.clearNoticeList())
		},
		// 
		setNoticeLoadingFlag(){
			dispatch(actionCreater.noticeSection.setNoticeLoadingFlag())
		},
		// 
		resetNoticeLoadingFlag(){
			dispatch(actionCreater.noticeSection.resetNoticeLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoticeSection);
