
const CONST_LOCAL = {
	// W3Sidebar.jsに存在
	SIDEBAR_WRAPPER:{
		ID: "mySidebarWrapper",
		HIDDEN: "hiddenMySidebar",
		SHOW: "showMySidebar",
	},
	// SwitchComponent.jsに存在
	OVERLAY:{
		ID: "my-overlay",
		HIDDEN: "hiddenSidebar",
		SHOW: "showSidebar",
	},
	MAIN_WRAPPER:{
		ID: "mainWrapper",
		HIDDEN: "hiddenSidebarMain",
		SHOW: "showSidebarMain",
	}
}

export function toggleButton() {
	// 直接クラス名を取ってしまうと、ただの文字列になってしまい、クラス名が書き換えられない
	let mySidebarWrapper = document.getElementById(CONST_LOCAL.SIDEBAR_WRAPPER.ID)
	let myOverlay = document.getElementById(CONST_LOCAL.OVERLAY.ID)
	let mainWrapper = document.getElementById(CONST_LOCAL.MAIN_WRAPPER.ID)
	if (mySidebarWrapper.className == CONST_LOCAL.SIDEBAR_WRAPPER.HIDDEN) {
		// サイドバー非表示→表示
		mainWrapper.className = CONST_LOCAL.MAIN_WRAPPER.SHOW;
		mySidebarWrapper.className = CONST_LOCAL.SIDEBAR_WRAPPER.SHOW;
		myOverlay.className = CONST_LOCAL.OVERLAY.SHOW;
	} else {
		// サイドバー表示→非表示
		mainWrapper.className = CONST_LOCAL.MAIN_WRAPPER.HIDDEN;
		mySidebarWrapper.className = CONST_LOCAL.SIDEBAR_WRAPPER.HIDDEN;
		myOverlay.className = CONST_LOCAL.OVERLAY.HIDDEN;
	}
}