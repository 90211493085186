import { connect } from "react-redux";

import EbEnvListSection from "../components/EbEnvListSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    ebInformation:{
			envList: state.ebEnvListSection.envList,
			loadingListFlag: state.ebEnvListSection.loadingListFlag,			
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// envList: list
		setEbEnvList(envList){
			dispatch(actionCreater.ebEnvListSection.setEbEnvList(envList))
		},
		// 
		clearEbEnvList(){
			dispatch(actionCreater.ebEnvListSection.clearEbEnvList())
		},
		// 
		setListLoadingFlag(){
			dispatch(actionCreater.ebEnvListSection.setListLoadingFlag())
		},
		// 
		resetListLoadingFlag(){
			dispatch(actionCreater.ebEnvListSection.resetListLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EbEnvListSection);
