import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import {executeGetAccount} from './common/handlerAccount'

class AccountSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
		};
		this.setModalMessage = this.setModalMessage.bind(this)
  }
  componentWillMount() {
		// 契約情報のメアドの長さが0（情報が取れていない）かつ読み込み済みではない場合だけ実行
    if (!this.props.loadingFlag && this.props.accountInformation.customerMailAddress.length === 0) {
      // console.log(this.props);
      this.getAccount();
    }
  }

  async getAccount() {
    try {
      await this.props.startLoading();
      await this.props.setLoadingFlag();
			let response = await executeGetAccount()
      await this.props.setAccountInformation(response);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
        error_function: "お客様情報取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
			$("#generalFailure").modal();
			// エラー発生時のみローディングフラグをリセットする
      await this.props.resetLoadingFlag();
    } finally {
      this.props.finishLoading();
    }
	}
	// エラーモーダルを表示する
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}

  render() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
					<div className="float-left mt-2">
            <i className="fas fa-file-signature mr-2" />
						お客様情報
						</div>
						{/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
              onClick={() => this.getAccount()}
              data-tip="お客様情報が更新されます。"
              data-html={true}
              data-for="refreshAccountToolTip"
            >
              <i className="fas fa-sync-alt" />

              <ReactTooltip id="refreshAccountToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>
          <div className="card-body">
            <table className="table table-bordered w-100 small">
              <tbody>
                {/* <tr>
                  <th scope="row" className="custom-td-width">
                    アカウントID
                  </th>
                  <td className="">{this.props.accountInformation.accountId}</td>
                </tr> */}
                <tr>
                  <th scope="row" className="custom-td-width">
                    部署名
                  </th>
                  <td>{this.props.accountInformation.customerName}</td>
                </tr>
                {/* <tr>
                  <th scope="row" className="custom-td-width">
                    メールアドレス
                  </th>
                  <td>{this.props.accountInformation.customerMailAddress}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}

export default AccountSection;
