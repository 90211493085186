
import React from "react";
import ReactTooltip from "react-tooltip";
import {API, Auth} from 'aws-amplify'

import {CONST} from '../../environments/environment'

// 1ページあたりの表示件数
export const RESULT_LIMIT = 20;
// 「New」を表示するのは何日前までかを定義
export const NEW_NOTICE_DATE = 31;

// お知らせ一覧取得APIの実行用
export async function executeGetNoticeList(pageNumber){
	let session = await Auth.currentSession();
	let api_name = CONST.API_NAME;
	let path = CONST.API_PATH.NOTICE;
	let my_init = {
		headers: {
			Authorization: session.getIdToken().getJwtToken()
		},
		queryStringParameters: {
			// 現在はユーザーを識別していないため、トークンは不要
			// accessToken: session.getAccessToken().getJwtToken()
			resultLimit: RESULT_LIMIT,
			pageNumber: pageNumber
		}
	};
	let response = await API.get(api_name, path, my_init);
	// console.log(response);
	if ("errorMessage" in response) {
		throw response;
	}
	return response
}

// ### お知らせ一覧を表示するコンポーネント
