import { connect } from "react-redux";

import EbEnvDetailSection from "../components/EbEnvDetailSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    ebInfomation: {
      envDetail: state.ebEnvDetailSection.envDetail,
      loadingDetailFlag: state.ebEnvDetailSection.loadingDetailFlag
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // envDetail: list
    setEbEnvDetail(envDetail) {
      dispatch(actionCreater.ebEnvDetailSection.setEbEnvDetail(envDetail));
    },
    //
    clearEbEnvDetail() {
      dispatch(actionCreater.ebEnvDetailSection.clearEbEnvDetail());
    },
    //
    setDetailLoadingFlag() {
      dispatch(actionCreater.ebEnvDetailSection.setDetailLoadingFlag());
    },
    //
    resetDetailLoadingFlag() {
      dispatch(actionCreater.ebEnvDetailSection.resetDetailLoadingFlag());
		},
	}
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EbEnvDetailSection);
