import React from "react";

import { TOTPSetup } from "aws-amplify-react";

// どうやらAmplify　Configureは親コンポーネントにさえ書いてあればいいらしい
import { Auth } from "aws-amplify";
import QRcode from "qrcode.react";

import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";
import { checkContact } from "./checkContact";

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import $ from "jquery";
import { showModal } from "../common/showModal";
import { checkCodeLengthAndNumber } from "../common/cognitoInputValidation";
import { switchCognitoError } from "./switchCognitoError";

class MyTOTPSetup extends TOTPSetup {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      codeForQr: null,
      codeForText: null,
			mfa_code: "",
			
			/**サインインボタンを連打すると、1回目のリクエストでトップページに遷移するのと同時に、
			 * 2回目のリクエストでエラーモーダルが表示される。
			 * その際エラーモーダルは消えるがグレーアウトが消えず、再読み込み以外で操作不能になる。
			 * ローディングで処理してしまうと、トップページに遷移した瞬間にローディングの解除が出来なくなるため、
			 * 解除不要な以下変数でボタンの制御を行う
			 */
			isDisabledButton: false,

      error_function: null,
      error_message: null
    };
    this.setModalMessage = this.setModalMessage.bind(this);
	}
	// Stateを更新する関数
  handleChangeCode(event) {
    this.setState({ mfa_code: event.target.value });
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1} />,
        `${this.state.error_message}`
      ];
    }
    return message;
	}
	// バリデーション結果によってアイコンを切り替える
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	// 入力規則チェック用セクション
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th className="custom-td-width-totp">
                {this.switchValidationIcon(checkCodeLengthAndNumber(this.state.mfa_code))}
                ワンタイムパスワード
              </th>
              <td>
                {this.switchValidationIcon(checkCodeLengthAndNumber(this.state.mfa_code))}
                半角6桁の数字です。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

	// MFAのQRコードを表示する
  setup() {
		const user = this.props.authData;
		// Asyncにすると何故かバグる。Promiseで暫定対応
    Auth.setupTOTP(user)
      .then(data => {
        // dataがMFA登録に必要なシークレットキー
        // codeはそれをQRコード化したもの（下記URLをQRコードに変換可能）
        const code =
          "otpauth://totp/AWSCognito:" + user.username + "?secret=" + data + "&issuer=AWSCognito";
        this.setState({
          codeForQr: code,
          codeForText: data
        });
      })
      .then(() => {})
      .catch(error => {
        this.setState({
          error_function: "ワンタイムパスワードの設定",
          error_message: switchCognitoError(error)
        });
        $("#generalFailure").modal();
			});
		// try {
    // 	let data = Auth.setupTOTP(user)
    // 	const code =
    // 				"otpauth://totp/AWSCognito:" +
    // 				user.username +
    // 				"?secret=" +
    // 				data +
    // 				"&issuer=AWSCognito";
    // 	this.setState({
    // 		codeForQr: code,
    // 		codeForText: data
    // 	});
    // } catch (error) {
    // 	this.setState({
    // 		error_function: "ワンタイムパスワードの設定",
    // 		error_message: switchCognitoError(error)
    // 	})
    // 	$('#generalFailure').modal()
    // }
	}
	
	// MFAを設定する関数
  async verifyTotpToken() {
		await this.setState({ isDisabledButton: true})
    const user = this.props.authData;
    const totpCode = this.state.mfa_code;
    try {
			// 本当はローディングを入れたいが、サインイン後の画面に遷移すると、表示が終わった画面のStateを更新する時の警告が出る
      await Auth.verifyTotpToken(user, totpCode);
      await Auth.setPreferredMFA(user, "TOTP");
      await checkContact.apply(this, [user]);
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "ワンタイムパスワードの設定",
        error_message: switchCognitoError(error)
			});
			await this.setState({ isDisabledButton: false})
      $("#generalFailure").modal();
    }
  }

	// AuthStateがMFAセットアップであり、QRコード用Stateに値がセットされた状態でのみ表示（じゃないとバグる）
  cardBody() {
    let component = null;
    // この画面が表示されている　かつ　QRコードが読み込まれている（読み込まれていないとエラー）
    if (this.props.authState === "TOTPSetup" && this.state.codeForQr !== null) {
      component = (
        <div>
          <div className="">
            <div className="row">
              <div className="col-sm-1">
                <i className="fas fa-info-circle text-primary ml-5" />
              </div>
              <div className="col-sm-11">
                本サービスでは、お客様の資産保護を目的として、多要素認証によるセキュリティ強化を実施しています。
                <br />
                そのため、認証時に通常のユーザー名・パスワードによる認証に加え、ワンタイムパスワードによる追加認証を行います。
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1">
                <i className="fas fa-info-circle text-primary ml-5" />
              </div>
              <div className="col-sm-11">
                ワンタイムパスワードを設定するためには、「
                <a
                  href="https://winauth.github.io/winauth/"
                  target="blank"
                  className="text-primary"
                >
                  WinAuth
                </a>
                」などの2要素認証用アプリが必要です。
                <br />
                2要素認証用アプリに以下のシークレットキーを入力すると、ワンタイムパスワードが生成されるようになります。
                <br />
                生成されたワンタイムパスワードを以下の入力ボックスに入力すると、設定が完了し、以降同じシークレットキーで生成したワンタイムパスワードでサインインすることができます。
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1">
                <i className="fas fa-exclamation-circle text-warning ml-5" />
              </div>
              <div className="col-sm-11">
                シークレットキーは今この画面でのみ表示され、再表示することはできません。（再度この画面にアクセスすると、別のシークレットキーが表示されます。）
								<br/>
								保管される場合は、安全な場所への保管をお願いします。
                <br />
                2要素認証用アプリがQRコードの読み込みに対応している場合は、シークレットキー入力の代わりにQRコードの読み込みでもワンタイムパスワードの生成が可能です。
                <br />
              </div>
            </div>
          </div>
					{/* QRコードとシークレットキーを表示する */}
          <div className="text-center">
            <div className="m-3">QRコード</div>
            <QRcode value={this.state.codeForQr} />
          </div>
          <div className="text-center">
            <div className="m-3">シークレットキー</div>
            <span>{this.state.codeForText}</span>
          </div>

          <br />
          <div className="m-3">
            <label htmlFor="inputVerifyCode">
              2要素認証用アプリで生成した6桁のワンタイムパスワードを入力してください:
            </label>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-key" id="addonVerifyCode" />
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="inputVerifyCode"
                placeholder="ワンタイムパスワード"
                aria-describedby="addonVerifyCode"
                value={this.state.mfa_code}
                onChange={event => this.handleChangeCode(event)}
              />
            </div>
						{/* 入力規則チェック用セクション */}
            {this.showValidationComponent()}
          </div>
          <br />

          <div className="m-3">
            <button
              className="btn btn-primary btn-block"
              onClick={() => this.verifyTotpToken()}
              disabled={!checkCodeLengthAndNumber(this.state.mfa_code) || this.state.isDisabledButton}
            >
              設定
            </button>
          </div>
        </div>
      );
    }
    return component;
	}
	// カード全体
  showCardComponent() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-key mr-2" />
              ワンタイムパスワードを設定
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.cardBody()}</div>
        </div>
      </div>
    );
	}
	// コンポーネント全体
  showComponent() {
    var component = null;
    // if (this.props.authState === CONST.AMPLIFY.AUTH_STATE.SIGN_IN) {
    component = (
      <div className="">
        {/* ################################### */}
        {/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
        <div className="content mybgcolor-grey mb-4">
          <div className="">
            <NavbarForLogin />
            {this.props.authState === "TOTPSetup" && this.state.codeForQr === null ? (
              this.setup()
            ) : (
              <span />
            )}
            <div id="" className="">
              <div id="" className="">
                <div id="" className="hiddenSidebarSignIn">
                  {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                  {showLoadingToast(this.state.loading)}
                </div>
                {/* カード */}
                {this.showCardComponent()}
              </div>
            </div>
						<Footer />
          </div>
					
        </div>
        {/* ################################### */}
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
    // }
    return component;
  }

  render() {
		// AuthStateがMFAセットアップの場合だけ表示する
    if (this.props.authState === "TOTPSetup") {
      return this.showComponent();
    } else {
      return null;
    }
  }
}

export default MyTOTPSetup;
