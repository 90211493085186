import React from "react";
import { VerifyContact } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import { CONST } from "../../environments/environment";
import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import $ from "jquery";
import { showModal } from "../common/showModal";
import { checkCodeLengthAndNumber } from "../common/cognitoInputValidation";
import { switchCognitoError } from "./switchCognitoError";

const CONST_LOCAL = {
  VALUE: {
    HEADER: "メールアドレスを検証します",
    INPUT_CODE: "検証コード",
    SEND_EMAIL: "検証",
    SUBMIT: "送信",
    SKIP: "検証せずにサインイン",
    VERIFY: {
      EMAIL: "email",
      PHONE: "phone_number"
    },
    ATTENTION: {
      EMAIL: "※ログインユーザーのメールアドレスに検証コードが送信されます。"
    }
  },
  ID: {
    EMAIL: "verifyEmail",
    PHONE: "verifyPhone",
    CODE: "verifyCode"
  }
};

// このセクションに入った時点で、サインインは出来てしまっている（検証の強制は不可能）
class MyVerifyContact extends VerifyContact {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      verifyAttr: CONST_LOCAL.VALUE.VERIFY.EMAIL,
      emailSended: false,
      code: "",
			
			error_function: null,
			error_message: null,
    };
    this.sendEmailToVerify = this.sendEmailToVerify.bind(this);
		this.verifySubmit = this.verifySubmit.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
	setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
	}
	// Stateを更新する関数
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
	}
	// バリデーション結果によってアイコンを切り替える
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	// 入力規則チェック用セクション
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th className="custom-td-width-totp-verify">
                {this.switchValidationIcon(checkCodeLengthAndNumber(this.state.code))}検証用ワンタイムパスワード
              </th>
              <td>
                {this.switchValidationIcon(checkCodeLengthAndNumber(this.state.code))}
                半角6桁の数字です。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

	// メール送信する
  async sendEmailToVerify() {
    try {
      await this.setState({ loading: true });
      await Auth.verifyCurrentUserAttribute(this.state.verifyAttr);
      await this.setState({ emailSended: true });
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "メール送信",
				error_message: switchCognitoError(error)
			})
      $("#generalFailure").modal();
    } finally {
			// ローディングを終える
      await this.setState({ loading: false });
    }
	}
	// メールアドレスを有効化する関数
  async verifySubmit() {
    try {
			const code = this.state.code
      await this.setState({ loading: true });
      await Auth.verifyCurrentUserAttributeSubmit(this.state.verifyAttr, code);
      // サインインする際にFinally句でStateを操作するとエラーが出る（問題ないが）
      await this.setState({ loading: false });
      this.changeState(CONST.AMPLIFY.AUTH_STATE.SIGNED_IN, this.props.authData);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "メールアドレス有効化",
				error_message: switchCognitoError(error)
			})
      $("#generalFailure").modal();
      await this.setState({ loading: false });
    }
  }

	// メール検証ステップをスキップしてサインインする
	skipVerification(event){
		event.preventDefault()
		this.changeState(CONST.AMPLIFY.AUTH_STATE.SIGNED_IN, this.props.authData)
	}
	// メール送信用カードボディ
  cardBodyAttr() {
    // 検証するかどうか選択する画面。Emailとtelが設定されているかどうかでラジオボタンが増える
    const user = this.props.authData;
    // 通常はphone_number = undefined　。右のオブジェクトの中の左の項目名をそのまま変数にする。
    const { email, phone_number } = user.unverified;
    let component = null;
    let emailComponent = null;
    let phoneComponent = null;
    if (email != null) {
      emailComponent = (
        <div>
          <i className="fas fa-info-circle text-primary mr-2" />
          この画面では、設定されているメールアドレスが有効かどうかを検証することができます。
          <br />
          <i className="fas fa-info-circle text-primary mr-2" />
          メールで検証用ワンタイムパスワードをお送りし、その検証用ワンタイムパスワードをご入力いただくことで、メールアドレスを有効化できます。
          <br />
          <i className="fas fa-info-circle text-primary mr-2" />
          メールアドレスを有効化していただくと、パスワードリマインダーをご利用いただけるようになります。
          <br />
          <i className="fas fa-info-circle text-primary mr-2" />
          メールアドレスを有効化せずにサインインしていただくこともできます。
          <br /><br/>
          <label htmlFor={CONST_LOCAL.ID.EMAIL}>
            <input
              type={CONST.INPUT_TYPE.RADIO}
              id={CONST_LOCAL.ID.EMAIL}
              checked={this.state.verifyAttr === CONST_LOCAL.VALUE.VERIFY.EMAIL}
              onChange={() => this.setState({ verifyAttr: CONST_LOCAL.VALUE.VERIFY.EMAIL })}
            />
						　Eメールで検証を行います。
          </label>
					<br/><br/>
					※ログインユーザーのメールアドレスに検証用ワンタイムパスワードが送信されます。<br/>
          ※メールが届くまで少々お時間がかかる場合がございます。あらかじめ、ご了承ください。
          <br /><br/>
        </div>
      );
    }
    if (phone_number != null) {
      // 通常は通らないはず
      phoneComponent = (
        <label htmlFor={CONST_LOCAL.ID.PHONE}>
          <input
            type={CONST.INPUT_TYPE.RADIO}
            id={CONST_LOCAL.ID.PHONE}
            checked={this.state.verifyAttr === CONST_LOCAL.VALUE.VERIFY.PHONE}
            onChange={() => this.setState({ verifyAttr: CONST_LOCAL.VALUE.VERIFY.PHONE })}
          />
          {CONST_LOCAL.VALUE.VERIFY.PHONE}
        </label>
      );
    }
    component = (
      <div>
				{/* ラジオボタン（連絡先がセットされていない場合、表示されない） */}
        {emailComponent}
        {phoneComponent}
        <button className="btn btn-block btn-primary" onClick={() => this.sendEmailToVerify()}>
          有効化
        </button>
				<hr/>
					<a
						href="#"
            className="text-primary"
            onClick={(event) => this.skipVerification(event)}
          >
            有効化せずにサインイン
          </a>
      </div>
    );
    return component;
	}
	// メールアドレスを有効化用カードボディ
  cardBodyCode() {
    // const user = this.props.authData;
    // 通常はphone_number = undefined　。右のオブジェクトの中の左の項目名をそのまま変数にする。
    // const { email, phone_number } = user.unverified;
    let component = null;
    component = (
      <div>
				<div>
        <label htmlFor="forgotCode"> メールでお送りした6桁の検証用ワンタイムパスワードを入力してください： </label>
          <div className={`input-group mb-3`}>
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.CODE} />
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="forgotCode"
              placeholder="検証用ワンタイムパスワード"
              autoComplete="new-password"
              value={this.state.code}
              onChange={event => this.handleChangeCode(event)}
            />
          </div>
        </div>
				{/* 入力ボックス：終了 */}
				{/* 入力規則チェック用セクション */}
				{this.showValidationComponent()}
				<br/>
        <button className="btn btn-block btn-primary" onClick={() => this.verifySubmit()}
					disabled={!checkCodeLengthAndNumber(this.state.code)}
				>
          有効化
        </button>
        <hr/>
					<a
						href="#"
            className="text-primary"
            onClick={(event) => this.skipVerification(event)}
          >
            有効化せずにサインイン
          </a>
      </div>
    );
    return component;
  }

	// メール送信用カード全体
  showComponentAttr() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-envelope mr-2" />
              メールアドレスを有効化
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.cardBodyAttr()}</div>
        </div>
      </div>
    );
	}
	// メールアドレスを有効化用カード全体
  showComponentCode() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card w-50 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-envelope mr-2" />
              メールアドレスを有効化
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.cardBodyCode()}</div>
        </div>
      </div>
    );
	}
	// AuthStateがメールアドレス検証の場合だけコンポーネント全体を表示する
  showComponent() {
		let component = null;
    if (this.props.authState === CONST.AMPLIFY.AUTH_STATE.VERIFY) {
			let card = null;
			// メール送信用とメールアドレスを有効化用セクションの表示を切り替える
      if (this.state.emailSended) {
        card = this.showComponentCode();
      } else {
        card = this.showComponentAttr();
      }
      component = (
        <div className="">
          {/* ################################### */}
          {/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
          <div className="content mybgcolor-grey mb-4">
            <div className="">
              <NavbarForLogin />
              <div id="" className="">
                <div id="" className="">
                  <div id="" className="hiddenSidebarSignIn">
                    {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                    {showLoadingToast(this.state.loading)}
                  </div>
                  {/* カード */}
                  {card}
                </div>
              </div>
							<Footer />
            </div>
          </div>
          {/* ################################### */}
          {/* モーダル：エラー */}
          {showModal({
            modalId: "generalFailure",
            modalTitle: (
              <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                エラー
              </span>
            ),
            modalBody: this.setModalMessage(),
            executeButtonLabel: "OK",
            showCancelButtonFlag: false,
            executeFunctionObject: this.emptyReturn
          })}
        </div>
      );
    }
    return component;
  }
  render() {
    return this.showComponent();
  }
}

export default MyVerifyContact;
