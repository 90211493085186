import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { showModal } from "./common/showModal";
import ReactTooltip from "react-tooltip";

export default class ClientEnvListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notExistFlag : false,
      //targetClientEnvId : null, // 再起動対象のClientEnvId

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
    this.startClientEnvManager = this.startClientEnvManager.bind(this);
    this.stopClientEnvManager = this.stopClientEnvManager.bind(this);
  }
  componentWillMount() {
    // 一覧を取得していないときだけ取得する。
    if (this.props.clientEnvInformation.clientEnvList.length <= 0) {
      this.getClientEnvListManager();
    }
  }

  // 開発クライアント環境一覧の更新
  refreshClientEnvList() {
    this.getClientEnvListManager();
  }

  async getClientEnvListManager() {

    try {
      await this.props.startLoading();

      let response = await this.getClientEnvList();
      
      await this.props.setClientEnvList(response);

    } finally {
      await this.props.finishLoading()
    }
  }

  // 開発クライアント環境一覧取得
  async getClientEnvList() {
    var response = {}
    try {
      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/client/list/";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken()
        }
      };
      response = await API.get(api_name, path, my_init);

      if ("errorCode" in response) {
        throw response.errorMessage
      }

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }
    return response;
  }

  async startClientEnvManager() {
    try {
      await this.props.startLoading();

      // EC2起動
      await this.startClient();

      // EC2状態再取得
      let response = await this.getClientEnvList();
      await this.props.setClientEnvList(response);

    } finally {
      await this.props.finishLoading()
    }
  }

  // 開発クライアント環境起動
  async startClient() {
    var response = {}
    try {
      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/client/start";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          InstanceId: this.state.InstanceId,
        }
      };
      response = await API.post(api_name, path, my_init);

      if ("errorCode" in response) {
        throw response.errorMessage
      }

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }
    return response;
  }

  async stopClientEnvManager() {
    try {
      await this.props.startLoading();

      // EC2停止
      await this.stopClient();

      // EC2状態再取得
      let response = await this.getClientEnvList();
      await this.props.setClientEnvList(response);

    } finally {
      await this.props.finishLoading()
    }
  }

  // 開発クライアント環境起動
  async stopClient() {
    var response = {}
    try {
      let session = await Auth.currentSession();
      let api_name = "stagingCognitoAuth";
      let path = "/service/client/stop";
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          InstanceId: this.state.InstanceId,
        }
      };
      response = await API.post(api_name, path, my_init);

      if ("errorCode" in response) {
        throw response.errorMessage
      }

    } catch (error) {
      // 返却値の設定（異常時）
      response = {
        result : false,
        errorMessage : error
      }
    }
    return response;
  }

	// 開発環境一覧を表示するコンポーネント
  renderClientEnvList() {
		let list = [];

    // 開発環境ごとにループ
    //<React.Fragment key={i}>
    
    for (let i = 0; i <= this.props.clientEnvInformation.clientEnvList.length - 1; i++) {

      // iが奇数なら緑背景、偶数なら青背景を適用
      let bgcolor = (i % 2 !== 0) ? "custom-bgcolor-odd border border-success" : "custom-bgcolor-even border border-primary"
      let bastionEnv = this.props.clientEnvInformation.clientEnvList[i].bastion;
      let clientEnv = this.props.clientEnvInformation.clientEnvList[i].client;

      list.push(
        <div>
          <div className={bgcolor}>
            {/* フルサイズ offset1分だけpadding */}
            <div className="h5 col-sm-12 col-sm-offset-1 py-2">
              環境名：{bastionEnv[0].Name}
              <button className="btn btn-primary ml-3" onClick={()=>this.props.showGitCredential(bastionEnv[0].Name)}>
                <i className="fas fa-key mr-2"/>
                Gitクレデンシャル管理
              </button>
            </div>
            {/* 中央寄せ　若干小さ目（11/12サイズ） */}
            <div className="col-sm-11 mx-auto">
              {/* 踏み台サーバ一覧をテーブルで表示する */}
              {
                this.props.clientEnvInformation.clientEnvList.length > 0 &&
                  <span className="bastion-header">
                    <i className="fas fa-window-restore mr-2" />
                    <span className="mr-3 h5">Accessサーバー
                      {this.renderOperationButton(bastionEnv[0].InstanceId, bastionEnv[0].Name, bastionEnv[0].State, "custom-bastion-buttons-area ml-3")}
                    </span>
                    
                  </span>
              }

              {this.renderBastionEnvTables(bastionEnv)}

              {/* 開発クライアント一覧をテーブルで表示する */}
              {
                this.props.clientEnvInformation.clientEnvList.length > 0 &&
                  <span className="bastion-header">
                    <i className="fas fa-window-restore mr-2" />
                    <span className="mr-3 h5">開発クライアント</span>
                  </span>
              }

              {this.renderClientEnvTables(clientEnv)}

              {/* 環境が存在しない場合にメッセージを表示する */}
              {this.showNotExist()}
            </div>
          </div>
          <br />
        </div>
      );
    }
    
    return (
      <div className="card custom-card-height general-card">
        <div className="card-header h5">
          <div className="float-left mt-2">
            <i className="fas fa-desktop mr-2" />
            開発環境一覧
          </div>
          {/* 更新ボタン */}
          <button
            className={`btn btn-light float-right`}
            onClick={() => this.refreshClientEnvList()}
            data-tip="開発環境の一覧が更新されます。"
            data-html={true}
            data-for="refreshClientsToolTip"
          >
            <i className="fas fa-sync-alt" />

            <ReactTooltip id="refreshClientsToolTip" effect="solid" place="left" html={true} />
          </button>
        </div>

        <div className="card-body custom-scroll small">
          {list}
        </div>
      </div>
    )
  }

  renderBastionEnvTables(bastionEnv) {

    let link = ""
    let state = bastionEnv[0].State
    let privateIp = bastionEnv[0].PrivateIpAddress
    if (bastionEnv[0].Destination !== null) {

      link = (
        <a href={"https://" + bastionEnv[0].Destination} className="text-primary"
          target="blank"
          rel="noreferrer noopener">
          {"https://" + bastionEnv[0].Destination}
        </a>
      )
    } else {
      link = "管理者にお問い合わせください。"
    }

    return (
      <table className="table table-bordered custom-table-width table-striped mt-2 mb-4">
        <tr>
          <td className="custom-td-width-eb-ver-manage-extra-small">
            接続先URL
          </td>

          <td className="custom-td-width-eb-app-ver">
            {link}
          </td>
        </tr>
        <tr>
          <td className="custom-td-width-eb-ver-manage-extra-small">
            Ericom Accessサーバー IPアドレス
          </td>

          <td className="custom-td-width-eb-app-ver">
            {privateIp}
          </td>
        </tr>
        <tr>
          <td className="custom-td-width-eb-ver-manage-extra-small">
            Accessサーバーステータス
          </td>

          <td className="custom-td-width-eb-app-ver">
            {state}
          </td>
        </tr>
      </table>
    )
  }

  renderClientEnvTables(clientEnv) {
    return (
      <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th scope="col" className="col-sm-3">
            環境名
          </th>

          <th scope="col" className="col-sm-3">
           接続先IPアドレス
          </th>

          <th scope="col" className="col-sm-3">
            ステータス
          </th>

          <th scope="col" className="col-sm-3">
          </th>
        </tr>
      </thead>
      <tbody>{this.recordCreater(clientEnv)}</tbody>
    </table>
    )
  }

	// リストを受け取り、表に展開する関数
  recordCreater(array) {
    let list = [];
    array.map((data, index) => {
      return list.push(
        <tr key={index}>
          <td> {data.Name} </td>
          <td> {data.Destination} </td>
          <td> {data.State} </td>
          <td>
            <div className="custom-td-buttons-area">
             {this.renderOperationButton(data.InstanceId, data.Name, data.State)}
            </div>
          </td>
        </tr>
      );
    });
    return list;
  }

  // ボタンタグ作成関数
  renderOperationButton(InstanceId, Name, State, AdditionalClass="") {

    let buttonColorType = "btn btn-primary"
    let buttonMessage = "不明"
    let buttonAction = "#clientEnvStop"
    let disabled = false

    if (State === "running") {
      buttonColorType = "btn btn-danger"
      buttonMessage = "停止"
      buttonAction = "#clientEnvStop"
      disabled = false
    } else if (State === "stopped") {
      buttonColorType = "btn btn-primary"
      buttonMessage = "起動"
      buttonAction = "#clientEnvStart"
      disabled = false
    } else if (State === "pending") {
      buttonColorType = "btn btn-warning"
      buttonMessage = "起動中"
      buttonAction = "#clientEnvStart"
      disabled = true
    } else if (State === "stopping" || State === "shutting-down") {
      buttonColorType = "btn btn-warning"
      buttonMessage = "停止中"
      buttonAction = "#clientEnvStart"
      disabled = true
    } else if (State === "terminated") {
      buttonColorType = "btn btn-default"
      buttonMessage = "削除済"
      buttonAction = "#clientEnvStart"
      disabled = true
    }

    return (
      <button 
        type="button"
        className={buttonColorType + " " + AdditionalClass}
        data-toggle="modal"
        data-target={buttonAction}
        onClick={ () =>
          this.setState({
            InstanceId: InstanceId,
            Name:Name
          })
        }
        disabled={disabled}
      >
        {buttonMessage}
      </button>
    )
  }

  // エラーモーダルメッセージ表示関数
	setModalErrorMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
	}
  
	showNotExist(){
		let message = null;
		if (this.state.notExistFlag) {
			message="環境は存在しません。"
		}
		return message
	}

  render() {
    return (
      <div className="section d-flex justify-content-center">

        {this.renderClientEnvList()}

        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalErrorMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}

       {/* モーダルウィンドウ（起動） */}
       <div className="modal fade" id="clientEnvStart" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">確認</h4>
            </div>
            <div className="modal-body">
              <label>{this.state.Name}</label><br />
              <label>このサーバーを起動しますか？</label>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">キャンセル</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.startClientEnvManager}>実行</button>
            </div>
          </div>
          <br />
        </div>
      </div>

        {/* モーダルウィンドウ（停止） */}
        <div className="modal fade" id="clientEnvStop" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">確認</h4>
            </div>
            <div className="modal-body">
              <label>{this.state.Name}</label><br />
              <label>このサーバーを停止しますか？</label>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">キャンセル</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.stopClientEnvManager}>実行</button>
            </div>
          </div>
          <br />
        </div>
      </div>

       {/* showModalではrebootClientEnvManagerの中で呼ばれる関数の参照を渡せないので、独自実装
       showModal({
          modalId: "clientEnvReboot",
          modalTitle: "確認",
          modalBody:
            <div className="modal-body">
              <label>{this.state.ClientEnvId}</label><br />
              <label>{this.state.ComputerName}</label><br />
              <label>このWorkSpaceを再起動しますか？</label>
            </div>,
          executeButtonLabel: "実行",
           
          executeFunctionObject: this.rebootClientEnvManager,
          executeFunctionArgumentsArray: [
            this.rebootClientEnv(this.state.ClientEnvId),
            this.getSnapshotBeforeUpdate
          ]
          
        })*/}
      </div>
    );
  }
}
