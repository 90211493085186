
// ########## Cognitoパスワードのバリデーション

export function checkPasswordLength(password_string) {
	// パスワードは8文字以上、256文字以下
	return ( (password_string.length >= 8) && (password_string.length <= 256) )
}

export function checkPasswordContainsSpecialSymbol(password_string){
	// パスワードに1文字以上必須な特殊記号：　~$*.[]{}()?-"!@#%&/\,<>':;|_~`
	// 「+」と「=」は使用可能ではあるが、特殊記号としてカウントされない
	// 特殊記号が1文字でも含まれていればTRUE
	return /[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\<\>\'\:\;\|\_\~\`]/.test(password_string)
}

export function checkPasswordContainsNumber(password_string){
	// 数字が1文字でも含まれていればTRUE
	return /[0-9]/.test(password_string)
}

export function checkPasswordContainsLowerCase(password_string){
	// 小文字が1文字でも含まれていればTRUE
	return /[a-z]/.test(password_string)
}

export function checkPasswordContainsUpperCase(password_string){
	// 大文字が1文字でも含まれていればTRUE
	return /[A-Z]/.test(password_string)
}

export function checkPasswordContainsSafeChar(password_string){
	// 使用できない文字が1文字でも含まれていればFALSE
	return !/[^0-9a-zA-Z\+\=\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\<\>\'\:\;\|\_\~\`]/.test(password_string)
}

// ########## Cognitoユーザー名のバリデーション

export function checkUserNameLength(user_name_string){
	// ユーザー名は1文字以上、128文字以下
	return ( (user_name_string.length >= 1) && (user_name_string.length <= 128) )
}

export function checkUserNameContainsSafeChar(user_name_string){
	// 既定の文字種以外が入っていたらアウトにしたい
	// 0-9, a-z, A-Z, 「-」,「_」以外が1文字でも入っているとFALSEを返す
	// return true
	return !/[^0-9a-zA-Z\-_]/.test(user_name_string)
	// 以下の指定だと、1文字でもセーフな文字が入っていれば、それ以外が無効でもTRUEを返してしまう。
	// return /[0-9a-zA-Z\-_]/.test(this.state.user_name)
}

// ########## Cognitoメールアドレスのバリデーション

export function checkMailAddressLength(mail_address_string){
	let flag = false
	// matchは文字が含まれていれば配列、なければNullを返す
	if (mail_address_string.match(/@/)) {
		// @で分割して配列にする（@が無いと、配列のインデックスを超えてしまいエラーになるため分岐）
		let mail_split = mail_address_string.split('@')
		// ホスト部は1文字以上
		let host_flag = (mail_split[0].length >= 1)
		// ドメイン部は1文字以上、63文字以下
		let domain_flag = (mail_split[1].length >= 1) && (mail_split[1].length <= 63)
		// メールアドレス全体で320文字以下
		flag = host_flag && domain_flag && (mail_address_string.length <= 320) 
	}
	return flag
}

export function checkMailAddressContainsSafeChar(mail_address_string){
	let flag = false
	// matchは文字が含まれていれば配列、なければNullを返す
	if (mail_address_string.match(/@/)) {
		// @で分割して配列にする（@が無いと、配列のインデックスを超えてしまいエラーになるため分岐）
		let mail_split = mail_address_string.split('@')
		// ホスト部は1文字以上
		let host_flag = checkMailAddressHostContainsSafeChar(mail_split[0])
		// ドメイン部は1文字以上、63文字以下
		let domain_flag = checkMailAddressDomainContainsSafeChar(mail_split[1])
		// @が1文字以下ならTRUE。@が2文字以上あると長さが3以上になり、FALSE
		let at_multi_flag = mail_split.length <= 2
		flag = host_flag && domain_flag && at_multi_flag
	}
	return flag
}

export function checkMailAddressHostContainsSafeChar(host_string){
	// メールアドレスのホスト部に使用不可文字が含まれていないか判定
	// 使用可能な記号：　!#$%&'*+-/=?^_`{|}~.
	// 数字、小文字、大文字も使用可能
	// 使用不可能な文字が1文字でも含まれていればFALSE
	return !/[^0-9a-zA-Z!#\$%&'*\+\-\/\=\?\^_`\{\|\}\~\.]/.test(host_string)
}

// メールアドレスのドメイン部のバリデート（@以下）
export function checkMailAddressDomainContainsSafeChar(domain_string){
	// 「.」の直前はアルファベット・数字のみ
	let dot_before_flag = !/[^a-zA-Z0-9]+[\.]/.test(domain_string)
	// 「-」の直前はアルファベット・数字・「-」のみ　★「-」連続はOK
	let hyphen_before_flag = !/[^a-zA-Z0-9\-]+[\-]/.test(domain_string)
	// 「.」の直後はアルファベットのみ　★「.」直後の数字だけNG
	let dot_after_flag = !/[\.][^a-zA-Z]+/.test(domain_string)
	// 「-」の直後はアルファベット・数字・「-」のみ　★「-」連続はOK
	let hyphen_after_flag = !/[\-][^a-zA-Z0-9\-]+/.test(domain_string)
	// 先頭が記号だとFalse
	let dot_hyphen_start_flag = !/^[\.\-]/.test(domain_string)
	// 末尾が記号だとFalse
	let dot_hyphen_finish_flag = !/[\.\-]$/.test(domain_string)
	// 1文字でも使用不可能な文字が含まれていればFALSE
	// 使用可能なのは　a-z, A-Z, 0-9, 「.」、「-」のみ
	let safe_char_flag = !/[^a-zA-Z0-9\.\-]/.test(domain_string)
	return safe_char_flag && dot_before_flag && hyphen_before_flag && dot_after_flag && hyphen_after_flag && dot_hyphen_start_flag && dot_hyphen_finish_flag
}

export function checkCodeLengthAndNumber(code_string){
	// 6桁の数値（完全一致）のみをTrueとする。文字列中に6桁の数値が含まれていてもFalse
	return /^[0-9]{6}$/.test(code_string)
}
