// 定数群を宣言
export const VAR_TYPE = {
  // EB環境詳細のセット
  SET_EB_ENV_DETAIL: "SET_EB_ENV_DETAIL",
  CLEAR_EB_ENV_DETAIL: "RESET_EB_ENV_DETAIL",
  // ローディングフラグのセット
  SET_DETAIL_LOADING_FLAG: "SET_DETAIL_LOADING_FLAG",
  RESET_DETAIL_LOADING_FLAG: "RESET_DETAIL_LOADING_FLAG",
};

// #########################   EB DETAIL
export const setEbEnvDetail = envDetail => {
  return {
    type: VAR_TYPE.SET_EB_ENV_DETAIL,
    payload: {envDetail}
  };
};
export const clearEbEnvDetail = () => {
  return {
    type: VAR_TYPE.CLEAR_EB_ENV_DETAIL,
    payload: {}
  };
};
export const setDetailLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_DETAIL_LOADING_FLAG,
    payload: {}
  };
};
export const resetDetailLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_DETAIL_LOADING_FLAG,
    payload: {}
  };
};


export const actions = {
  setEbEnvDetail: setEbEnvDetail,
  clearEbEnvDetail: clearEbEnvDetail,
  setDetailLoadingFlag: setDetailLoadingFlag,
	resetDetailLoadingFlag: resetDetailLoadingFlag,
};
