import { VAR_TYPE, actions } from "../actions/AccessKeySection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	envAccessKey: {},
	loadingAccessKeyFlag: false,
};

// Storeを更新するための関数
export const envReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  EB ACCESS_KEY
		// 情報をセット
		case VAR_TYPE.SET_EB_ENV_ACCESS_KEY:
			let tmpEnvAccessKey = state.envAccessKey
			tmpEnvAccessKey[action.payload.envAccessKey.envName] = action.payload.envAccessKey
      return {
				...state,
				envAccessKey: tmpEnvAccessKey,
			};
		// リセット
		case VAR_TYPE.CLEAR_EB_ENV_ACCESS_KEY:
      return {
				...state,
				envAccessKey: {},
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_ACCESS_KEY_LOADING_FLAG:
      return {
				...state,
				loadingAccessKeyFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_ACCESS_KEY_LOADING_FLAG:
      return {
				...state,
				loadingAccessKeyFlag: false
			};
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
