import { VAR_TYPE, actions } from "../actions/NoticeSection";

// Storeの定義
const initialState = {
  // 初期状態では何も設定されていない
  // Notice一覧のリストをそのまま入れる
  noticeList: [],
  totalRecordNumber: null,
  nowPageNumber: null,
  loadingNoticeFlag: false
};

// Storeを更新するための関数
export const noticeReducer = (state = initialState, action) => {
  switch (action.type) {
    // ###################  NOTICE LIST
    // 情報をセット
    case VAR_TYPE.SET_NOTICE_LIST:
      return {
        ...state,
        noticeList: action.payload.response.noticeList,
        totalRecordNumber: action.payload.response.totalRecordNumber,
        nowPageNumber: action.payload.nowPageNumber
			};
		// 現在のページをセット
    case VAR_TYPE.SET_NOTICE_PAGE_NUMBER:
      return {
        ...state,
        nowPageNumber: action.payload.nowPageNumber
      };
    // リセット
    case VAR_TYPE.CLEAR_NOTICE_LIST:
      return {
        ...state,
        noticeList: [],
        totalRecordNumber: null,
        nowPageNumber: null
      };
    // ローディングフラグをセット
    case VAR_TYPE.SET_NOTICE_LOADING_FLAG:
      return {
        ...state,
        loadingNoticeFlag: true
      };
    // リセット
    case VAR_TYPE.RESET_NOTICE_LOADING_FLAG:
      return {
        ...state,
        loadingNoticeFlag: false
      };

    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
