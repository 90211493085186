import { SWITCH_TYPE, actions } from "../actions/SwitchComponent";

// Storeの定義
const initialState = {
  // 初期状態ではTOP画面を表示
  switchType: SWITCH_TYPE.SHOW_TOP
};

// Storeを更新するための関数
export const switchReducer = (state = initialState, action) => {
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    // トップページ
    case SWITCH_TYPE.SHOW_TOP:
      return {
        switchType: SWITCH_TYPE.SHOW_TOP
      };
    // 契約情報
    case SWITCH_TYPE.SHOW_ACCOUNT:
      return {
        switchType: SWITCH_TYPE.SHOW_ACCOUNT
      };
    // アクセスキー
    case SWITCH_TYPE.SHOW_ACCESS_KEY:
      return {
        switchType: SWITCH_TYPE.SHOW_ACCESS_KEY
      };
    // 環境一覧
    case SWITCH_TYPE.SHOW_EB_ENV_LIST:
      return {
        switchType: SWITCH_TYPE.SHOW_EB_ENV_LIST
      };
    // 環境詳細
    case SWITCH_TYPE.SHOW_EB_ENV_DETAIL:
      return {
        switchType: SWITCH_TYPE.SHOW_EB_ENV_DETAIL
      };
    // 手動デプロイ
    case SWITCH_TYPE.SHOW_APPLI_DEPLOY:
      return {
        switchType: SWITCH_TYPE.SHOW_APPLI_DEPLOY
      };
    // バージョン管理
    // case SWITCH_TYPE.SHOW_APPLI_VERSION:
    //   return {
    //     switchType: SWITCH_TYPE.SHOW_APPLI_VERSION
    //   };
    // ユーザー管理
    case SWITCH_TYPE.SHOW_USER_LIST:
      return {
        switchType: SWITCH_TYPE.SHOW_USER_LIST
      };
    // お知らせ
    case SWITCH_TYPE.SHOW_NOTICE:
      return {
        switchType: SWITCH_TYPE.SHOW_NOTICE
      };
    // 開発環境一覧
    case SWITCH_TYPE.SHOW_CLIENT_ENV_LIST:
      return {
        switchType: SWITCH_TYPE.SHOW_CLIENT_ENV_LIST
			};
		// Git Credential
    case SWITCH_TYPE.SHOW_GIT_CREDENTIAL:
      return {
        switchType: SWITCH_TYPE.SHOW_GIT_CREDENTIAL
      };
    case SWITCH_TYPE.SHOW_RDS:
      return {
        // ...state,
        switchType: SWITCH_TYPE.SHOW_RDS
      };
    case SWITCH_TYPE.SHOW_S3:
      return {
        // ...state,
        switchType: SWITCH_TYPE.SHOW_S3
      };
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
