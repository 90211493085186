import {combineReducers} from 'redux'

// import * as 【任意の名前（？）】としないと、ドットでメンバにアクセスできない
import * as SwitchComponent from './SwitchComponent'
import * as AccountSection from './AccountSection'
import * as UserListSection from './UserListSection'
// import * as EbSwitchComponent from './EbSwitchComponent'
import * as EbEnvListSection from './EbEnvListSection'
import * as EbEnvDetailSection from './EbEnvDetailSection'
import * as AccessKeySection from './AccessKeySection'
// import * as AppliVersionSection from './AppliVersionSection'
import * as AppliDeploySection from './AppliDeploySection'
import * as NoticeSection from './NoticeSection'
//import * as WorkspaceListSection from './WorkspaceListSection'
import * as ClientEnvListSection from './ClientEnvListSection'
import * as GitCredentialSection from './GitCredentialSection'
import * as TopSection from './TopSection'

import {SWITCH_TYPE} from '../actions/SwitchComponent'
// export const ALL_STORE_RESET = "ALL_STORE_RESET"

// さらに、CombineReducersの中身をJSOｎ形式にしないと、メンバにアクセスできない
// 複数あるReducerをひとまとめにする（※ここで付けた名前をConnectで使用するのに注意）
export const appReducer = combineReducers({
		switchComponent: SwitchComponent.switchReducer,
		accountSection: AccountSection.accountReducer,
		userListSection: UserListSection.userReducer,
		// ebSwitchComponent: EbSwitchComponent.envReducer,
		ebEnvListSection: EbEnvListSection.envReducer,
		ebEnvDetailSection: EbEnvDetailSection.envReducer,
		accessKeySection: AccessKeySection.envReducer,
		// appliVersionSection: AppliVersionSection.envReducer,
		appliDeploySection: AppliDeploySection.envReducer,
		noticeSection: NoticeSection.noticeReducer,
		//workspaceListSection: WorkspaceListSection.workspaceReducer,
		clientEnvListSection: ClientEnvListSection.clientEnvReducer,
		gitCredentialSection: GitCredentialSection.gitCredentialReducer,
		topSection: TopSection.topReducer,
})

export const rootReducer = ( state,action)=>{
	// 全てのアクションはここを経由する
	// console.log("Root Reducer")
	// console.log(state)
	// console.log(action)
	if (action.type === SWITCH_TYPE.ALL_STORE_RESET) {
		// 特定のaction.typeが通過するときにStateをリセットする
		// console.log("Storeリセット前")
		// console.log(state)
		state = undefined
		// console.log("Storeリセット後")
		// console.log(state)
		// console.log("Root Clear Store")
	}
	return appReducer(state,action)
}

// 複数あるActionCreater＝Actionsをひとまとめにする
export const actionCreater = {
		switchComponent: SwitchComponent.actionCreater.actions,
		accountSection: AccountSection.actionCreater.actions,
		userListSection: UserListSection.actionCreater.actions,
		// ebSwitchComponent: EbSwitchComponent.actionCreater.actions,
		ebEnvListSection: EbEnvListSection.actionCreater.actions,
		ebEnvDetailSection: EbEnvDetailSection.actionCreater.actions,
		accessKeySection: AccessKeySection.actionCreater.actions,
		// appliVersionSection: AppliVersionSection.actionCreater.actions,
		appliDeploySection: AppliDeploySection.actionCreater.actions,
		noticeSection: NoticeSection.actionCreater.actions,
		//workspaceListSection: WorkspaceListSection.actionCreater.actions,
		clientEnvListSection: ClientEnvListSection.actionCreater.actions,
		gitCredentialSection: GitCredentialSection.actionCreater.actions,
		topSection: TopSection.actionCreater.actions,
}