import React, { Component } from "react";
// import logo from '../../webfonts/WebPerformerWhite.png'
import logo from '../../webfonts/WPC_Logotype_white.png'

// フッターにレスポンシブはしない方が良い（トグルの方向を操作できない＋ドロップアップがレスポンシブ時にドロップダウンになる）
class NavbarForLogin extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top justify-content-around">
        <span className="navbar-brand">
          <img className="image-resize mr-2" src={logo} alt="Web Performer"></img>
					{/* WebPerformer Cloud */}
        </span>
      </nav>
    );
  }
}

export default NavbarForLogin;
