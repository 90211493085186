import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { CONST } from "../environments/environment";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import {switchEnvHealth} from './common/switchEnvHealth'

// 環境イベントの重大度リスト + ALL
const EVENT_TYPE = ["ALL", "INFO", "WARN", "ERROR", "FATAL", "TRACE", "DEBUG"];
// DBログ詳細のログ種類リスト + ALL
const DB_LOG_DETAIL_TYPE = [
  "ALL",
  "LOG",
  "INFO",
  "NOTICE",
  "WARNING",
  "ERROR",
  "FATAL",
  "PANIC",
  "HINT",
  // "DETAIL",
  // "QUERY",
  // "CONTEXT",
  // "DEBUG1",
  // "DEBUG2",
  // "DEBUG3",
  // "DEBUG4",
  // "DEBUG5"
];

export default class EbEnvDetailSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // #######################  db log list
      // "yyyy-mm-dd" or ""
      db_log_list_file_name_contains: "",
      // "error/postgresql.log.2019-05-13-07"（例） or ""
      // 返り値のmarkerがNullであれば、最後まで取得できた
      db_log_list_marker: [null],
      // 0,1,...
      db_log_list_page: 0,
      // ログファイルのJSON配列（KEY: LogFileName）
      db_log_list_files: [],
      db_log_list_remain_flag: true,

      // #######################  db log detail
      // "error/postgresql.log.2019-05-13-11"（例）
      db_log_detail_file_name: null, //"error/postgres.log",
      // "5:4032"（例） or "0"（ログファイルの最初から取得するための指定）
      db_log_detail_marker: ["0"],
      // ログファイルがまだ残っているかを判定するフラグ。Falseになれば最後まで取り切ったことになる
      db_log_detail_is_remained_log_file_data: true,
      // 0,1,...
      db_log_detail_page: 0,
      // 改行コード付きの文字列ですべて返ってきている (logFileDataString)→「\n」で分割する
      db_log_detail_rows: [],
      // DBログ詳細をフィルタリングする用
      db_log_detail_rows_filtered: [],
      // 初期では絞り込まない
			db_log_detail_filter_type: "ALL",
			
      // #######################  env event
      // "MTU1Nzg3OTY4OTkwNDo6MDo6MTU1NzkyMDUwOTg0Mw=="（例） or ""
      env_event_next_token: [null],
      // 0,1,...
      env_event_page: 0,
      // イベントのJSON配列（eventType, eventMessage, eventDate）
      env_event_list: [],
      // イベントをフィルタリングする用
      env_event_list_filtered: [],
      // 初期では絞り込まない
      env_event_filter_type: "ALL",
      env_event_remain_flag: true,

      // ヘルスのJSON（appHealth, causes:[], envHealth, envName, envOperationalStatus, refreshDate, instanceHealthJson:{} ）
      env_health: {},
      // DB情報のJSON（availabilityZone, dbInstanceId, engine, engineVersion, instanceStatus, latestRestorableTime）
      db_info: {},
			env_log_url: null,
			// [{log1}, {log2}, ...]
			env_logs_list: [],

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
		this.downloadEnvLog = this.downloadEnvLog.bind(this);
		this.getEnvLogsList = this.getEnvLogsList.bind(this)
    this.setModalMessage = this.setModalMessage.bind(this);
  }
  async componentDidMount() {
    // console.log(this.props);
    await this.getEnvDetail();
  }

  // EB環境詳細取得→3種のリクエストをそれぞれ呼び出すハンドラー的関数
  async getEnvDetail() {
    // ここにtryを書く必要がある。関数の呼び出し元に書いてもcatch出来ない
    try {
      await this.props.startLoading();
      // ローディングフラグの処理はここでしか使用しない
      await this.props.setDetailLoadingFlag();
      let session = await Auth.currentSession();
      // ★複数のAPIを呼ぶ
      let isExecutedAlone = false;

      // 各処理を呼び出す。※ここに「await」を書くと、一つずつ順番にリクエストが送信される
      // （同時に複数のリクエストを出してくれない）
      this.getEnvHealth(isExecutedAlone, session);
      this.getEnvEventPaging(isExecutedAlone, session, 0);
      this.getDbInfo(isExecutedAlone, session);
      this.getDbLogPaging(isExecutedAlone, session, 0);
    } catch (error) {
      // ここは通常は通らない。各関数で個別にエラー処理をしている
      $("#generalFailure").modal();
      // エラー発生時のみローディングフラグをリセットする
      await this.props.resetDetailLoadingFlag();
    } finally {
      this.props.finishLoading();
    }
  }

  // 単独実行された場合のみローディング処理をする
  switchStartLoading(isExecutedAlone) {
    if (isExecutedAlone) {
      this.props.startLoading();
    }
  }
  switchFinishLoading(isExecutedAlone) {
    if (isExecutedAlone) {
      this.props.finishLoading();
    }
  }
  switchThrowError(isExecutedAlone, error) {
    // 単独実行していない場合に、エラーを投げる（単独実行時は）
    if (!isExecutedAlone) {
      throw error;
    }
  }
  // 更新するマーカーの位置を切り替える関数
  switchUpdateMarker(continue_flag, marker_list, new_marker, page) {
    if (continue_flag) {
      // ファイルを最後まで取得しきれていない→マーカーを追加する
      if (page >= marker_list.length - 1) {
        // ページ番号がマーカーリストの最後の要素番号であれば、追加する
        marker_list.push(new_marker);
      } else {
        // 現在表示しているページの次のマーカーを更新
        marker_list[page + 1] = new_marker;
      }
    }
    return marker_list;
  }
  // 更新や実行用関数群
  async refreshEnvEventPaging() {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.setState({ env_event_next_token: [null] });
    await this.getEnvEventPaging(isExecutedAlone, session, 0);
  }
  async invokeEnvEventPaging(page) {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.getEnvEventPaging(isExecutedAlone, session, page);
  }
  async refreshEnvHealth() {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.getEnvHealth(isExecutedAlone, session);
  }
  async refreshEnvLogUrl() {
    // 単独実行しかしない
    await this.getEnvLogUrl();
  }
  async refreshDbInfo() {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.getDbInfo(isExecutedAlone, session);
  }
  async refreshDbLogPaging() {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.setState({ db_log_list_marker: [null] });
    await this.getDbLogPaging(isExecutedAlone, session, 0);
  }
  async invokeDbLogPaging(page) {
    let isExecutedAlone = true;
    let session = await Auth.currentSession();
    await this.getDbLogPaging(isExecutedAlone, session, page);
  }
  async invokeDbLogDetailPaging(event, page, file_name) {
    event.preventDefault();
    // 単独実行しかしない。DBログ詳細にはリフレッシュ不要（ログが書き換えられることが無いため）
    // 直接起動してよい
    await this.setState({ db_log_detail_file_name: file_name });
    await this.getDbLogDetailPaging(page);
  }

  // 【機能１】EB環境のヘルスを取得する関数
  async getEnvHealth(isExecutedAlone, session) {
    try {
      await this.props.startLoading();
      // let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.HEALTH;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          envName: this.props.envName
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // EBのヘルスをStoreに格納
      await this.setState({ env_health: response.envHealth });
      // await this.props.setEbEnvDetail(response);
    } catch (error) {
      await this.setState({
        error_function: "実行環境情報およびヘルスの取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

  // 【機能2】DBの情報を取得する関数
  async getDbInfo(isExecutedAlone, session) {
    try {
      // return する前にローディングを開始しないと、finally句でローディングを終えてしまう
      await this.props.startLoading();
      if (this.props.rdsName === null) {
        // RDS名が無いときにこのAPIを叩くと、リストの一番上のRDSの情報を返す→起動したくない
        return;
      }
      // let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      // let path = "/service/env/health";
      let path = CONST.API_PATH.ENV.DB.INFO;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          rdsName: this.props.rdsName
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // ★DB情報をStoreにセット
      // await this.props.setEbEnvDetail(response);
      await this.setState({ db_info: response.rdsInfo });
    } catch (error) {
      // エラーモーダルを表示する
      await this.setState({
        error_function: "DB情報取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

  // 【機能3】EB環境のイベント一覧をページングで取得する関数
  async getEnvEventPaging(isExecutedAlone, session, page) {
    try {
      await this.props.startLoading();
      // let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      // let path = "/service/env/health";
      let path = CONST.API_PATH.ENV.EVENT;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          envName: this.props.envName,
          nextToken: this.state.env_event_next_token[page]
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // nextTokenがNullなら最後まで取得できた。それ以外ならまだ残っている
      await this.setState({ env_event_remain_flag: response.nextToken !== null });
      await this.setState({
        env_event_list: response.eventList,
        // フィルタリングはリセットする
        env_event_list_filtered: response.eventList,
        env_event_filter_type: "ALL",
        env_event_next_token: this.switchUpdateMarker(
          this.state.env_event_remain_flag,
          this.state.env_event_next_token,
          response.nextToken,
          page
        ),
        // 取得に成功した場合のみ、ページをセット
        env_event_page: page
      });
      // フィルタリング用セレクトボックスをリセットする
      let selectObject = document.getElementById("envEventSelectBox");
      if (selectObject !== null) {
        // 描画される前に実行されてしまうとNullが格納される→エラー
        selectObject.value = this.state.env_event_filter_type;
      }

      // await this.props.setEbEnvDetail(response);
      // await console.log(this.props.ebInformation.envDetail);
    } catch (error) {
      // console.log(error)
      // エラーモーダルを表示する
      await this.setState({
        error_function: "実行環境イベント取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }
  // 環境イベントをフィルタリングする
  async filteringEnvEvent(event_type) {
    // type = event.target.value
    if (event_type === "ALL") {
      // 全件取得→フィルタリングする前のリストをセットし直す
      this.setState({ env_event_list_filtered: this.state.env_event_list });
    } else {
      // それ以外→フィルタリングする
      let tmp_env_event_list = await this.state.env_event_list.filter(data => {
        return data.eventType === event_type;
      });
      await this.setState({ env_event_list_filtered: tmp_env_event_list });
    }
  }
  // イベントリスト検索用セレクトボックスを返す
  showSelectBoxForEnvEvent() {
    let select_box = EVENT_TYPE.map((type, index) => {
      return (
        <option key={index} value={type}>
          {type}
        </option>
      );
    });
    return (
      <select
        defaultValue={this.state.env_event_filter_type}
        className="form-control ml-2"
        onChange={event => this.filteringEnvEvent(event.target.value)}
        key="env_event"
        id="envEventSelectBox"
      >
        {select_box}
      </select>
    );
  }

  // 【機能4】DBのログ一覧をページングで取得する関数
  async getDbLogPaging(isExecutedAlone, session, page) {
    try {
      // return する前にローディングを開始しないと、finally句でローディングを終えてしまう
      await this.props.startLoading();
      if (this.props.rdsName === null) {
        // RDS名が無いときにこのAPIを叩くと、リストの一番上のRDSの情報を返す→起動したくない
        return;
      }
      // let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      // let path = "/service/env/health";
      let path = CONST.API_PATH.ENV.DB.LOG.LIST;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          rdsName: this.props.rdsName,
          fileNameContains: this.state.db_log_list_file_name_contains,
          marker: this.state.db_log_list_marker[page]
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      await this.setState({ db_log_list_remain_flag: response.marker !== null });
      // ★DBログ一覧をStoreにセット
      await this.setState({
        db_log_list_files: response.rdsLogList,
        db_log_list_marker: this.switchUpdateMarker(
          this.state.db_log_list_remain_flag,
          this.state.db_log_list_marker,
          response.marker,
          page
        ),
        // 成功した場合のみページをセット
        db_log_list_page: page
      });
      // await this.props.setEbEnvDetail(response);
      // await console.log(this.props.ebInfomation.envDetail);
    } catch (error) {
      // エラーモーダルを表示する
      await this.setState({
        error_function: "DBログ取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

  // 【機能５】DBのログ詳細をページングで取得する関数。これも単独実行のみ
  async getDbLogDetailPaging(page) {
    try {
      // return する前にローディングを開始しないと、finally句でローディングを終えてしまう
      await this.props.startLoading();
      if (this.props.rdsName === null) {
        // RDS名が無いときにこのAPIを叩くと、リストの一番上のRDSの情報を返す→起動したくない
        return;
      }
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.DB.LOG.DETAIL;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          rdsName: this.props.rdsName,
          logFileName: this.state.db_log_detail_file_name,
          marker: this.state.db_log_detail_marker[page]
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // 改行で区切り、配列にする。その際、最後の行は空白？になっているため削除
      let tmp_db_log_detail_rows = response.logFileDataString.split("\n");
      tmp_db_log_detail_rows.pop();
      // ★DBログ詳細をStoreにセット
      await this.setState({
        db_log_detail_rows: tmp_db_log_detail_rows,
        // フィルタリングをリセットする
        db_log_detail_rows_filtered: tmp_db_log_detail_rows,
        db_log_detail_filter_type: "ALL",
        db_log_detail_is_remained_log_file_data: response.isRemainedLogFileData
      });
      await this.setState({
        db_log_detail_marker: this.switchUpdateMarker(
          this.state.db_log_detail_is_remained_log_file_data,
          this.state.db_log_detail_marker,
          response.marker,
          page
        ),
        // 成功した場合のみページをセット
        db_log_detail_page: page
      });
      // フィルタリング用セレクトボックスをリセットする
      let selectObject = document.getElementById("dbLogDetailSelectBox");
      if (selectObject !== null) {
        // 描画される前に実行されてしまうとNullが格納される→エラー
        selectObject.value = this.state.db_log_detail_filter_type;
      }

      // await console.log(this.state);
      // await this.props.setEbEnvDetail(response);
      // await console.log(this.props.ebInfomation.envDetail);
    } catch (error) {
      // エラーモーダルを表示する
      await this.setState({
        error_function: "DBログ詳細取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }
  // DBログ詳細をフィルタリングする
  async filteringDbLogDetail(log_type) {
    // type = event.target.value
    if (log_type === "ALL") {
      // 全件取得→フィルタリングする前のリストをセットし直す
      this.setState({ db_log_detail_rows_filtered: this.state.db_log_detail_rows });
    } else {
      // それ以外→フィルタリングする
      let tmp_db_log_detail_list = await this.state.db_log_detail_rows.filter(row => {
        // 行でしか取れないため、部分一致で検索する
        // string.indexOf(search_string)→検索用文字列を含んでいる場合はそのインデックス、無ければ「-1」を返す
        return row.indexOf(log_type) !== -1;
      });
      await this.setState({ db_log_detail_rows_filtered: tmp_db_log_detail_list });
    }
  }
  // DBログ詳細検索用セレクトボックスを返す
  showSelectBoxForDbLogDetail() {
    let select_box = DB_LOG_DETAIL_TYPE.map((type, index) => {
      return (
        <option key={index} value={type}>
          {type}
        </option>
      );
    });
    return (
      <select
        defaultValue={this.state.db_log_detail_filter_type}
        className="form-control ml-2"
        onChange={event => this.filteringDbLogDetail(event.target.value)}
        key="db_log_detail"
        id="dbLogDetailSelectBox"
      >
        {select_box}
      </select>
    );
  }

  // ★この関数はページ読み込み時には呼ばない→読み込みフラグやセッションの外部取得は不要
  // 【機能6】EBのログのダウンロードURLを取得する関数
  async getEnvLogsList() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.ENV.LOG;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        queryStringParameters: {
          accessToken: session.getAccessToken().getJwtToken(),
          envName: this.props.envName
        }
      };
      let response = await API.get(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // ★ここでダウンロードURLが取得できる→それを直接ダウンロードしてしまうか？
			// await this.setState({ env_log_url: response.logDownloadUrl });
			await this.setState({ env_logs_list: response })
      // await this.props.setEbEnvDetail(response);
    } catch (error) {
      // ここではなく、ダウンロード用関数でエラーハンドリングする
      // $("#generalFailure").modal();
			// throw error;
			// エラーモーダルを表示する
      await this.setState({
        error_function: "実行環境ログ生成",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }
  async downloadEnvLog() {
    try {
      await this.getEnvLogUrl();
      // window.open(this.state.env_log_url,"blank")
      // 一瞬画面が白くなるが、問題なくダウンロードされ、Reactの元の画面に戻る
      // target: blankを指定すると、ブラウザのポップアップブロックに弾かれる
      // target: blankを指定しなくてもポップアップブロックに弾かれる時がある（弾かれない時もある）
      // window.open(this.state.env_log_url);
      let link = document.createElement("a");
      link.href = this.state.env_log_url;
      // download, type属性を追加してもコンソールに警告が出る。しかし無害なので良いか。ブロックはされない。
      link.click();
    } catch (error) {
      // エラーモーダルを表示する
      await this.setState({
        error_function: "実行環境ログのダウンロード",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    }
  }

  // 環境情報用コンポーネント全体
  showEnvComponent() {
    let component = null;
    // オブジェクトのキー配列の長さが0以外→オブジェクトが空ではない
    if (Object.keys(this.state.env_health).length !== 0) {
      // イベントリスト
      const eventCreater = this.state.env_event_list_filtered.map((data, index) => {
        return (
          <tr key={index}>
            <td className="custom-td-width-eb-deploy">{data.eventType} </td>
            <td className="custom-td-width-message">{data.eventMessage} </td>
            <td className="custom-td-width-eb-day-long">{data.eventDate} </td>
          </tr>
        );
			});
			// ログリスト
			const logCreater = this.state.env_logs_list.map((data, index) =>{
				return (
					<tr key={index}>
						<td>{data.logInstance}</td>
						{/* 作成日時の秒「:SS」を削る */}
						<td>{data.logCreatedDate.slice(0, -3)}</td>
						<td>
							{/* {data.logDownloadUrl} */}
							<a href={data.logDownloadUrl} 
								download 
								className="text-primary"
								target="blank"
								rel="noreferrer noopener"
							>ZIPダウンロードリンク</a>
						</td>
					</tr>
				)
			})
      component = (
        <div className="card-body custom-bgcolor-env border border-primary">
          {/* 環境タイトル */}
          <strong>
            <i className="fas fa-server mr-2" />
						{/* 実行環境 */}
						{this.props.appType}
          </strong>
          {/* 環境情報タイトル */}
          <div className="m-3">
					実行環境情報
            <button
              className={`btn btn-light ml-3`}
              onClick={() => this.refreshEnvHealth()}
              data-tip="実行環境情報・ヘルスステータス詳細が更新されます。"
              data-html={true}
              data-for="refreshEnvInfoDetailToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip
                id="refreshEnvInfoDetailToolTip"
                effect="solid"
                place="top"
                html={true}
              />
            </button>
            
          </div>
          {/* 環境情報 */}
          <div className="custom-scroll">
            <table className="table table-bordered w-100 small bg-white">
              <thead>
                <tr>
                  {/* <th scope="col" className="custom-td-width-4">環境ID</th> */}
                  <th scope="col" className="custom-td-width-eb-env-name-long">
										実行環境名
                  </th>
                  <th scope="col" className="custom-td-width-eb-env-health">
                    ヘルス
                  </th>
                  <th scope="col" className="custom-td-width-eb-app-health">
                    ヘルスステータス
                  </th>
                  <th scope="col" className="custom-td-width-eb-env-status">
                    状態
                  </th>
                  <th scope="col" className="custom-td-width-eb-day-long">
                    情報取得日時
                  </th>
                  <th scope="col" className="custom-td-width-eb-day-long">
                    URL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {switchEnvHealth(this.state.env_health.envHealth)}
                    {this.state.env_health.envName}
                  </td>
                  <td> {this.state.env_health.envHealth} </td>
                  <td> {this.state.env_health.appHealth} </td>
                  <td> {this.state.env_health.envOperationalStatus} </td>
                  <td> {this.state.env_health.refreshDate} </td>
                  <td>
                    <a
                      href={`${this.props.envEndpoint}`}
                      className="text-primary"
                      target="blank"
                      rel="noreferrer noopener"
                    >
											{/* 表示するURLは、DBコネクト環境はHTTP、Web環境はHTTPS固定
											※環境作成時の申込書にHTTPSか否かを選択する欄は設けない。DBコネクト環境はWPの制約でHTTPアクセス固定のため */}
                      {this.props.envEndpoint}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 環境ヘルス詳細  */}
          <div className="m-3">ヘルスステータス詳細</div>
          <div className="custom-scroll">
            <table className="table table-bordered w-100 small bg-white">
              <thead>
                <tr>
                  <th scope="col" className="custom-td-width-alpha2">
                    OK
                  </th>
                  <th scope="col" className="custom-td-width-alpha6">
                    Severe
                  </th>
                  <th scope="col" className="custom-td-width-alpha7">
                    Warning
                  </th>
                  <th scope="col" className="custom-td-width-alpha8">
                    Degraded
                  </th>
                  <th scope="col" className="custom-td-width-alpha7">
                    Pending
                  </th>
                  <th scope="col" className="custom-td-width-alpha4">
                    Info
                  </th>
                  <th scope="col" className="custom-td-width-alpha8">
                    Unknown
                  </th>
                  <th scope="col" className="custom-td-width-alpha6">
                    NoData
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {this.state.env_health.instanceHealthJson.Ok} </td>
                  <td> {this.state.env_health.instanceHealthJson.Severe} </td>
                  <td> {this.state.env_health.instanceHealthJson.Warning} </td>
                  <td> {this.state.env_health.instanceHealthJson.Degraded} </td>
                  <td> {this.state.env_health.instanceHealthJson.Pending} </td>
                  <td> {this.state.env_health.instanceHealthJson.Info} </td>
                  <td> {this.state.env_health.instanceHealthJson.Unknown} </td>
                  <td> {this.state.env_health.instanceHealthJson.NoData} </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 環境イベント */}
          <div className="m-3 text-left">
            イベントリスト　({this.state.env_event_list_filtered.length}件表示中)
            {/* 更新ボタン */}
            <button
              className={`btn btn-light ml-3`}
              onClick={() => this.refreshEnvEventPaging()}
              data-tip="実行環境のイベントリストが更新されます。"
              data-html={true}
              data-for="refreshEnvEventDetailToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip
                id="refreshEnvEventDetailToolTip"
                effect="solid"
                place="top"
                html={true}
              />
            </button>
            <div className="form-inline mt-2">
              {/* フィルタリング用セレクトボックス */}
              <i className="fas fa-search mr-2" />
              イベントタイプの絞り込み
              {this.showSelectBoxForEnvEvent()}
            </div>
          </div>
          <div className="custom-scroll custom-table-height-scroll">
            <div className="">
              <table className="m-0 table table-bordered w-100 small bg-white table-striped sticky_table">
                <thead className="">
                  <tr>
                    {/* <th scope="col" className="custom-td-width-4">環境ID</th> */}
                    <th scope="col" className="custom-td-width-eb-deploy bg-white">
                      タイプ
                    </th>
                    {/* 他の要素にmin-widthを設定し、ここだけwidth:100%を指定することで、無理やり残り幅すべてを実現 */}
                    <th scope="col" className="custom-td-width-message bg-white">
                      メッセージ
                    </th>
                    <th scope="col" className="custom-td-width-eb-day-long bg-white">
                      発生日時
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {/* 配列→map */}
                  {eventCreater}
                </tbody>
              </table>
            </div>
          </div>

          {/* 環境イベントのページング用セクション */}
          <div className="text-center">
            <button
              className="btn"
              onClick={() => this.invokeEnvEventPaging(this.state.env_event_page - 1)}
              disabled={this.state.env_event_page <= 0}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {this.state.env_event_page + 1}
            </button>
            <button
              className="btn"
              onClick={() => this.invokeEnvEventPaging(this.state.env_event_page + 1)}
              disabled={!this.state.env_event_remain_flag}
            >
              次
            </button>
          </div>

					{/* 環境ログセクション */}
          <div className="m-3 text-left">
            ログリスト　({this.state.env_logs_list.length}件表示中)
						{/* 環境ログ生成 */}
            <button className="btn btn-primary ml-3" onClick={() => $("#downloadConfirm").modal()}>
              <i className="fas fa-download mr-2" />
              実行環境ログ生成
            </button>
          </div>
					<div className="custom-scroll">
            <table className="table table-bordered w-100 small bg-white">
              <thead>
                <tr>
                  <th scope="col" className="">
                    サーバID
                  </th>
                  <th scope="col" className="">
                    ログ生成日時
                  </th>
                  <th scope="col" className="">
                    ダウンロードリンク
                  </th>
                </tr>
              </thead>
              <tbody>
								{logCreater}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return component;
  }

  // DB用コンポーネント全体
  showDbComponent() {
    let component = null;
    let logCreater = null;
    // オブジェクトのキー配列の長さが0以外→オブジェクトが空ではない
    if (Object.keys(this.state.db_log_list_files).length !== 0) {
      // DBログ一覧を表に展開する
      logCreater = this.state.db_log_list_files.map((data, index) => {
        return (
          <tr key={index}>
            <td className="custom-td-width-eb-env-name-long">{this.props.envName} </td>
            <td className="custom-td-width-eb-env-name-long">{this.props.rdsName} </td>
            <td className="custom-td-width-log">
              <a
                href="#db-log"
								className="text-primary"
                onClick={event => this.invokeDbLogDetailPaging(event, 0, data.LogFileName)}
              >
                {data.LogFileName}
              </a>
            </td>
          </tr>
        );
      });
    }
    // DB Infoが取れたらセクションを表示する
    if (Object.keys(this.state.db_info).length !== 0) {
      component = (
        <div className="card-body custom-bgcolor-db border border-warning">
          <hr />
          {/* データベースセクション用タイトル */}
          <strong>
            <i className="fas fa-database mr-2" />
            データベース
          </strong>
          {/* DB情報タイトル */}
          <div className="m-3">
            DB情報
            {/* 更新ボタン */}
            <button
              className={`btn btn-light ml-3`}
              onClick={() => this.refreshDbInfo()}
              data-tip="DB情報が更新されます。"
              data-html={true}
              data-for="refreshDbInfoDetailToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip
                id="refreshDbInfoDetailToolTip"
                effect="solid"
                place="top"
                html={true}
              />
            </button>
          </div>
          {/* DB情報 */}
          <div className="custom-scroll">
            <table className="table table-bordered w-100 small bg-white">
              <tbody>
                <tr>
                  <th scope="col" className="custom-td-width-eb-env-name-long">
                    データベース名
                  </th>
                  <td> {this.state.db_info.dbInstanceId} </td>
                </tr>
                <tr>
                  <th scope="col" className="">
                    稼働状態
                  </th>
                  <td> {this.state.db_info.instanceStatus} </td>
                </tr>
                <tr>
                  <th scope="col" className="">
                    エンジン名
                  </th>
                  <td> {this.state.db_info.engine} </td>
                </tr>
                <tr>
                  <th scope="col" className="">
                    エンジンバージョン
                  </th>
                  <td> {this.state.db_info.engineVersion} </td>
                </tr>
								<tr>
									<th scope="col" className="">構成</th>
									<td>{this.state.db_info.multiAz ? "Multi構成":"Single構成"}</td>
								</tr>
                {/* <tr>
                  <th scope="col" className="">
                    アベイラビリティゾーン
                  </th>
                  <td> {this.state.db_info.availabilityZone} </td>
                </tr> */}
                <tr>
                  <th scope="col" className="">
                    リストア可能日時
                  </th>
                  <td> {this.state.db_info.latestRestorableTime} </td>
                </tr>
                <tr>
                  <th scope="col" className="">
                    JDBC接続URL
                  </th>
                  <td>
                    {`jdbc:postgresql://${this.state.db_info.endpoint.Address}:${
                      this.state.db_info.endpoint.Port
                    }/postgres`}
                  </td>
                </tr>
                <tr>
                  <th>実行DBユーザー</th>
                  <td>（管理者にお問い合わせください。）</td>
                </tr>
                <tr>
                  <th>実行DBパスワード</th>
                  <td>（管理者にお問い合わせください。）</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* DBログ一覧タイトル */}
          <div className="m-3" id="db-log">
            ログリスト ({this.state.db_log_list_files.length}件表示中)
            {/* 更新ボタン */}
            <button
              className={`btn btn-light ml-3`}
              onClick={() => this.refreshDbLogPaging()}
              data-tip="DBのログリストが更新されます。"
              data-html={true}
              data-for="refreshDbLogDetailToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip id="refreshDbLogDetailToolTip" effect="solid" place="top" html={true} />
            </button>
          </div>
          {/* DBログ一覧 */}
          <div className="custom-scroll custom-table-height-scroll">
            <div className="">
              <table className="m-0 table table-bordered w-100 small bg-white table-striped sticky_table">
                <thead className="">
                  <tr>
                    {/* <th scope="col" className="custom-td-width-4">環境ID</th> */}
                    <th scope="col" className="custom-td-width-eb-env-name-long bg-white">
											実行環境名
                    </th>
                    {/* 他の要素にmin-widthを設定し、ここだけwidth:100%を指定することで、無理やり残り幅すべてを実現 */}
                    <th scope="col" className="custom-td-width-eb-env-name-long bg-white">
                      データベース名
                    </th>
                    <th scope="col" className="custom-td-width-log bg-white">
                      ログ情報
                      <a
                        data-tip="ログ情報のリンクをクリックすると、そのログの詳細情報がページ下部に表示されます。"
                        data-html={true}
                        data-for="dbLogDetailToolTip"
                      >
                        <i className="fas fa-question-circle text-primary ml-2" />
                        <ReactTooltip
                          id="dbLogDetailToolTip"
                          effect="solid"
                          place="top"
                          html={true}
                        />
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {/* 配列→map */}
                  {logCreater}
                </tbody>
              </table>
            </div>
          </div>
          {/* DBログ一覧用ページング */}
          <div className="text-center">
            <button
              className="btn"
              onClick={() => this.invokeDbLogPaging(this.state.db_log_list_page - 1)}
              disabled={this.state.db_log_list_page <= 0}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {" "}
              {this.state.db_log_list_page + 1}{" "}
            </button>
            <button
              className="btn"
              onClick={() => this.invokeDbLogPaging(this.state.db_log_list_page + 1)}
              disabled={!this.state.db_log_list_remain_flag}
            >
              次
            </button>
          </div>

          {/* DBログ詳細 */}
          {this.showDbLogDetail()}
        </div>
      );
    }
    return component;
  }
  // DBログ詳細用コンポーネント
  showDbLogDetail() {
    let component = null;
    // DBログファイル名が指定されている場合だけ表示
    if (this.state.db_log_detail_file_name !== null) {
      const logDetailCreater = this.state.db_log_detail_rows_filtered.map((data, index) => {
        return (
          <tr key={index}>
            <td>{data}</td>
          </tr>
        );
      });
      component = (
        <React.Fragment>
          <hr />
          {/* DBログ詳細タイトル */}
          <div className="m-3">
            ログ詳細参照 ({this.state.db_log_detail_rows_filtered.length}行表示中)：　
            {this.state.db_log_detail_file_name}
            <div className="form-inline mt-2">
              {/* フィルタリング用セレクトボックス */}
              <i className="fas fa-search mr-2" />
              ログタイプの絞り込み
              {this.showSelectBoxForDbLogDetail()}
            </div>
          </div>

          {/* DBログ詳細 */}
          <div className="custom-table-height-scroll">
            <table className="table table-bordered small table-striped bg-white">
              <tbody className="custom-scroll">{logDetailCreater}</tbody>
            </table>
          </div>
          {/* DBログ詳細用ページング */}
          <div className="text-center">
            <button
              className="btn"
              onClick={() => this.getDbLogDetailPaging(this.state.db_log_detail_page - 1)}
              disabled={this.state.db_log_detail_page <= 0}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {this.state.db_log_detail_page + 1}
            </button>
            <button
              className="btn"
              onClick={() => this.getDbLogDetailPaging(this.state.db_log_detail_page + 1)}
              disabled={!this.state.db_log_detail_is_remained_log_file_data}
            >
              次
            </button>
          </div>
        </React.Fragment>
      );
    }
    return component;
  }
  // エラーモーダル用
  emptyReturn() {
    return null;
  }
  // エラーモーダルにメッセージをセットする
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key="error" />,
        `${this.state.error_message}`
      ];
    }
    return message;
  }

  render() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
            <div className="float-left mt-2">
              <i className="fas fa-cube mr-2" />
              実行環境情報詳細
            </div>
            {/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
              onClick={() => this.getEnvDetail()}
              data-tip="実行環境詳細情報が更新されます。"
              data-html={true}
              data-for="refreshAllDetailToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip id="refreshAllDetailToolTip" effect="solid" place="left" html={true} />
            </button>
            {/* 戻るボタン */}
            <button
              type="button"
              className="btn btn-light float-right"
              onClick={() => this.props.showEnvList()}
              data-tip="アプリケーション・実行環境の一覧画面に戻ります。"
              data-html={true}
              data-for="backDetailToolTip"
            >
              <i className="fas fa-reply mr-2" />
              実行環境一覧に戻る
              <ReactTooltip id="backDetailToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>
          <div className="card-body custom-scroll">
            {/* 環境コンポーネント全体 */}
            {this.showEnvComponent()}
            <br />
            {/* DBコンポーネント全体 */}
            {this.showDbComponent()}
          </div>
        </div>
        {/* モーダル：ログダウンロード確認 */}
        {showModal({
          modalId: "downloadConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: "サーバのログをZIPファイルで生成してもよろしいですか？",
          executeFunctionObject: this.getEnvLogsList
        })}
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
