
import React from "react";
import ReactTooltip from "react-tooltip";
import {API, Auth} from 'aws-amplify'

import {CONST} from '../../environments/environment'


// 環境一覧取得APIの実行用
export async function executeGetUserList(){
	let session = await Auth.currentSession();
	let api_name = CONST.API_NAME;
	let path = CONST.API_PATH.USER;
	let my_init = {
		headers: {
			Authorization: session.getIdToken().getJwtToken()
		},
		queryStringParameters: {
			accessToken: session.getAccessToken().getJwtToken()
		}
	};
	let response = await API.get(api_name, path, my_init);
	// console.log(response);
	if ("errorMessage" in response) {
		throw response;
	}
	return response
}

// ### 