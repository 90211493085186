// 定数群を宣言
export const VAR_TYPE = {
  SET_USERS: "SET_USERS",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  ADD_USER: "ADD_USER",
	CLEAR_USERS: "CLEAR_USERS",
	SET_USER_LOADING_FLAG: "SET_USER_LOADING_FLAG",
	RESET_USER_LOADING_FLAG: "RESET_USER_LOADING_FLAG",
	SET_USER_NOT_EXIST_FLAG: "SET_USER_NOT_EXIST_FLAG",
	RESET_USER_NOT_EXIST_FLAG: "RESET_USER_NOT_EXIST_FLAG",
};

export const setUsers = userList => {
  return {
    type: VAR_TYPE.SET_USERS,
    payload: { userList }
  };
};

// 更新するユーザーが何番目かと、変更する値を取得する
export const updateUser = (index, email) => {
  return {
    type: VAR_TYPE.UPDATE_USER,
    payload: { index, email }
  };
};

// 削除するユーザーが何番目かを取得する
export const deleteUser = index => {
  return {
    type: VAR_TYPE.DELETE_USER,
    payload: { index }
  };
};

// 1人のユーザー情報を取得する
export const addUser = user => {
  return {
    type: VAR_TYPE.ADD_USER,
    payload: { user }
  };
};

export const clearUsers = () => {
  return {
    type: VAR_TYPE.CLEAR_USERS,
    payload: {}
  };
};
export const setUserLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_USER_LOADING_FLAG,
    payload: {}
  };
};
export const resetUserLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_USER_LOADING_FLAG,
    payload: {}
  };
};
export const setUserNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_USER_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetUserNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_USER_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
  setUsers: setUsers,
  updateUser: updateUser,
  deleteUser: deleteUser,
  addUser: addUser,
	clearUsers: clearUsers,
	setUserLoadingFlag: setUserLoadingFlag,
	resetUserLoadingFlag: resetUserLoadingFlag,
	setUserNotExistFlag: setUserNotExistFlag,
	resetUserNotExistFlag: resetUserNotExistFlag,
};
