// 定数群を宣言
export const VAR_TYPE = {
	ACCOUNT_INFORMATION: "ACCOUNT_INFORMATION",
	CLEAR_INFORMATION: "CLEAR_INFORMATION",
	SET_LOADING_FLAG: "SET_LOADING_FLAG",
	RESET_LOADING_FLAG: "RESET_LOADING_FLAG",
	// GET_ACCOUNT_INFORMATION_LENGTH: "GET_ACCOUNT_INFORMATION_LENGTH"
};

export const setAccountInformation = (accountInformation) => {
  return {
    type: VAR_TYPE.ACCOUNT_INFORMATION,
    payload: {
			accountInformation
		}
  };
};

export const clearAccountInformation = () => {
  return {
    type: VAR_TYPE.CLEAR_INFORMATION,
    payload: {
		}
  };
};

export const setLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_LOADING_FLAG,
    payload: {
		}
  };
};

export const resetLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_LOADING_FLAG,
    payload: {
		}
  };
};
// export const getAccountInformationLength = () => {
//   return {
//     type: VAR_TYPE.GET_ACCOUNT_INFORMATION_LENGTH,
//     payload: {
// 		}
//   };
// };

export const actions = {
	setAccountInformation: setAccountInformation,
	clearAccountInformation: clearAccountInformation,
	setLoadingFlag: setLoadingFlag,
	resetLoadingFlag: resetLoadingFlag,
	// getAccountInformationLength: getAccountInformationLength,
}