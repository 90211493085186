import { connect } from "react-redux";

import TopSection from "../components/TopSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    noticeInformation:{
			noticeList: state.topSection.noticeList,
			totalRecordNumber: state.topSection.totalRecordNumber,
			nowPageNumber: state.topSection.nowPageNumber,
			loadingNoticeFlag: state.topSection.loadingNoticeFlag,			
		},

		ebInformation:{
			envList: state.topSection.envList,
			loadingListFlag: state.topSection.loadingListFlag,			
		},

		userList:state.topSection.userList,
		loadingUserFlag: state.topSection.loadingUserFlag,

		loadingAccountFlag: state.topSection.loadingAccountFlag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
		//　お知らせ
    showNotice() {
      dispatch(actionCreater.switchComponent.showNotice());
		},
		
		// noticeList: list
		setTopNoticeList(response, nowPageNumber){
			dispatch(actionCreater.topSection.setTopNoticeList(response, nowPageNumber))
		},
		setNoticeList(response, nowPageNumber){
			dispatch(actionCreater.noticeSection.setNoticeList(response, nowPageNumber))
		},
		setNoticeLoadingFlag(){
			dispatch(actionCreater.noticeSection.setNoticeLoadingFlag())
		},
		// 
		resetNoticeLoadingFlag(){
			dispatch(actionCreater.noticeSection.resetNoticeLoadingFlag())
		},
		// 
		clearTopNoticeList(){
			dispatch(actionCreater.topSection.clearTopNoticeList())
		},
		// 
		setTopNoticeLoadingFlag(){
			dispatch(actionCreater.topSection.setTopNoticeLoadingFlag())
		},
		// 
		resetTopNoticeLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopNoticeLoadingFlag())
		},

		// envList: list
		setTopEbEnvList(envList){
			dispatch(actionCreater.topSection.setTopEbEnvList(envList))
		},
		setEbEnvList(envList){
			dispatch(actionCreater.ebEnvListSection.setEbEnvList(envList))
		},
		setListLoadingFlag(){
			dispatch(actionCreater.ebEnvListSection.setListLoadingFlag())
		},
		// 
		resetListLoadingFlag(){
			dispatch(actionCreater.ebEnvListSection.resetListLoadingFlag())
		},
		// 
		clearTopEbEnvList(){
			dispatch(actionCreater.topSection.clearTopEbEnvList())
		},
		// 
		setTopListLoadingFlag(){
			dispatch(actionCreater.topSection.setTopListLoadingFlag())
		},
		// 
		resetTopListLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopListLoadingFlag())
		},

		// userList: list
		setTopUsers(userList){
			dispatch(actionCreater.topSection.setTopUsers(userList))
		},
		setUsers(userList){
			dispatch(actionCreater.userListSection.setUsers(userList))
		},
		setUserLoadingFlag(){
			dispatch(actionCreater.userListSection.setUserLoadingFlag())
		},
		resetUserLoadingFlag(){
			dispatch(actionCreater.userListSection.resetUserLoadingFlag())
		},
		// index: int, email: string
		updateTopUser(index,email){
			dispatch(actionCreater.topSection.updateTopUser(index,email))
		},
		// index: int
		deleteTopUser(index){
			dispatch(actionCreater.topSection.deleteTopUser(index))
		},
		// user: JSON
		addTopUser(user){
			dispatch(actionCreater.topSection.addTopUser(user))
		},
		// 
		clearTopUsers(){
			dispatch(actionCreater.topSection.clearTopUsers())
		},
		setTopUserLoadingFlag(){
			dispatch(actionCreater.topSection.setTopUserLoadingFlag())
		},
		resetTopUserLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopUserLoadingFlag())
		},


		// accountInfomation: JSON
		setAccountInformation(accountInformation){
			dispatch(actionCreater.accountSection.setAccountInformation(accountInformation))
		},
		// accountInfomation: JSON
		clearAccountInformation(){
			dispatch(actionCreater.accountSection.clearAccountInformation())
		},
		setLoadingFlag(){
			dispatch(actionCreater.accountSection.setLoadingFlag())
		},
		// 
		resetLoadingFlag(){
			dispatch(actionCreater.accountSection.resetLoadingFlag())
		},
		setTopAccountLoadingFlag(){
			dispatch(actionCreater.topSection.setTopAccountLoadingFlag())
		},
		resetTopAccountLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopAccountLoadingFlag())
		},
		
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSection);
