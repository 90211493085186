import { VAR_TYPE, actions } from "../actions/GitCredentialSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	bastionGitCredential: {},
	loadingGitCredentialFlag: false,
};

// Storeを更新するための関数
export const gitCredentialReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  Git Credential
		// 情報をセット
		case VAR_TYPE.SET_BASTION_GIT_CREDENTIAL:
			// ★要修正：アクセスキーのデータ構造による
			let tmpEnvAccessKey = state.bastionGitCredential
			tmpEnvAccessKey[action.payload.bastionGitCredential.envName] = action.payload.bastionGitCredential
      return {
				...state,
				bastionGitCredential: tmpEnvAccessKey,
			};
		// リセット
		case VAR_TYPE.CLEAR_BASTION_GIT_CREDENTIAL:
      return {
				...state,
				bastionGitCredential: {},
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_GIT_CREDENTIAL_LOADING_FLAG:
      return {
				...state,
				loadingGitCredentialFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_GIT_CREDENTIAL_LOADING_FLAG:
      return {
				...state,
				loadingGitCredentialFlag: false
			};
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
