// import {ALL_STORE_RESET} from '../reducers/root'

// 定数群を宣言
export const SWITCH_TYPE = {
	// トップページ（サマリーダッシュボード）
	SHOW_TOP: "SHOW_TOP",
	// 
	ALL_STORE_RESET: "ALL_STORE_RESET",
	// 契約情報参照
	SHOW_ACCOUNT: "SHOW_ACCOUNT",
	// アクセスキー追加削除
	SHOW_ACCESS_KEY: "SHOW_ACCESS_KEY",
	// 環境情報一覧
	SHOW_EB_ENV_LIST: "SHOW_EB_ENV_LIST",
	// 環境情報詳細
	SHOW_EB_ENV_DETAIL: "SHOW_EB_ENV_DETAIL",
	// アプリケーションの手動デプロイ
	SHOW_APPLI_DEPLOY: "SHOW_APPLI_DEPLOY",
	// アプリケーションのバージョン管理
	// SHOW_APPLI_VERSION: "SHOW_APPLI_VERSION",
	// ユーザー一覧（削除などはすべて同じページにするか？）
	SHOW_USER_LIST: "SHOW_USER_LIST",
	// お知らせ一覧
  SHOW_NOTICE: "SHOW_NOTICE",
  // 開発環境一覧
	SHOW_CLIENT_ENV_LIST: "SHOW_CLIENT_ENV_LIST",
	// Gitクレデンシャル
	SHOW_GIT_CREDENTIAL: "SHOW_GIT_CREDENTIAL",

	SHOW_RDS: "SHOW_RDS",
	SHOW_S3: "SHOW_S3",
};

// 初期状態。サインアウトするときにも呼び出す
export const showTop = () => {
  return {
    type: SWITCH_TYPE.SHOW_TOP,
    payload: {}
  };
};

// 全てのStoreをリセットする（rootReducerでリセット）。サインアウトするときにも呼び出す
export const resetAllStore = () => {
  return {
    type: SWITCH_TYPE.ALL_STORE_RESET,
    payload: {}
  };
};

export const showAccount = () => {
  return {
    type: SWITCH_TYPE.SHOW_ACCOUNT,
    payload: {}
  };
};

export const showAccessKey = () => {
  return {
    type: SWITCH_TYPE.SHOW_ACCESS_KEY,
    payload: {}
  };
};

export const showEbEnvList = () => {
  return {
    type: SWITCH_TYPE.SHOW_EB_ENV_LIST,
    payload: {}
  };
};

export const showEbEnvDetail = () => {
  return {
    type: SWITCH_TYPE.SHOW_EB_ENV_DETAIL,
    payload: {}
  };
};

export const showAppliDeploy = () => {
  return {
    type: SWITCH_TYPE.SHOW_APPLI_DEPLOY,
    payload: {}
  };
};

// export const showAppliVersion = () => {
//   return {
//     type: SWITCH_TYPE.SHOW_APPLI_VERSION,
//     payload: {}
//   };
// };

export const showUserList = () => {
  return {
    type: SWITCH_TYPE.SHOW_USER_LIST,
    payload: {}
  };
};

export const showNotice = () => {
  return {
    type: SWITCH_TYPE.SHOW_NOTICE,
    payload: {}
  };
};

export const showClientEnvList = () => {
  return {
    type: SWITCH_TYPE.SHOW_CLIENT_ENV_LIST,
    payload: {}
  };
};

export const showGitCredential = () => {
  return {
    type: SWITCH_TYPE.SHOW_GIT_CREDENTIAL,
    payload: {}
  };
};

export const showRds = () => {
  return {
    type: SWITCH_TYPE.SHOW_RDS,
    payload: {}
  };
};

export const showS3 = () => {
  return {
    type: SWITCH_TYPE.SHOW_S3,
    payload: {}
  };
};

export const actions = {
	showTop: showTop,
	resetAllStore: resetAllStore,
	showAccount: showAccount,
	showAccessKey: showAccessKey,
	showEbEnvList: showEbEnvList,
	showEbEnvDetail: showEbEnvDetail,
	showAppliDeploy: showAppliDeploy,
	// showAppliVersion: showAppliVersion,
	showUserList: showUserList,
  showNotice: showNotice,
	showClientEnvList: showClientEnvList,
	showGitCredential: showGitCredential,
	showS3: showS3,
	showRds: showRds,
}