import { connect } from "react-redux";

import AppliDeploySection from "../components/AppliDeploySection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    ebInfomation:{
			envDeploy: state.appliDeploySection.envDeploy,
			loadingDeployFlag: state.appliDeploySection.loadingDeployFlag,
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// Deploy
		// envDeploy
		setEbEnvDeploy(envDeploy){
			dispatch(actionCreater.appliDeploySection.setEbEnvDeploy(envDeploy))
		},
		// 
		clearEbEnvDeploy(){
			dispatch(actionCreater.appliDeploySection.clearEbEnvDeploy())
		},
		// 
		setDeployLoadingFlag(){
			dispatch(actionCreater.appliDeploySection.setDeployLoadingFlag())
		},
		// 
		resetDeployLoadingFlag(){
			dispatch(actionCreater.appliDeploySection.resetDeployLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppliDeploySection);
