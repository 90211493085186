import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { CONST } from "../environments/environment";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import {RESULT_LIMIT, NEW_NOTICE_DATE, executeGetNoticeList} from './common/handlerNotice'

// // 1ページあたりの表示件数
// export const RESULT_LIMIT = 20;
// // 「New」を表示するのは何日前までかを定義
// export const NEW_NOTICE_DATE = 31;

export default class NoticeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeTitle: "",
      noticeDate: "",
      noticeBody: "",
      // ページング1回あたりに取得するお知らせ件数
      result_limit: 3,
      // ページング番号（1~count(*)）
      page_number: 1,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
    this.showBodyComponent = this.showBodyComponent.bind(this);
    this.setModalMessage = this.setModalMessage.bind(this);
  }
  componentWillMount() {
		// お知らせリストの長さが0かつ読み込み済みではない場合だけ実行
    if (
      !this.props.noticeInformation.loadingListFlag &&
      this.props.noticeInformation.noticeList.length === 0
    ) {
      // console.log(this.props);
      this.getNoticeList(1);
    }
	}

	// お知らせ更新用
  refreshNoticeList() {
    this.getNoticeList(1);
  }

  // 【機能１】notice一覧取得
  async getNoticeList(pageNumber) {
    // console.log(this.props);
    try {
			await this.props.startLoading();
			await this.props.setNoticeLoadingFlag();
      // await this.props.setListLoadingFlag();
			let response = await executeGetNoticeList(pageNumber)
      await this.props.setNoticeList(response, pageNumber);
      // ロールバック用に保持していた前回のページ数を更新する
      await this.setState({ page_number: pageNumber });
    } catch (error) {
			// await this.props.resetListLoadingFlag();
			// // セレクトボックスのページ数を元に戻す
      // let selectObject = document.getElementById("noticeSelectBox");
      // selectObject.value = this.state.page_number;
      await this.setState({
        error_function: "お知らせ一覧取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
			$("#generalFailure").modal();
			// エラー発生時のみローディングフラグをリセットする
			await this.props.resetNoticeLoadingFlag();
    } finally {
      this.props.finishLoading();
    }
	}
	// お知らせ本文をセットし、モーダルを表示する
  async setNoticeBody(event, date, title, body) {
    event.preventDefault();
    await this.setState({
      noticeBody: body,
      noticeTitle: title,
      noticeDate: date
    });
    $("#noticeBodyModal").modal();
  }

	// 改行コードを含むお知らせ本文を受け取り、改行した状態で返す
  newLineCreater(string) {
    let lines = [];
    string.split("\n").forEach((line, index) => {
      lines.push(
        <div key={index}>
          {line}
          <br />
        </div>
      );
    });
    return lines;
	}
	// セレクトボックス用のページ一覧を作成する
  pageCreater() {
    let options = [];
    for (let i = 1; i <= this.calcMaxPageNumber(); i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
	}
	// ページ数の最大を返す（10件表示でデータ数15なら、2を返す）
  calcMaxPageNumber() {
		let maxPageNumber = this.props.noticeInformation.totalRecordNumber / RESULT_LIMIT;
		// 切り上げ
    return Math.ceil(maxPageNumber);
	}
	// 現在表示しているお知らせが、全データの何件目からかを返す
  calcStartRecordNumber() {
		// ページ数が0なら1を返し、例えばページ数が1で表示件数が10なら、11を返す
		// return 1 + RESULT_LIMIT * (this.props.noticeInformation.nowPageNumber - 1);
		return Math.min(
			// 0件取得の場合のみは0にする
			this.props.noticeInformation.totalRecordNumber,
			1 + RESULT_LIMIT * (this.props.noticeInformation.nowPageNumber - 1)
		)
	}
	// 現在表示しているお知らせが、全データの何件目までかを返す
  calcFinishRecordNumber() {
		// お知らせの全件数と、1ページあたりの表示件数＊現在のページ数の最小を返す
		// 10件表示で全データ数が15なら、1ページ目は10を返し、2ページ目は15を返す
    return Math.min(
      this.props.noticeInformation.totalRecordNumber,
      RESULT_LIMIT * this.props.noticeInformation.nowPageNumber
    );
	}
	// ページング用セクション
  showPageComponent() {
		let component = null;
		// お知らせを読み込んだ後のみ表示
    if (this.props.noticeInformation.totalRecordNumber !== null) {
      component = (
        <div>
          <div className="text-center">
            全{this.props.noticeInformation.totalRecordNumber}件中　
            {this.calcStartRecordNumber()}~{this.calcFinishRecordNumber()}件　を表示
						{/* セレクトボックスでページングする場合 */}
            {/* keyを指定しないと、DefaultValueが再描画されない */}
            {/* <select
              defaultValue={this.props.noticeInformation.nowPageNumber}
              className="form-control ml-2"
              onChange={event => this.getNoticeList(event.target.value)}
              key={this.props.noticeInformation.nowPageNumber}
              id="noticeSelectBox"
            >
              {this.pageCreater()}
            </select> */}
          </div>
          <div className="text-center mt-2">
            <button
              className="btn"
              onClick={() => this.getNoticeList(this.state.page_number - 1)}
              disabled={this.state.page_number <= 1}
            >
              前
            </button>
            <button className="btn" disabled={true}>
              {this.state.page_number}
            </button>
            <button
              className="btn"
              onClick={() => this.getNoticeList(this.state.page_number + 1)}
              disabled={this.state.page_number >= this.calcMaxPageNumber()}
            >
              次
            </button>
          </div>
        </div>
      );
    }
    return component;
  }

	// 現在は使用していないが、お知らせ本文を表で表示するコンポーネント
  showBodyComponent() {
    let component = null;
    if (this.state.noticeBody !== "") {
      component = (
        <table className="table table-bordered w-100 mt-3" id="noticeBody">
          <tbody>
            <tr>
              <th scope="row" className="custom-td-width">
                タイトル
              </th>
              <td className="">
                <strong>{this.state.noticeTitle}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" className="custom-td-width">
                日時
              </th>
              <td>{this.state.noticeDate}</td>
            </tr>
            <tr>
              <th scope="row" className="custom-td-width">
                本文
              </th>
              <td>{this.newLineCreater(this.state.noticeBody)}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    return component;
	}
	// お知らせ本文をモーダル上に表示するコンポーネント
  showBodyComponentModal() {
    let component = null;
    component = (
      <div className="">
        <div className="text-center h3">
          <strong className=" underline-blue">{this.state.noticeTitle}</strong>
        </div>
        <div className="text-right h5 mt-4">{this.state.noticeDate.slice(0, -3)}</div>
        <div className="" />
        <hr />
        <div className="h4 mt-2">{this.newLineCreater(this.state.noticeBody)}</div>
      </div>
    );
    return component;
	}
	// お知らせにNewを付けるか切り替える
	switchNewIcon(start_date_string){
		let new_icon = null
		// ブラウザの言語設定によっては、日本時間を取得しない場合がある
		let now_date = new Date()
		let start_date = new Date(start_date_string)
		let delta_mili_seconds = now_date - start_date
		let delta_date = delta_mili_seconds / 1000 / 60 / 60 / 24
		// 掲載開始日からNEW_NOTICE_DATE日以内ならNewを表示する
		if ( delta_date <= NEW_NOTICE_DATE ) {
			new_icon = <strong><i className="text-danger ml-2">New !!</i></strong>
		}
		return new_icon
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
    }
    return message;
  }

  render() {
    // お知らせリストを分解してテーブルのレコードを作成する
    const recordCreater = this.props.noticeInformation.noticeList.map((data, index) => {
      return (
        <tr key={index}>
          {/* slice(始端、終端)：0文字目から始め、末尾3文字を切り取る */}
          <td> {data.startPublishDate.slice(0, -3)} </td>
          <td>
            <a
              href="#noticeBody"
              className="text-primary"
              onClick={event =>
                this.setNoticeBody(event, data.startPublishDate, data.noticeTitle, data.noticeBody)
              }
            >
              {data.noticeTitle}
            </a>
						{this.switchNewIcon(data.startPublishDate)}
						{/* Newをアイコンで表示する場合 */}
            {/* <span className="fa-stack fa-lg fa-custom-position fa-custom-rotate">
              <i className="fas fa-circle fa-stack-2x text-warning" />
              <span className="fas fa-stack-1x text-white small">New</span>
            </span> */}

          </td>
        </tr>
      );
    });
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
            <div className="float-left mt-2">
              <i className="fas fa-bell mr-2" />
              お知らせ一覧
            </div>
            {/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
              onClick={() => this.refreshNoticeList()}
              data-tip="お知らせ一覧が更新されます。"
              data-html={true}
              data-for="refreshNoticeToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip id="refreshNoticeToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>
          <div className="card-body custom-scroll small">
            <table className="table table-bordered custom-table-width table-striped">
              <thead>
                <tr>
                  <th scope="col" className="custom-td-width-eb-day-long">
                    日時
                  </th>
                  <th scope="col" className="custom-td-width-message">
                    タイトル
                    <a
                      data-tip="タイトルのリンクをクリックすると、そのお知らせの本文が表示されます。"
                      data-html={true}
                      data-for="noticeTitleToolTip"
                    >
                      <i className="fas fa-question-circle text-primary ml-2" />
                      <ReactTooltip
                        id="noticeTitleToolTip"
                        effect="solid"
                        place="top"
                        html={true}
                      />
                    </a>
                  </th>
                  {/* <th scope="col" className="custom-td-width-eb-env-health">
                    お知らせ本文
                  </th> */}
                </tr>
              </thead>
              <tbody>{recordCreater}</tbody>
            </table>

            {/* ページング用セクション */}
            {this.showPageComponent()}

          </div>
        </div>

        {/* モーダル：お知らせ本文 */}
        {showModal({
          modalId: "noticeBodyModal",
          modalTitle: "",
          modalBody: this.showBodyComponentModal(),
          executeButtonLabel: "OK",
          executeFunctionObject: this.emptyReturn,
          showCancelButtonFlag: false
        })}
        {/* error */}
        {showModal({
          modalId: "noticeFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: "エラーが発生しました。",
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
        {/* エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
