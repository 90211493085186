// ライブラリ　インポート
import React, { Component } from "react";
import { Auth } from "aws-amplify";

// 共通処理　インポート
import { showLoadingSpinner } from "./common/loadingSpinner";
// 表示するコンポーネントを切り替える関数
import { switchComponent } from "./common/switchComponent";
import { toggleButton } from "./common/toggleButton";
// import NavbarCSS from "./common/NavbarCSS";
import NavbarCSS from "./common/NavbarSimple";
import Footer from "./common/Footer";

// import W3SideBar from "./common/W3SideBar";
import W3SideBar from "./common/W3SideBarSimple";

import $ from "jquery";
import { showModal } from "./common/showModal";

// ### ローディングトーストテスト
// import '../css/loadingToast_test.css'
import {showLoadingToast} from './common/loadingToast'

// これをImportしたい場合は、ファイル名で可能か？？
class component extends Component {
  // コンストラクタ－（おまじない）
  constructor(props) {
    super(props);
    // 変数群
    this.state = {
      // どのようにLoadingを更新するか？→Promise（コールバック）でコードの実行完了を待つ
      // loading: false,
      // ローディングは複数の処理が重なることを考慮して、数値で1以上ならローディング中という判定とする
      loading: 0,
      login_user_name: "",
      is_sign_out: false,

      // サイドバーが開いているかどうかを判定
      is_showing_sidebar: true,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
			error_function: null,
			
			// Topページから環境詳細を無理やり表示するため
			env_name: "",
			rds_name: "",
			env_endpoint: "",
			app_type: "環境",

			// Gitクレデンシャル画面を無理やり呼び出すため
			bastion_name: "",
    };
    // 子コンポーネントから以下の関数を呼んだ際に、「this」が指す先をここのコンポーネントに固定する
    this.startLoading = this.startLoading.bind(this);
    this.finishLoading = this.finishLoading.bind(this);
    this.setLoginUserName = this.setLoginUserName.bind(this);
    // this.signOutFunction = this.signOutFunction.bind(this)
    // this.handlerSignOut = this.handlerSignOut.bind(this)
    this.setModalMessage = this.setModalMessage.bind(this);
		this.handleToggleButton = this.handleToggleButton.bind(this);
		
		this.showEnvDetail = this.showEnvDetail.bind(this)
		this.showBastionGitCredential = this.showBastionGitCredential.bind(this)
  }

  // XXLoading：ローディングの状態を開始と終了に変更する関数（Stateを変更するのみ）
  // 子コンポーネントにこの関数を渡し、Promiseで実行する（※あくまでも更新するLoadingは親コンポーネントしか持っていない）
  async startLoading() {
    await this.emptyReturn();
    // setStateの前に何かしらawaitが存在しないと、意図した動作をしない（ローディングが止まってしまう）
		await this.setState({ loading: this.state.loading + 1 });
		// await console.log(this.state.loading)
  }
  async finishLoading() {
    await this.emptyReturn();
    // setStateの前に何かしらawaitが存在しないと、意図した動作をしない（ローディングが止まってしまう）
		await this.setState({ loading: Math.max(0, this.state.loading - 1) });
		// await console.log(this.state.loading)
  }
  // ログインユーザー名をセットする関数
  setLoginUserName(userName) {
    this.setState({ login_user_name: userName });
  }
  // サインアウトする関数
  async signOutFunction() {
    try {
      // サインアウトフラグを立てる
      await this.setState({ is_sign_out: true });
			// サインアウトしてもストアの中身はリセットされない→同じブラウザで別アカウントでログインしても、前のストアの中身が見れてしまう
			// RootReducerでStateをUndefinedにする関数
      // await this.props.resetAllStore()
      await this.storeReset();
      await this.props.showTop();
      // サインアウト実行
      await Auth.signOut();
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "サインアウト",
        error_message: "お手数ですが時間を置くか、ブラウザを更新して再度お試しください。"
      });
      $("#generalFailure").modal();
    }
	}
	// 全てのStoreの中身をリセットする（初期値に戻す）
  async storeReset() {
    try {
      // 契約情報
      await this.props.clearAccountInformation();
      await this.props.resetLoadingFlag();
      await this.props.resetTopAccountLoadingFlag();
      // ユーザー管理
      await this.props.clearUsers();
      await this.props.resetUserLoadingFlag();
      await this.props.clearTopUsers();
      await this.props.resetTopUserLoadingFlag();
      // 環境情報
      await this.props.clearEbEnvList();
      await this.props.resetListLoadingFlag();
      await this.props.clearTopEbEnvList();
      await this.props.resetTopListLoadingFlag();
      // お知らせ
      await this.props.clearNoticeList();
      await this.props.resetNoticeLoadingFlag();
      await this.props.clearTopNoticeList();
      await this.props.resetTopNoticeLoadingFlag();
      // await console.log("All Store Reset in SwitchComponent")
    } catch (error) {
      throw error;
    }
	}
	// 環境詳細を無理やり呼び出す
	async showEnvDetail(env_name, rds_name, env_endpoint, app_type){
		await this.setState({
			env_name: env_name,
			rds_name: rds_name,
			env_endpoint: env_endpoint,
			app_type: app_type
		})
		await this.props.showEbEnvDetail()
	}

	// Git Credentialを無理やり呼び出す
	async showBastionGitCredential(bastion_name){
		await this.setState({
			bastion_name: bastion_name,
		})
		await this.props.showGitCredential()
	}

  // showComponentの値によって表示するコンポーネントを切り替える関数
  handleSwitchComponent() {
    // console.log(this.props);
    return switchComponent({
      currentSwitchType: this.props.switchType,
      startLoadingFuncObject: this.startLoading,
      finishLoadingFuncObject: this.finishLoading,
      setLoginUserNameFuncObject: this.setLoginUserName,
      loginUserName: this.state.login_user_name,
			is_sign_out: this.state.is_sign_out,
			// 環境詳細を無理やり呼び出すため
			env_name: this.state.env_name,
			rds_name: this.state.rds_name,
			env_endpoint: this.state.env_endpoint,
			app_type: this.state.app_type,
			showEnvDetailFuncObject: this.showEnvDetail,
			showEnvListFuncObject: this.props.showEbEnvList,

			bastion_name: this.state.bastion_name,
			showClientEnvListFuncObject: this.props.showClientEnvList,
			showGitCredentialFuncObject: this.showBastionGitCredential,

			// ローディングフラグを配下のコンポーネントすべてに渡す用
			is_root_loading: this.state.loading >= 1,
    });
  }

	// サイドバーを開閉する関数
  handleToggleButton() {
    this.setState({ is_showing_sidebar: !this.state.is_showing_sidebar });
    toggleButton();
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
    }
    return message;
  }

  render() {
    return (
      <div className="rootWrapper">
        {/* ################################### */}
        <div className="content mybgcolor-grey">
          <div className="test-height">
						{/* ヘッダー */}
            <NavbarCSS
              login_user_name={this.state.login_user_name}
              signOutFunction={() => this.signOutFunction()}
              showS3Bucket={() => this.showS3Bucket()}
              showRdsTable={() => this.showRdsTable()}
              showTopScreen={() => this.props.showTop()}
              toggleButton={() => this.handleToggleButton()}
            />

						{/* サイドバー */}
            <W3SideBar
              login_user_name={this.state.login_user_name}
              current_switch_type={this.props.switchType}
              signOutFunction={() => this.signOutFunction()}
              showS3Bucket={() => this.props.showS3()}
              showRdsTable={() => this.props.showRds()}
              showTopScreen={() => this.props.showTop()}
              showAccount={() => this.props.showAccount()}
              showAccessKey={() => this.props.showAccessKey()}
              showEbEnvList={() => this.props.showEbEnvList()}
              showEbEnvDetail={() => this.props.showEbEnvDetail()}
              showAppliDeploy={() => this.props.showAppliDeploy()}
              showAppliVersion={() => this.props.showAppliVersion()}
              showUserList={() => this.props.showUserList()}
              showNotice={() => this.props.showNotice()}
              showClientEnvList={() => this.props.showClientEnvList()}
							is_showing_sidebar={this.state.is_showing_sidebar}
							toggleButton={() => this.handleToggleButton()}
						/>

						{/* メインのコンポーネント */}
            <div id="mainWrapper" className="showSidebarMain">
              <div id="main" className="">
                <div id="my-overlay" className="showSidebar">
                  {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
									{/* {showLoadingSpinner(this.state.loading >= 1)} */}
									
									{/* ### ローディングトーストテスト */}
									{showLoadingToast(this.state.loading >= 1)}

                  {/* ここにメインコンテンツを入れると、直接ローディングスピナーを置ける？ */}
                  {/* {this.handleSwitchComponent()} */}
                </div>

                {/* main */}
                {this.handleSwitchComponent()}
              </div>
							{/* Footer */}
							<Footer />
            </div>
						
          </div>
					
        </div>
				
        {/* ################################### */}
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle" />
              　エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
export default component;
