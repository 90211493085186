// 定数群を宣言
export const VAR_TYPE = {
  // NOTICE一覧のセット
  SET_TOP_NOTICE_LIST: "SET_TOP_NOTICE_LIST",
  CLEAR_TOP_NOTICE_LIST: "RESET_TOP_NOTICE_LIST",
  // ローディングフラグのセット
  SET_TOP_NOTICE_LOADING_FLAG: "SET_TOP_NOTICE_LOADING_FLAG",
	RESET_TOP_NOTICE_LOADING_FLAG: "RESET_TOP_NOTICE_LOADING_FLAG",
	
	// EB環境一覧のセット
  SET_TOP_EB_ENV_LIST: "SET_TOP_EB_ENV_LIST",
  CLEAR_TOP_EB_ENV_LIST: "RESET_TOP_EB_ENV_LIST",
  // ローディングフラグのセット
  SET_TOP_LIST_LOADING_FLAG: "SET_TOP_LIST_LOADING_FLAG",
	RESET_TOP_LIST_LOADING_FLAG: "RESET_TOP_LIST_LOADING_FLAG",
	
	SET_TOP_USERS: "SET_TOP_USERS",
	UPDATE_TOP_USER: "UPDATE_TOP_USER",
	DELETE_TOP_USER: "DELETE_TOP_USER",
	ADD_TOP_USER: "ADD_TOP_USER",
	CLEAR_TOP_USERS: "CLEAR_TOP_USERS",
	SET_TOP_USER_LOADING_FLAG: "SET_TOP_USER_LOADING_FLAG",
	RESET_TOP_USER_LOADING_FLAG: "RESET_TOP_USER_LOADING_FLAG",

	SET_TOP_ACCOUNT_LOADING_FLAG: "SET_TOP_ACCOUNT_LOADING_FLAG",
	RESET_TOP_ACCOUNT_LOADING_FLAG: "RESET_TOP_ACCOUNT_LOADING_FLAG",
};

// #########################   EB LIST
export const setTopNoticeList = (response,nowPageNumber) => {
  return {
    type: VAR_TYPE.SET_TOP_NOTICE_LIST,
    payload: {response,nowPageNumber}
  };
};
export const clearTopNoticeList = () => {
  return {
    type: VAR_TYPE.CLEAR_TOP_NOTICE_LIST,
    payload: {}
  };
};
export const setTopNoticeLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_TOP_NOTICE_LOADING_FLAG,
    payload: {}
  };
};
export const resetTopNoticeLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_TOP_NOTICE_LOADING_FLAG,
    payload: {}
  };
};

// 
// #########################   EB LIST
export const setTopEbEnvList = envList => {
  return {
    type: VAR_TYPE.SET_TOP_EB_ENV_LIST,
    payload: {envList}
  };
};
export const clearTopEbEnvList = () => {
  return {
    type: VAR_TYPE.CLEAR_TOP_EB_ENV_LIST,
    payload: {}
  };
};
export const setTopListLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_TOP_LIST_LOADING_FLAG,
    payload: {}
  };
};
export const resetTopListLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_TOP_LIST_LOADING_FLAG,
    payload: {}
  };
};

// 
export const setTopUsers = userList => {
  return {
    type: VAR_TYPE.SET_TOP_USERS,
    payload: { userList }
  };
};

// 更新するユーザーが何番目かと、変更する値を取得する
export const updateTopUser = (index, email) => {
  return {
    type: VAR_TYPE.UPDATE_TOP_USER,
    payload: { index, email }
  };
};

// 削除するユーザーが何番目かを取得する
export const deleteTopUser = index => {
  return {
    type: VAR_TYPE.DELETE_TOP_USER,
    payload: { index }
  };
};

// 1人のユーザー情報を取得する
export const addTopUser = user => {
  return {
    type: VAR_TYPE.ADD_TOP_USER,
    payload: { user }
  };
};

export const clearTopUsers = () => {
  return {
    type: VAR_TYPE.CLEAR_TOP_USERS,
    payload: {}
  };
};
export const setTopUserLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_TOP_USER_LOADING_FLAG,
    payload: {}
  };
};
export const resetTopUserLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_TOP_USER_LOADING_FLAG,
    payload: {}
  };
};

// ACCOUNT
export const setTopAccountLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_TOP_ACCOUNT_LOADING_FLAG,
    payload: {}
  };
};
export const resetTopAccountLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_TOP_ACCOUNT_LOADING_FLAG,
    payload: {}
  };
};

export const actions = {
  setTopNoticeList: setTopNoticeList,
	clearTopNoticeList: clearTopNoticeList,
	setTopNoticeLoadingFlag: setTopNoticeLoadingFlag,
	resetTopNoticeLoadingFlag: resetTopNoticeLoadingFlag,
	
	setTopEbEnvList: setTopEbEnvList,
	clearTopEbEnvList: clearTopEbEnvList,
	setTopListLoadingFlag: setTopListLoadingFlag,
	resetTopListLoadingFlag: resetTopListLoadingFlag,
	
	setTopUsers: setTopUsers,
  updateTopUser: updateTopUser,
  deleteTopUser: deleteTopUser,
  addTopUser: addTopUser,
	clearTopUsers: clearTopUsers,
	setTopUserLoadingFlag: setTopUserLoadingFlag,
	resetTopUserLoadingFlag: resetTopUserLoadingFlag,

	setTopAccountLoadingFlag: setTopAccountLoadingFlag,
	resetTopAccountLoadingFlag: resetTopAccountLoadingFlag,
};
