import { connect } from "react-redux";

import AccountSection from "../components/AccountSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    accountInformation:{
			accountId: state.accountSection.accountId,
			customerName: state.accountSection.customerName,
			customerMailAddress: state.accountSection.customerMailAddress,
			loadingFlag: state.accountSection.loadingFlag,
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// accountInfomation: JSON
		setAccountInformation(accountInformation){
			dispatch(actionCreater.accountSection.setAccountInformation(accountInformation))
		},
		// accountInfomation: JSON
		clearAccountInformation(){
			dispatch(actionCreater.accountSection.clearAccountInformation())
		},
		// 
		setLoadingFlag(){
			dispatch(actionCreater.accountSection.setLoadingFlag())
		},
		// 
		resetLoadingFlag(){
			dispatch(actionCreater.accountSection.resetLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSection);
