import { connect } from "react-redux";

import SwitchComponent from "../components/SwitchComponent";
import { actionCreater } from "../reducers/root";

function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
		switchType: state.switchComponent.switchType,
		// accountInfomation: state.accountSection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
		resetAllStore(){
			dispatch( actionCreater.switchComponent.resetAllStore() )
		},
		// =====<Clear Store：開始>==============================================================
		// 何とかここから契約情報をリセットできないか？→出来ている。一つのコンポーネントに複数のActionを渡したい場合はここに列挙する
		clearAccountInformation(){
			dispatch(actionCreater.accountSection.clearAccountInformation())
		},
		resetLoadingFlag(){
			dispatch(actionCreater.accountSection.resetLoadingFlag())
		},
		// user section : loading flagは無い
		clearUsers(){
			dispatch(actionCreater.userListSection.clearUsers())
		},
		resetUserLoadingFlag(){
			dispatch(actionCreater.userListSection.resetUserLoadingFlag())
		},
		// env list section 
		clearEbEnvList(){
			dispatch(actionCreater.ebEnvListSection.clearEbEnvList())
		},
		resetListLoadingFlag(){
			dispatch(actionCreater.ebEnvListSection.resetListLoadingFlag())
		},
		// notice
		clearNoticeList(){
			dispatch(actionCreater.noticeSection.clearNoticeList())
		},
		resetNoticeLoadingFlag(){
			dispatch(actionCreater.noticeSection.resetNoticeLoadingFlag())
		},
		// TOP
		// TOP NOTICE
		clearTopNoticeList(){
			dispatch(actionCreater.topSection.clearTopNoticeList())
		},
		resetTopNoticeLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopNoticeLoadingFlag())
		},
		// TOP ENV
		clearTopEbEnvList(){
			dispatch(actionCreater.topSection.clearTopEbEnvList())
		},
		resetTopListLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopListLoadingFlag())
		},
		// TOP USER
		clearTopUsers(){
			dispatch(actionCreater.topSection.clearTopUsers())
		},
		resetTopUserLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopUserLoadingFlag())
		},
		// TOP ACCOUNT
		resetTopAccountLoadingFlag(){
			dispatch(actionCreater.topSection.resetTopAccountLoadingFlag())
		},
		// 本当は各ローディングフラグもリセットすべきだが、データさえ消せば基本問題ないので、ひとまず放置
		// =====<Clear Store：終了>==============================================================

		// =====<各ページのサマリー表示用：開始>==============================================================
		
		// =====<各ページのサマリー表示用：終了>==============================================================


    //　トップ
    showTop() {
      dispatch(actionCreater.switchComponent.showTop());
    },
    //　契約情報
    showAccount() {
      dispatch(actionCreater.switchComponent.showAccount());
    },
    //　アクセスキー
    showAccessKey() {
      dispatch(actionCreater.switchComponent.showAccessKey());
    },
    //　環境一覧
    showEbEnvList() {
      dispatch(actionCreater.switchComponent.showEbEnvList());
    },
    //　環境詳細
    showEbEnvDetail() {
      dispatch(actionCreater.switchComponent.showEbEnvDetail());
    },
    //　手動デプロイ
    showAppliDeploy() {
      dispatch(actionCreater.switchComponent.showAppliDeploy());
    },
    //　バージョン管理
    // showAppliVersion() {
    //   dispatch(actionCreater.switchComponent.showAppliVersion());
		// },
		//　ユーザー管理
    showUserList() {
      dispatch(actionCreater.switchComponent.showUserList());
		},
		//　お知らせ
    showNotice() {
      dispatch(actionCreater.switchComponent.showNotice());
		},
    //　開発環境
    showClientEnvList() {
			dispatch(actionCreater.switchComponent.showClientEnvList());
		},

		//　Git Credential
    showGitCredential() {
			dispatch(actionCreater.switchComponent.showGitCredential());
		},
		
    showRds() {
      dispatch(actionCreater.switchComponent.showRds());
    },
    showS3() {
      dispatch(actionCreater.switchComponent.showS3());
    }
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchComponent);
