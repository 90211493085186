import { VAR_TYPE, actions } from "../actions/AccountSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	accountId : "",
	customerName : "",
	customerMailAddress : "",
	loadingFlag: false,
};

// Storeを更新するための関数
export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
		// 全てのアカウント情報を更新する
    case VAR_TYPE.ACCOUNT_INFORMATION:
      return {
				...state,
				accountId: action.payload.accountInformation.accountId,
				customerName: action.payload.accountInformation.customerName,
				customerMailAddress: action.payload.accountInformation.customerMailAddress,
			};
		// 全てのアカウント情報をリセットする
		case VAR_TYPE.CLEAR_INFORMATION:
      return {
				...state,
				accountId: "",
				customerName: "",
				customerMailAddress: "",
			};
		// 
		case VAR_TYPE.SET_LOADING_FLAG:
      return {
				...state,
				loadingFlag: true
			};
		// 
		case VAR_TYPE.RESET_LOADING_FLAG:
      return {
				...state,
				loadingFlag: false
      };
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
