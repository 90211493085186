import React, { Component } from "react";
import { SWITCH_TYPE } from "../../actions/SwitchComponent";
import ReactTooltip from "react-tooltip";
import { showModal } from "./showModal";

class W3SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // 現在表示しているコンポーネントのボタンをActive化させるための関数群
  switchActiveTop() {
    let active = "";
    if (this.props.current_switch_type === SWITCH_TYPE.SHOW_TOP) {
      active = "active";
    }
    return active;
  }
  switchActiveEnv() {
		let active = "";
		// 現在、環境詳細を無理やり呼び出しているため、そこも判定させる
		if (this.props.current_switch_type === SWITCH_TYPE.SHOW_EB_ENV_LIST || 
			this.props.current_switch_type === SWITCH_TYPE.SHOW_EB_ENV_DETAIL) {
      active = "active";
    }
    return active;
  }
  showWorkspaceList() {
    let active = "";
    if (this.props.current_switch_type === SWITCH_TYPE.SHOW_WORKSPACE_LIST) {
      active = "active";
    }
    return active;
  }
  showClientEnvList() {
		let active = "";
		// 現在、Gitクレデンシャルを無理やり呼び出しているため、そこも判定させる
		if (this.props.current_switch_type === SWITCH_TYPE.SHOW_CLIENT_ENV_LIST ||
			this.props.current_switch_type === SWITCH_TYPE.SHOW_GIT_CREDENTIAL) {
      active = "active";
    }
    return active;
  }
  switchActiveAccount() {
    let active = "";
    if (this.props.current_switch_type === SWITCH_TYPE.SHOW_ACCOUNT) {
      active = "active";
    }
    return active;
  }
  switchActiveUser() {
    let active = "";
    if (this.props.current_switch_type === SWITCH_TYPE.SHOW_USER_LIST) {
      active = "active";
    }
    return active;
  }
  switchActiveNotice() {
    let active = "";
    if (this.props.current_switch_type === SWITCH_TYPE.SHOW_NOTICE) {
      active = "active";
    }
    return active;
  }

  // サイドバーを細くする場合に文字を非表示にする関数群
  showTopTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = <div className="col-sm-9">トップページ</div>;
    }
    return string;
  }
  showEnvTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = (
				<div className="col-sm-9">
					実行環境一覧
				</div>
			)
    }
    return string;
  }
  showWorkspacesTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = <div className="col-sm-9">開発環境一覧</div>;
    }
    return string;
  }
  showAccountTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = <div className="col-sm-9">お客様情報</div>;
    }
    return string;
  }
  showUserTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = <div className="col-sm-9">ユーザー管理</div>;
    }
    return string;
  }
  showNoticeTitle() {
    let string = null;
    if (this.props.is_showing_sidebar) {
      string = <div className="col-sm-9">お知らせ一覧</div>;
    }
    return string;
  }
  // サイドバーの開閉によってアイコンを切り替える
  switchToggleIcon() {
    let icon = <i className="fas fa-bars custom-margin-left" />;
    if (this.props.is_showing_sidebar) {
      icon = <i className="fas fa-times ml-1" />;
    }
    return icon;
  }

  render() {
    return (
      <div id="mySidebarWrapper" className="showMySidebar">
        <div className="w3-sidebar w3-bar-block w3-card w3-animate-left bg-light" id="mySidebar">
          {/* navbarの下に埋め込ませるための空の行。50px。top:0px;を指定して無理やり潜り込ませている */}
          <div className="w3-bar-item height-for-navbar" />

          {/* サイドバー開閉ボタン */}
          <button
            className={`w3-bar-item btn btn-light w3-border-top`}
            onClick={() => this.props.toggleButton()}
            data-tip="サイドバーを開閉することができます。"
            data-html={true}
            data-for="sidebarOpenToolTip"
          >
            <div className="row">
              <div className="col-sm-3">{this.switchToggleIcon()}</div>
            </div>
            <ReactTooltip id="sidebarOpenToolTip" effect="solid" place="right" html={true} />
          </button>

					{/* トップページ表示ボタン */}
          <button
            className={`w3-bar-item btn btn-light w3-border-top ${this.switchActiveTop()}`}
            onClick={() => this.props.showTopScreen()}
            data-tip="各ページのサマリーを表示するトップページです。"
            data-html={true}
            data-for="sidebarTopToolTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-home" />
              </div>
              {this.showTopTitle()}
            </div>
            <ReactTooltip id="sidebarTopToolTip" effect="solid" place="right" html={true} />
          </button>

					{/* 環境情報一覧表示ボタン */}
          <button
            className={`w3-bar-item btn btn-light ${this.switchActiveEnv()}`}
            onClick={() => this.props.showEbEnvList()}
            data-tip="お客様が契約されているアプリケーション・実行環境の情報を確認できます。<br/>また、ログの参照やバージョン管理などをご利用いただけます。"
            data-html={true}
            data-for="sidebarEnvToolTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-cubes" />
              </div>
              {this.showEnvTitle()}
            </div>
            <ReactTooltip id="sidebarEnvToolTip" effect="solid" place="right" html={true} />
          </button>

          <button
            className={`w3-bar-item btn btn-light ${this.showClientEnvList()}`}
            onClick={() => this.props.showClientEnvList()}
            data-tip="お客様が契約されている開発環境の情報を確認できます。"
            data-html={true}
            data-for="sidebarClientsEnvListTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-desktop" />
              </div>
              {this.showWorkspacesTitle()}
            </div>
            <ReactTooltip id="sidebarClientsEnvListTip" effect="solid" place="right" html={true} />
          </button>

					{/* 契約情報参照ボタン */}
          <button
            className={`w3-bar-item btn btn-light ${this.switchActiveAccount()}`}
            onClick={() => this.props.showAccount()}
            data-tip="お客様情報を確認できます。"
            data-html={true}
            data-for="sidebarAccountToolTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-file-signature" />
              </div>
              {this.showAccountTitle()}
            </div>
            <ReactTooltip id="sidebarAccountToolTip" effect="solid" place="right" html={true} />
          </button>

					{/* ユーザー一覧表示ボタン */}
          <button
            className={`w3-bar-item btn btn-light ${this.switchActiveUser()}`}
            onClick={() => this.props.showUserList()}
            data-tip="ユーザーポータル（本サイト）にサインイン出来るユーザーの参照・追加・変更・削除が出来ます。"
            data-html={true}
            data-for="sidebarUserToolTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-users-cog" />
              </div>
              {this.showUserTitle()}
            </div>
            <ReactTooltip id="sidebarUserToolTip" effect="solid" place="right" html={true} />
          </button>

					{/* お知らせ参照ボタン */}
          <button
            className={`w3-bar-item btn btn-light w3-border-bottom ${this.switchActiveNotice()}`}
            onClick={() => this.props.showNotice()}
            data-tip="メンテナンス情報など、お客様へのお知らせを確認できます。"
            data-html={true}
            data-for="sidebarNoticeToolTip"
          >
            <div className="row">
              <div className="col-sm-3">
                <i className="fas fa-bell" />
              </div>
              {this.showNoticeTitle()}
            </div>
            <ReactTooltip id="sidebarNoticeToolTip" effect="solid" place="right" html={true} />
          </button>
        </div>

				{/* サインアウト確認モーダル */}
        {showModal({
          modalId: "signOutSidebar",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary" />
              　確認
            </span>
          ),
          modalBody: "サインアウトしてもよろしいですか？",
          executeFunctionObject: this.props.signOutFunction
        })}
      </div>
    );
  }
}

export default W3SideBar;
