import { connect } from "react-redux";

import AccessKeySection from "../components/AccessKeySection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    // combineReducersを使った場合、stateと【変数名】の間に、コンポーネント名（Rootで命名）が挟まる
    ebInfomation:{
			envAccessKey: state.accessKeySection.envAccessKey,
			loadingAccessKeyFlag: state.accessKeySection.loadingAccessKeyFlag,
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// AccessKey
		// env
		setEbEnvAccessKey(envAccessKey){
			dispatch(actionCreater.accessKeySection.setEbEnvAccessKey(envAccessKey))
		},
		// 
		clearEbEnvAccessKey(){
			dispatch(actionCreater.accessKeySection.clearEbEnvAccessKey())
		},
		// 
		setAccessKeyLoadingFlag(){
			dispatch(actionCreater.accessKeySection.setAccessKeyLoadingFlag())
		},
		// 
		resetAccessKeyLoadingFlag(){
			dispatch(actionCreater.accessKeySection.resetAccessKeyLoadingFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessKeySection);
