import { VAR_TYPE, actions } from "../actions/AppliDeploySection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	envDeploy: {},
	loadingDeployFlag: false,
};

// Storeを更新するための関数
export const envReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  EB DEPLOY
		// 情報をセット
		case VAR_TYPE.SET_EB_ENV_DEPLOY:
			let tmpEnvDeploy = state.envDeploy
			console.log(action.payload.envDeploy)
			tmpEnvDeploy[action.payload.envDeploy.envName] = action.payload.envDeploy
			tmpEnvDeploy[action.payload.envDeploy.versionLabel] = action.payload.envDeploy
			tmpEnvDeploy[action.payload.envDeploy.appName] = action.payload.envDeploy
      return {
				...state,
				envDeploy: tmpEnvDeploy,
			};
		// リセット
		case VAR_TYPE.CLEAR_EB_ENV_DEPLOY:
      return {
				...state,
				envDeploy: {},
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_DEPLOY_LOADING_FLAG:
      return {
				...state,
				loadingDeployFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_DEPLOY_LOADING_FLAG:
      return {
				...state,
				loadingDeployFlag: false
			};
		
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
