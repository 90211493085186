import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { CONST } from "../environments/environment";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import {
  // パスワード用バリデーション
  checkPasswordLength,
  checkPasswordContainsSpecialSymbol,
  checkPasswordContainsNumber,
  checkPasswordContainsLowerCase,
  checkPasswordContainsUpperCase,
  checkPasswordContainsSafeChar,

  // ユーザー名用バリデーション
  checkUserNameLength,
  checkUserNameContainsSafeChar,

  // メールアドレス用バリデーション
  checkMailAddressLength,
  checkMailAddressContainsSafeChar
} from "./common/cognitoInputValidation";

import {executeGetUserList} from './common/handlerUser'

export default class UserListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // updateに関する変数は一時的にしか利用しないため、ストア化しなくてよい
      update_id: null,
      update_user_name: null,
      update_email: null,
      update_pre_emial: null,
      // ユーザー追加用一時変数
      add_user_name: "",
      add_email: "",
      add_password: "",
      // ユーザー削除用一時変数
      delete_id: null,
      delete_user_name: null,
      delete_email: null,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
      error_function: null
    };
    this.updateUser = this.updateUser.bind(this);
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
		this.resetAddUser = this.resetAddUser.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
  }
  componentWillMount() {
		// console.log(this.props);
		// ユーザーリストが空かつユーザー読み込み済みではない場合だけ実行
    if (this.props.userList.length === 0 && !this.props.loadingUserFlag) {
      this.getUserList();
    }
  }
  // 【機能１】ユーザー一覧取得
  async getUserList() {
    try {
      await this.props.startLoading();
      await this.props.setUserLoadingFlag();
			let response = await executeGetUserList()
      // console.log(response.users);
      await this.props.setUsers(response.users);
    } catch (error) {
			await this.props.resetUserLoadingFlag();
			// エラーモーダルを表示する
      await this.setState({
        error_function: "ユーザー一覧取得",
        error_message: "お手数ですが時間を置いて、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
			// ここにawaitを書くと、モーダルが複数重ならなくなる
      await this.props.finishLoading();
    }
  }

  // 【機能２】ユーザーの属性（Email）を更新する
  async updateUser() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.USER;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          // propsと indexを利用してアクセスする
          userName: this.state.update_user_name,
          // state
          email: this.state.update_email
        }
      };
      let response = await API.put(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // await this.props.updateUser(index, update_email);
      $("#updateSuccess").modal();
      await this.resetUpdateId();
      await this.getUserList();
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "メールアドレス更新",
        error_message: "お手数ですが入力内容を見直し、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

  // 削除用モーダル表示用
  async deleteCommit(index) {
    await this.setState({
      delete_id: index,
      delete_user_name: this.props.userList[index].userName,
      delete_email: this.props.userList[index].email
    });
    // await this.deleteUser();
    await $("#deleteConfirm").modal();
  }
  // 【機能３】ユーザーを削除する
  async deleteUser() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.USER;
      let myInit = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          // propsと indexを利用してアクセスする
          userName: this.props.userList[this.state.delete_id].userName
        }
      };
      let response = await API.del(api_name, path, myInit);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      $("#deleteSuccess").modal();
      // ログインユーザーを削除した場合、getUserが失敗するようになり、削除成功のモーダルの上にエラー発生モーダルが重なる
      await this.getUserList();
      // await this.props.deleteUser(this.state.delete_id);
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "ユーザー削除",
        error_message: "お手数ですがユーザー一覧を更新して、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
  }

	// ユーザー追加セクションのStateを更新する関数群
  handleChangeAddUserName(event) {
    this.setState({ add_user_name: event.target.value });
  }
  handleChangeAddEmail(event) {
    this.setState({ add_email: event.target.value });
  }
  handleChangeAddPassword(event) {
    this.setState({ add_password: event.target.value });
  }
  // 【機能４】ユーザーを追加する
  async addUser() {
    try {
      await this.props.startLoading();
      let session = await Auth.currentSession();
      let api_name = CONST.API_NAME;
      let path = CONST.API_PATH.USER;
      let my_init = {
        headers: {
          Authorization: session.getIdToken().getJwtToken()
        },
        body: {
          accessToken: session.getAccessToken().getJwtToken(),
          userName: this.state.add_user_name,
          email: this.state.add_email,
          password: this.state.add_password
        }
      };
      // POSTメソッドでは、APIGWにマッピングテンプレートを設定しなくてよい
      let response = await API.post(api_name, path, my_init);
      // console.log(response);
      if ("errorMessage" in response) {
        throw response;
      }
      // await this.props.addUser({ userName: this.state.add_user_name, email: this.state.add_email });
      $("#addSuccess").modal();
      // 開いたユーザー追加セクションを閉じる
      await $("#collapseInput").collapse("toggle");
      await this.getUserList();
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "ユーザー追加",
        error_message: "お手数ですが入力内容を見直し、再度お試しください。"
			});
			// Lambdaで処理したエラーの場合は、そのメッセージをセットする
      if ("errorMessage" in error) {
        await this.setState({
          error_message: error.errorMessage
        });
      }
      $("#generalFailure").modal();
    } finally {
      await this.props.finishLoading();
    }
	}
	// 入力ボックスの内容を初期化する
  resetAddUser() {
    this.setState({
      add_user_name: "",
      add_email: "",
      add_password: ""
    });
	}
	// ユーザー追加セクション表示用コンポーネント
  addComponent() {
    return (
      <div className="collapse" id="collapseInput">
        <table className="table table-bordered w-100 tb-2">
          <thead>
            <tr>
              <th scope="col">ユーザー名</th>
              <th scope="col">メールアドレス</th>
              <th scope="col" className="custom-td-width-pass">
                パスワード
              </th>
              <th className=" custom-td-width-add"> 追加 </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type={CONST.INPUT_TYPE.TEXT}
                  value={this.state.add_user_name}
                  className={CONST.CLASS.INPUT_BOX}
                  onChange={event => this.handleChangeAddUserName(event)}
                  autoComplete="new-password"
                />
              </td>
              <td>
                <input
                  type={CONST.INPUT_TYPE.EMAIL}
                  value={this.state.add_email}
                  className={CONST.CLASS.INPUT_BOX}
                  onChange={event => this.handleChangeAddEmail(event)}
                  autoComplete="new-password"
                />
              </td>
              <td>
                <input
                  type={CONST.INPUT_TYPE.PASSWORD}
                  value={this.state.add_password}
                  className={CONST.CLASS.INPUT_BOX}
                  onChange={event => this.handleChangeAddPassword(event)}
                  autoComplete="new-password"
                />
              </td>
              <td>
                <button
                  onClick={() => $("#addConfirm").modal()}
                  className="btn btn-primary btn-sm"
                  disabled={this.props.userList.length >= 10 || !this.allValidationFlag()}
                >
                  {/* 追加 */}
                  <i className="fas fa-user-plus" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
				{/* 入力規則チェック用セクション */}
        {this.showValidationComponent()}
        <hr />
      </div>
    );
	}
	// ユーザー名の全バリデーション結果を返す関数
  userNameAllValidationFlag() {
    return (
      checkUserNameLength(this.state.add_user_name) &&
      checkUserNameContainsSafeChar(this.state.add_user_name)
    );
	}
	// メールアドレスの全バリデーション結果を返す関数
  emailAllValidationFlag() {
    return (
      checkMailAddressLength(this.state.add_email) &&
      checkMailAddressContainsSafeChar(this.state.add_email)
    );
	}
	emailArgAllValidationFlag(email_string) {
    return (
      checkMailAddressLength(email_string) &&
      checkMailAddressContainsSafeChar(email_string)
    );
	}
	// パスワードの全バリデーション結果を返す関数
  passwordAllValidationFlag() {
    return (
      checkPasswordLength(this.state.add_password) &&
      checkPasswordContainsLowerCase(this.state.add_password) &&
      checkPasswordContainsUpperCase(this.state.add_password) &&
      checkPasswordContainsNumber(this.state.add_password) &&
      checkPasswordContainsSpecialSymbol(this.state.add_password) &&
      checkPasswordContainsSafeChar(this.state.add_password)
    );
	}
	// 全ての入力項目の全バリデーション結果を返す関数
  allValidationFlag() {
    return (
      this.userNameAllValidationFlag() &&
      this.emailAllValidationFlag() &&
      this.passwordAllValidationFlag()
    );
	}
	// バリデーション結果によってアイコンを切り替える
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	// 入力規則チェック用セクション
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th scope="row" rowSpan="2" className="custom-td-width">
                {this.switchValidationIcon(this.userNameAllValidationFlag())}
                ユーザー名
              </th>
              <td>
                {this.switchValidationIcon(checkUserNameLength(this.state.add_user_name))}
                長さは1文字以上、128文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkUserNameContainsSafeChar(this.state.add_user_name))}
                半角アルファベットの小文字・大文字、数字、「-」、「_」以外の文字はご使用いただけません。
              </td>
            </tr>
            <tr>
              <th scope="row" rowSpan="2">
                {this.switchValidationIcon(this.emailAllValidationFlag())}
                メールアドレス
              </th>
              <td>
                {this.switchValidationIcon(checkMailAddressLength(this.state.add_email))}
                長さはホスト部が1文字以上、ドメイン部が1文字以上63文字以下、全体で320文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkMailAddressContainsSafeChar(this.state.add_email))}
                正しい形式のメールアドレスを入力してください。
              </td>
            </tr>
            <tr>
              <th scope="row" rowSpan="6">
                {this.switchValidationIcon(this.passwordAllValidationFlag())}パスワード
              </th>
              <td>
                {this.switchValidationIcon(checkPasswordLength(this.state.add_password))}
                長さは8文字以上、256文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsLowerCase(this.state.add_password))}
                小文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsUpperCase(this.state.add_password))}
                大文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsNumber(this.state.add_password))}
                数字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(
                  checkPasswordContainsSpecialSymbol(this.state.add_password)
                )}
                次の特殊文字が1文字以上必要です。　特殊文字：　
                {"^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"}
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsSafeChar(this.state.add_password))}
                半角アルファベットの小文字・大文字、数字、上記特殊文字、「+」、「=」以外の文字はご使用いただけません。
              </td>
            </tr>
          </tbody>
        </table>
        ※一部ご登録いただけないユーザー名がございます。あらかじめご了承ください。
      </div>
    );
  }

  // 更新する行をリセットする
  resetUpdateId() {
    this.setState({ update_id: null });
  }
  // 更新する行とデータをStateにセットする
  setUpdateId(index) {
    let user_name = this.props.userList[index].userName;
    let email = this.props.userList[index].email;
    this.setState({
      update_id: index,
      update_user_name: user_name,
      update_email: email,
      update_pre_emial: email
    });
  }
  // 更新用入力ボックスを制御する
  handleChangeEmail(event) {
    this.setState({ update_email: event.target.value });
  }

  // 更新する行としない行とで表示するコンポーネントを切り替える
  switchUpdate(index) {
    let component = null;
    if (this.state.update_id === index) {
      // 編集ボタンを押した行
      component = this.updateComponent(index);
    } else {
      // 通常のEmail
      component = this.notUpdateComponent(index);
    }
    return component;
  }
  // 更新する行は編集用のコンポーネントを表示する
  updateComponent(index) {
    return (
      <React.Fragment>
        <td>
					{/* Eメールを変更する入力ボックスでも、Eメールのバリデーションを実施すべき */}
          <input
            type={CONST.INPUT_TYPE.EMAIL}
            value={this.state.update_email}
            className={CONST.CLASS.INPUT_BOX}
            onChange={event => this.handleChangeEmail(event)}
          />
        </td>
        {/* <td> {this.checkEmailStatus(this.props.userList[index].emailStatus)} </td> */}
        <td>
          {/* 編集ボタンを押した際、ボタングループの指定が全く同じだと、クリック時のCSSがなぜか切り替え後のボタンに適用されてしまう */}
          <div className="btn-toolbar">
            <div className="btn-group" role="group">
              <button
                className={`btn btn-success btn-sm`}
								onClick={() => $("#updateConfirm").modal()}
								// 更新前後で値が変わっていなければ無効化する。バリデーション結果でも無効化する
                disabled={
									this.props.userList[index].email === this.state.update_email 
									|| !this.emailArgAllValidationFlag(this.state.update_email)
								}
              >
                {/* 更新コミット */}
                <i className="fas fa-check" />
              </button>
              <button
                className={`btn btn-light btn-sm`}
								onClick={() => this.resetUpdateId(index)}
              >
                {/* キャンセル */}
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
        </td>
      </React.Fragment>
    );
  }
  // 更新しない行は通常のコンポーネントを表示する
  notUpdateComponent(index) {
    return (
      <React.Fragment>
        <td>
          {this.checkEmailStatus(this.props.userList[index].emailStatus)}
          {this.props.userList[index].email}
        </td>
        <td>
          <div className="btn-group" role="group">
            <button
              className={`btn btn-success btn-sm`}
              onClick={() => this.setUpdateId(index)}
            >
              {/* 編集 */}
              <i className="fas fa-pen" />
            </button>
            <button
              className={`btn btn-danger btn-sm`}
							onClick={() => this.deleteCommit(index)}
							// 最後のユーザー　もしくは　ログインユーザーの削除ボタンを無効化する
              disabled={
                this.props.userList.length === 1 ||
                this.props.userList[index].userName === this.props.login_user_name
              }
            >
              {/* 削除 */}
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        </td>
      </React.Fragment>
    );
	}
	// メールアドレスの検証ステータスによってアイコンを切り替える
  checkEmailStatus(status) {
    let component = null;
    // 大文字と小文字が混在しているため、小文字に変換して比較する（混在していたのは追加時の指定がおかしかっただけか？）
    if (status.toLowerCase() == "true") {
      // 検証が出来ている
      component = (
        <a
          data-tip="このメールアドレスは有効です。<br />サインイン画面より、パスワードリセットの機能をご利用いただけます。"
          data-html={true}
          data-for="successToolTip"
        >
          <i className="fas fa-check-circle text-success mr-2" />
          <ReactTooltip id="successToolTip" effect="solid" place="top" html={true} />
        </a>
      );
    } else {
      // 検証が出来ていない。（もしくは値が取れていない？）
      component = (
        <a
          data-tip="このメールアドレスは有効かどうか検証されていません。<br />サインイン時にメールアドレスの有効化を行うことで有効になります。"
          data-html={true}
          data-for="failureToolTip"
        >
          <i className="fas fa-exclamation-circle text-warning mr-2" />
          <ReactTooltip id="failureToolTip" effect="solid" place="top" html={true} />
        </a>
      );
    }
    return component;
	}
	// 追加ユーザーの情報を、メッセージと共に表示するコンポーネント
  showAddUserInfoWithMessage(message) {
    let component = (
      <div>
        {message}
        <br />
        <br />
        ユーザー名　　：　{this.state.add_user_name}
        <br />
        メールアドレス：　{this.state.add_email}
        <br />
      </div>
    );
    return component;
	}
	// 削除ユーザーの情報を、メッセージと共に表示するコンポーネント
  showDeleteUserInfoWithMessage(message) {
    let component = (
      <div>
        {message}
        <br />
        <br />
        ユーザー名　　：　{this.state.delete_user_name}
        <br />
        メールアドレス：　{this.state.delete_email}
        <br />
      </div>
    );
    return component;
	}
	// 更新ユーザーの情報を、メッセージと共に表示するコンポーネント
  showUpdateUserInfoWithMessage(message) {
    let component = (
      <div>
        {message}
        <br />
        <br />
        ユーザー名　　　　　：　{this.state.update_user_name}
        <br />
        変更前メールアドレス：　{this.state.update_pre_emial}
        <br />
        変更後メールアドレス：　{this.state.update_email}
        <br />
      </div>
    );
    return component;
	}
	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージを表示する
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
		}
		return message
  }

  render() {
    // ユーザーリストを分解してテーブルのレコードを作成する
    const recordCreater = this.props.userList.map((data, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{data.userName} </td>
          {this.switchUpdate(index)}
        </tr>
      );
    });

    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card">
          <div className="card-header h5">
            <div className="float-left mt-2">
              <i className="fas fa-users-cog mr-2" />
              ユーザー管理
            </div>
            {/* 更新ボタン */}
            <button
              className={`btn btn-light float-right`}
              onClick={() => this.getUserList()}
              data-tip="ユーザーの一覧が更新されます。"
              data-html={true}
              data-for="refreshToolTip"
            >
              <i className="fas fa-sync-alt" />
              <ReactTooltip id="refreshToolTip" effect="solid" place="left" html={true} />
            </button>
						{/* ユーザー追加ボタン */}
            <button
              type="button"
              className="btn btn-light float-right"
              data-toggle="collapse"
              data-target="#collapseInput"
              aria-expanded="false"
              aria-controls="collapseInput"
              data-tip="ユーザーを追加するための入力欄が表示されます。"
              data-html={true}
              data-for="addToolTip"
            >
              <i className="fas fa-user-plus mr-2" />
              ユーザー追加
              <i className="ml-2 fas fa-angle-down" id="addCollapse" />
              {/* ReactTooltipのidとdata-forを一致させないと、何故かツールチップが複数表示される */}
              <ReactTooltip id="addToolTip" effect="solid" place="left" html={true} />
            </button>
          </div>
          <div className="card-body">
            {/* ユーザー追加用セクション（普段は非表示） */}
            {this.addComponent()}
            <table className="table table-bordered w-100 table-striped small">
              <thead>
                <tr>
                  <th scope="col" className="custom-td-width-number">
                    No
                  </th>
                  <th scope="col">ユーザー名</th>
                  <th scope="col">メールアドレス</th>
                  {/* <th scope="col">検証ステータス</th> */}
                  <th scope="col" className="custom-td-width-edit">
                    編集・削除
                    <a
                      data-tip="メールアドレスの変更（左のボタン）・ユーザーの削除（右のボタン）がご利用いただけます。"
                      data-html={true}
                      data-for="editToolTip"
                    >
                      <i className="fas fa-question-circle text-primary ml-2" />
                      <ReactTooltip id="editToolTip" effect="solid" place="top" html={true} />
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>{recordCreater}</tbody>
            </table>
            ※最後のユーザー・サインインユーザーを削除することはできません。
            <br />
            ※ユーザーは合計10人までご利用いただけます。
            <br />
          </div>
        </div>
        {/* Modal */}
        {/* 更新確認 */}
        {showModal({
          modalId: "updateConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: this.showUpdateUserInfoWithMessage(
            "以下のユーザーのメールアドレスを変更してもよろしいですか？"
          ),
          executeFunctionObject: this.updateUser
        })}
        {/* 追加成功 */}
        {showModal({
          modalId: "updateSuccess",
          modalTitle: (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2" />
              成功
            </span>
          ),
          modalBody: this.showUpdateUserInfoWithMessage(
            "以下のユーザーのメールアドレスを変更しました。"
          ),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
        {/* 追加確認 */}
        {showModal({
          modalId: "addConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: this.showAddUserInfoWithMessage("以下のユーザーを追加してもよろしいですか？"),
          executeFunctionObject: this.addUser
        })}
        {/* 追加成功 */}
        {showModal({
          modalId: "addSuccess",
          modalTitle: (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2" />
              成功
            </span>
          ),
          modalBody: this.showAddUserInfoWithMessage("以下のユーザーを追加しました。"),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.resetAddUser
        })}
        {/* 削除確認 */}
        {showModal({
          modalId: "deleteConfirm",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary mr-2" />
              確認
            </span>
          ),
          modalBody: this.showDeleteUserInfoWithMessage(
            "以下のユーザーを削除してもよろしいですか？"
          ),
          executeFunctionObject: this.deleteUser,
          executeButtonColor: "danger"
        })}
        {/* 削除成功 */}
        {showModal({
          modalId: "deleteSuccess",
          modalTitle: (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2" />
              成功
            </span>
          ),
          modalBody: this.showDeleteUserInfoWithMessage("以下のユーザーを削除しました。"),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
        {/* エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
