// 定数群を宣言
export const VAR_TYPE = {
  // NOTICE一覧のセット
	SET_NOTICE_LIST: "SET_NOTICE_LIST",
	SET_NOTICE_PAGE_NUMBER: "SET_NOTICE_PAGE_NUMBER",
  CLEAR_NOTICE_LIST: "RESET_NOTICE_LIST",
  // ローディングフラグのセット
  SET_NOTICE_LOADING_FLAG: "SET_NOTICE_LOADING_FLAG",
	RESET_NOTICE_LOADING_FLAG: "RESET_NOTICE_LOADING_FLAG",
	
	SET_NOTICE_NOT_EXIST_FLAG: "SET_NOTICE_NOT_EXIST_FLAG",
  RESET_NOTICE_NOT_EXIST_FLAG: "RESET_NOTICE_NOT_EXIST_FLAG",
};

// #########################   EB LIST
export const setNoticeList = (response,nowPageNumber) => {
  return {
    type: VAR_TYPE.SET_NOTICE_LIST,
    payload: {response,nowPageNumber}
  };
};
export const setNoticePageNumber = (nowPageNumber) => {
  return {
    type: VAR_TYPE.SET_NOTICE_PAGE_NUMBER,
    payload: {nowPageNumber}
  };
};
export const clearNoticeList = () => {
  return {
    type: VAR_TYPE.CLEAR_NOTICE_LIST,
    payload: {}
  };
};
export const setNoticeLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_NOTICE_LOADING_FLAG,
    payload: {}
  };
};
export const resetNoticeLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_NOTICE_LOADING_FLAG,
    payload: {}
  };
};
export const setNoticeNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_NOTICE_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetNoticeNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_NOTICE_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
	setNoticeList: setNoticeList,
	setNoticePageNumber: setNoticePageNumber,
	clearNoticeList: clearNoticeList,
	setNoticeLoadingFlag: setNoticeLoadingFlag,
	resetNoticeLoadingFlag: resetNoticeLoadingFlag,
	setNoticeNotExistFlag: setNoticeNotExistFlag,
	resetNoticeNotExistFlag: resetNoticeNotExistFlag,
};
