
import React from "react";
import ReactTooltip from "react-tooltip";

import statusGreen from "../../webfonts/StatusGreen.png";
import statusYellow from "../../webfonts/StatusYellow.png";
import statusRed from "../../webfonts/StatusRed.png";
import statusGrey from "../../webfonts/StatusGrey.png";


const HEALTH_MAPPING = {
	"Green": {
		message: "この実行環境は最新のヘルスチェックに合格しています。",
		toolTipName: "greenToolTip",
		iconClassName: "fas fa-check-circle text-success mr-2",
		imageSource: statusGreen
	},
	"Yellow": {
		message: "この実行環境は 1 つ以上のヘルスチェックに失格しています。",
		toolTipName: "yellowToolTip",
		iconClassName: "fas fa-exclamation-circle text-warning mr-2",
		imageSource: statusYellow
	},
	"Red": {
		message: "この実行環境は 3 つ以上のヘルスチェックで失格したか、<br/>実行環境のリソースが使用不可になっています。",
		toolTipName: "redToolTip",
		iconClassName: "fas fa-exclamation-circle text-danger mr-2",
		imageSource: statusRed
	},
	"Grey": {
		message: "この実行環境は更新中、停止中、もしくはデータを取得できない状態です。",
		toolTipName: "greyToolTip",
		iconClassName: "fas fa-minus-circle text-secondary mr-2",
		imageSource: statusGrey
	}
}

// 環境一覧・詳細用ヘルスアイコン
export function switchEnvHealth(health){
	let component = (
		<a
			data-tip={HEALTH_MAPPING[health].message}
			data-html={true}
			data-for={HEALTH_MAPPING[health].toolTipName}
		>
			<i className={HEALTH_MAPPING[health].iconClassName} />
			<ReactTooltip id={HEALTH_MAPPING[health].toolTipName} effect="solid" place="top" html={true} />
		</a>
	);
	return component
}

// トップページ用ヘルス画像
export function switchEnvHealthTop(health){
	let component = (
		<a
			data-tip={HEALTH_MAPPING[health].message}
			data-html={true}
			data-for={HEALTH_MAPPING[health].toolTipName}
		>
			<img className="image-rotate mr-2" src={HEALTH_MAPPING[health].imageSource} alt={health} />
			<ReactTooltip id={HEALTH_MAPPING[health].toolTipName} effect="solid" place="top" html={true} />
		</a>
	);
	return component
}





export function switchEnvHealth_old(health) {
	let component = null;
	switch (health) {
		case "Green":
			component = (
				<a
					data-tip="この実行環境は最新のヘルスチェックに合格しています。"
					data-html={true}
					data-for="greenToolTip"
				>
					<i className="fas fa-check-circle text-success mr-2" />
					<ReactTooltip id="greenToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Yellow":
			component = (
				<a
					data-tip="この実行環境は 1 つ以上のヘルスチェックに失格しています。"
					data-html={true}
					data-for="yellowToolTip"
				>
					<i className="fas fa-exclamation-circle text-warning mr-2" />
					<ReactTooltip id="yellowToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Red":
			component = (
				<a
					data-tip="この実行環境は 3 つ以上のヘルスチェックで失格したか、<br/>実行環境のリソースが使用不可になっています。"
					data-html={true}
					data-for="redToolTip"
				>
					<i className="fas fa-exclamation-circle text-danger mr-2" />
					<ReactTooltip id="redToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Grey":
			component = (
				<a
					data-tip="この実行環境は更新中です。"
					data-html={true}
					data-for="greyToolTip"
				>
					<i className="fas fa-minus-circle text-secondary mr-2" />
					<ReactTooltip id="greyToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;

		default:
			break;
	}
	return component
}

// トップページ用ヘルス画像
// 環境ヘルスによって表示するアイコン（現在は信号）を切り替える関数
export function switchEnvHealthTop_old(health) {
	let component = null;
	switch (health) {
		case "Green":
			component = (
				<a
					data-tip="この実行環境は最新のヘルスチェックに合格しています。"
					data-html={true}
					data-for="greenToolTip"
				>
					{/* <i className="fas fa-check-circle text-success" /> */}
					<img className="image-rotate mr-2" src={statusGreen} alt="Green" />
					<ReactTooltip id="greenToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Yellow":
			component = (
				<a
					data-tip="この実行環境は 1 つ以上のヘルスチェックに失格しています。"
					data-html={true}
					data-for="yellowToolTip"
				>
					{/* <i className="fas fa-exclamation-circle text-warning" /> */}
					<img className="image-rotate mr-2" src={statusYellow} alt="Yellow" />
					<ReactTooltip id="yellowToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Red":
			component = (
				<a
					data-tip="この実行環境は 3 つ以上のヘルスチェックで失格したか、<br/>実行環境のリソースが使用不可になっています。"
					data-html={true}
					data-for="redToolTip"
				>
					{/* <i className="fas fa-exclamation-circle text-danger" /> */}
					<img className="image-rotate mr-2" src={statusRed} alt="Red" />
					<ReactTooltip id="redToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;
		case "Grey":
			component = (
				<a data-tip="この実行環境は更新中、停止中、もしくはデータを取得できない状態です。" data-html={true} data-for="greyToolTip">
					{/* <i className="fas fa-minus-circle text-secondary" /> */}
					<img className="image-rotate mr-2" src={statusGrey} alt="Grey" />
					<ReactTooltip id="greyToolTip" effect="solid" place="top" html={true} />
				</a>
			);
			break;

		default:
			break;
	}
	return component;
}