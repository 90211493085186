import React from "react";

import { ConfirmSignIn } from "aws-amplify-react";

// どうやらAmplify　Configureは親コンポーネントにさえ書いてあればいいらしい
import { Auth } from "aws-amplify";
// import NavbarFooter from './NavbarFooter'
// import QRcode from "qrcode.react";
import { checkContact } from "./checkContact";

import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import $ from "jquery";
import { showModal } from "../common/showModal";
import { checkCodeLengthAndNumber } from "../common/cognitoInputValidation";
import { switchCognitoError } from "./switchCognitoError";

class MyConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
			mfa_code_for_sign_in: "",

			/**サインインボタンを連打すると、1回目のリクエストでトップページに遷移するのと同時に、
			 * 2回目のリクエストでエラーモーダルが表示される。
			 * その際エラーモーダルは消えるがグレーアウトが消えず、再読み込み以外で操作不能になる。
			 * ローディングで処理してしまうと、トップページに遷移した瞬間にローディングの解除が出来なくなるため、
			 * 解除不要な以下変数でボタンの制御を行う
			 */
			isDisabledButton: false,
			
			error_function: null,
			error_message: null,
		};
		this.setModalMessage = this.setModalMessage.bind(this)
	}
	
	// MFAコードの入力ボックスから起動し、Stateを更新する関数
  handleChangeMfaSignIn(event) {
    this.setState({ mfa_code_for_sign_in: event.target.value });
	}
	
	// モーダルで何も実行させないための関数
  emptyReturn() {
    return null;
	}
	
	// 入力規則チェック用アイコンを返す関数
  switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	
	// 入力規則チェック用のセクションを表示するための関数
  showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
            <tr>
              <th className="custom-td-width-totp">
                {this.switchValidationIcon(
                  checkCodeLengthAndNumber(this.state.mfa_code_for_sign_in)
                )}
                ワンタイムパスワード
              </th>
              <td>
                {this.switchValidationIcon(
                  checkCodeLengthAndNumber(this.state.mfa_code_for_sign_in)
                )}
                半角6桁の数字です。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

	// MFAコードでサインインを行う
  async confirm() {
		await this.setState({ isDisabledButton: true})
    const user = this.props.authData;
    const code = this.state.mfa_code_for_sign_in;
    const mfaType = user.challengeName;
		//   const mfaType = user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;
		try {
			// MFA認証を行い、成功すれば検証ORトップページに遷移する
			await Auth.confirmSignIn(user, code, mfaType)
			await checkContact.apply(this, [user]);
		} catch (error) {
			// エラー発生時にモーダルを表示する
			await this.setState({
				error_function: "ワンタイムパスワード認証",
				error_message: switchCognitoError(error)
			})
			await this.setState({ isDisabledButton: false})
			$("#generalFailure").modal();
		}
		// finally句でローディングを解除できない（解除前にページ遷移するため）
	}

	// エラーモーダル用メッセージを返す関数
	setModalMessage() {
		let message = "エラーが発生しました。";
		if (this.state.error_function !== null) {
			message = [
				`${this.state.error_function}に失敗しました。`,
				<br key={1}/>,
				`${this.state.error_message}`
			];
		}
		return message
	}

	// メインコンポーネント
  myConfirmSignIn() {
    let component = null;
    component = (
      <div>
        <div className="m-3">
          {/* <div>認証コードを入力してください。</div> <br /> */}
          <label htmlFor="inputMfaCode">2要素認証用アプリで生成した6桁のワンタイムパスワードを入力してください:</label>
					{/* 入力ボックスのグループ */}
          <div className="input-group mb-4">
						{/* 横並びの入力ボックスの前（左）に表示 */}
            <div className="input-group-prepend">
							{/* アイコン */}
              <span className="input-group-text">
                <i className="fas fa-key" id="addonMfaCode" />
              </span>
            </div>
						{/* 横並びの入力ボックスの後ろ（右）に表示 */}
            <input
              type="text"
              className="form-control"
              id="inputMfaCode"
              placeholder="ワンタイムパスワード"
              aria-describedby="addonMfaCode"
              autoComplete="new-password"
              onChange={event => this.handleChangeMfaSignIn(event)}
              value={this.state.mfa_code_for_sign_in}
            />
          </div>
					{/* 入力規則チェック用セクション */}
          {this.showValidationComponent()}
        </div>

				{/* サインインボタン */}
        <div className="m-3">
          <button
            className="btn btn-primary btn-block"
            onClick={() => this.confirm()}
						disabled={!checkCodeLengthAndNumber(this.state.mfa_code_for_sign_in)
							|| this.state.isDisabledButton}
          >
            サインイン
          </button>
        </div>
      </div>
    );
    return component;
  }

	// カードを表示するコンポーネント
  showCardComponent() {
    return (
      <div className="section d-flex justify-content-center">
        <div className="card custom-card-height general-card w-50 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
              <i className="fas fa-key mr-2" />
              ワンタイムパスワードを入力してサインイン
            </div>
          </div>
          <div className="card-body custom-scroll test_small">{this.myConfirmSignIn()}</div>
        </div>
      </div>
    );
  }
  showComponent() {
    var component = null;
    component = (
      <div className="">
        {/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
        <div className="content mybgcolor-grey mb-4">
          <div className="">
            <NavbarForLogin />
            <div id="" className="">
              <div id="" className="">
                <div id="" className="hiddenSidebarSignIn">
                  {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
									{showLoadingToast(this.state.loading)}
									{/* {showLoadingSpinner(this.state.loading)} */}
                </div>
                {/* カードコンポーネント */}
                {this.showCardComponent()}
              </div>
            </div>
						<Footer />
          </div>
        </div>
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
    return component;
  }

  render() {
		// AuthStateがMFA認証の場合だけ表示する
    if (this.props.authState === "confirmSignIn") {
      return this.showComponent();
    } else {
      return null;
    }
  }
}

export default MyConfirmSignIn;
