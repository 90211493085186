import { Auth, JS } from "aws-amplify";

// checkContact.apply(this.[args])として呼び出せば、this.changeStateを使用できる
// defaultを入れるとエラーが出る
export function checkContact(user){
    Auth.verifiedContact(user).then( (data)=>{
        // console.log(data)
        if (!JS.isEmpty(data.verified)) {
            // ログイン成功
            this.changeState('signedIn',user)
        } else {
						user = Object.assign(user,data)
						// メールアドレス・電話番号を検証する画面に遷移
            this.changeState('verifyContact',user)
        }
    })
}