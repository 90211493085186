import React from "react";

// 引数 = 値　となっているのは、デフォルト引数。値が渡されなかった場合のみ代入される値
export function showModal({
  modalTitle = "please input modal title.",
  modalBody = "please input modal body.",
  modalId = "default-modal",
  modalTitleSize = "h4",
  executeFunctionObject = emptyReturn,
  executeFunctionArgumentsArray = ["arg1", "arg2"],
	executeButtonLabel = "実行",
	executeButtonColor = "primary",
	showCancelButtonFlag = true,
	modalSize = "modal-lg"
} = {}) {
  let component = null;
  component = (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="basicModal"
      aria-hidden="true"
    >
      <div className={`modal-dialog ${modalSize}`}>
        <div className="modal-content">
          <div className="modal-header">
            <span className={`modal-title ${modalTitleSize}`}>{modalTitle}</span>
          </div>
          <div className="modal-body">{modalBody}</div>
          <div className="modal-footer">
            {showCancelButton(showCancelButtonFlag)}
            <button
              type="button"
              className={`btn btn-${executeButtonColor}`}
              data-dismiss="modal"
              onClick={() => executeFunctionObject.apply(this, executeFunctionArgumentsArray)}
            >
              {executeButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return component;
}

function showCancelButton(flag) {
  let component = null;
  if (flag) {
    component = (
      <button type="button" className="btn btn-default" data-dismiss="modal">
        キャンセル
      </button>
    );
  }
  return component;
}

function emptyReturn(){
	return null
}