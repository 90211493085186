import { VAR_TYPE, actions } from "../actions/EbEnvListSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	// 環境情報一覧のリストをそのまま入れる
	envList: [],
	// envList: {
	// 	// 初期値が配列でないとundefinedが入りバグる
	// 	productionEnvList: [],
	// 	stagingEnvList: []
	// },
	loadingListFlag: false,
	loadingListFlagArray: [],
};

// Storeを更新するための関数
export const envReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  EB LIST
		// 情報をセット
    case VAR_TYPE.SET_EB_ENV_LIST:
      return {
				...state,
				envList: action.payload.envList,
			};
		// リセット
		case VAR_TYPE.CLEAR_EB_ENV_LIST:
      return {
				...state,
				envList: [],
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: false
			};
	
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
