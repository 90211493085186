// export const COGNITO_IDENTITY_POOL_ID = "ap-northeast-1:c1e7fd4c-280c-4181-8585-b54194b450d8"
// export const COGNITO_USER_POOL_ID = "ap-northeast-1_BwSq7Bx1G"
// export const COGNITO_WEB_CLIENT_ID = "7o55ssj4prrthp07p6vblg8506"
// export const API_GATEWAY_ENDPOINT_INCLUDE_STAGE = "https://5u8bfs30vi.execute-api.ap-northeast-1.amazonaws.com/mnt"

// ##### 以上のパラメータはハードコードする

// ハードコード必須のパラメータを設定ファイルからインポート
import { 
	COGNITO_IDENTITY_POOL_ID,
	COGNITO_USER_POOL_ID,
	COGNITO_WEB_CLIENT_ID,
	API_GATEWAY_ENDPOINT_INCLUDE_STAGE
} from './environment_amplify_settings'

export const API_NAME = "stagingCognitoAuth"

export const environment = {
  // AWS Amplifyの設定
  amplify: {
    Auth: {
			// CognitoおよびAPIGateway
      identityPoolId: COGNITO_IDENTITY_POOL_ID,
      region: "ap-northeast-1",
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolWebClientId: COGNITO_WEB_CLIENT_ID
    },
    // api gateway with cognito auth
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint: API_GATEWAY_ENDPOINT_INCLUDE_STAGE,
          // regionを明記しないと、デフォルトでus-east-1となり、東京リージョンのAPIGWにアクセス出来ない
          region: "ap-northeast-1"
				}
      ]
    }
  },
};

export const API_PATH={
	ENV:{
		// 以下はDBまですべてget
		LIST:"/service/env/list",
		HEALTH:"/service/env/health",
		LOG:"/service/env/log",
		EVENT:"/service/env/event",
		DB:{
			INFO:"/service/env/db/info",
			LOG:{
				LIST:"/service/env/db/log/list",
				DETAIL:"/service/env/db/log/detail",
			}
		},
		// get, post, delete
		ACCESSKEY:"/service/env/accesskey",
		// {
		// 	LIST:"/service/env/accesskey/list",
		// 	ADD:"",
		// 	DELETE:"",
		// }
		// put ?,
		DEPLOY:"/service/env/deploy",
		// get, put
		VERSION:"/service/env/version",
		// {
		// 	LIST:"/service/env/",
		// 	BACK:"",
		// },
	},
	// get, post, put, delete  メソッドによって一覧、追加、更新、削除を使い分ける
	USER:"/user",
	// {
	// 	LIST:"/user/",
	// 	ADD:"/user/",
	// 	UPDATE:"/user/",
	// 	DELETE:"/user/",
	// },
	// get
	ACCOUNT:"/account",
	// get
	NOTICE:"/notice",
	// {
	// 	LIST:"",
	// 	// 面倒なので詳細も一緒に持ってくるか。どうせページングするし
	// 	DETAIL:"",
	// },
	BASTION: {
		// GIT: "/client/bastion/git"
		GIT: "/service/client/gitcredential"
	},
}

// font awesomeのアイコンを利用するためのクラス名
export const ICON_TYPE = {
  USER: "fas fa-user",
  PASSWORD: "fas fa-lock",
	CODE: "fas fa-key",
	TOGGLE: "fas fa-bars",
	SIGN_IN: "fas fa-sign-in-alt",
	SIGN_OUT: "fas fa-sign-out-alt"
};

export const BUTTON_TYPE = {
  BASE: "btn btn-block",
  PRIMARY: "btn-primary",
	SUCCESS: "btn-success",
	DANGER: "btn-danger",
	GRAY: "btn-secondary",
	SMALL: "btn-sm",
	CIRCLE: "rounded-pill",
	
};

export const CLASS = {
  SIDEBAR: {
    HIDDEN: "hiddenSidebar",
    SHOW: "showSidebar"
  },
	LOGIN: "loginForm",
	ROOT: "rootWrapper",
	INPUT_BOX: "form-control",
};

export const INPUT_TYPE = {
  TEXT: "text",
  PASSWORD: "password",
	RADIO: "radio",
	EMAIL: "email",
};

export const ID = {
  MY_LOGIN: {
    INPUT_USER: "inputUserName",
    INPUT_PASS: "inputPassword",
    BUTTON_SIGN_IN: "signInButton"
  },
  MY_FORGOT: {
    INPUT_USER: "forgotUserName",
    INPUT_PASS1: "forgotPassword",
    INPUT_PASS2: "forgotPasswordAgain",
    INPUT_CODE: "forgotCode"
  },
  MY_NEW_PASS: {
    INPUT_PASS1: "newPassword",
    INPUT_PASS2: "newPasswordAgain"
  }
};

//
export const VALUE = {
  BACK_SIGN_IN: "サインイン画面に戻る",
  MY_LOGIN: {
    INPUT_USER: "ユーザー名",
    INPUT_PASS: "パスワード",
    SIGN_IN: "サインイン",
    FORGOT: "パスワードを忘れた方はこちら",
    HEADER: "Sign In"
  },
  MY_FORGOT: {
    HEADER_USER: "パスワードを変更するユーザー名を入力",
    INPUT_USER: "ユーザー名",
    SEND_USER: "ユーザー名を送信",
    ATTENTION: {
      INPUT_USER: "※ここで入力したユーザーのメールアドレスに検証コードが送信されます。",
      INPUT_CODE: "※メールで送られた検証コードを入力してください。",
      INPUT_PASS:
        "※パスワードは8文字以上で、数字・特殊文字・大文字・小文字がそれぞれ1文字以上必要です。"
    },

    HEADER_CODE: "コードと新しいパスワードを入力",
    INPUT_CODE: "コード",
    INPUT_PASS1: "新しいパスワード",
    INPUT_PASS2: "新しいパスワード（確認）",
    SUBMIT_PASS: "新しいパスワードを設定",
    BACK_SIGN_IN: "サインイン画面に戻る"
  },
  MY_NEW_PASS: {
    HEADER_PASS: "新しいパスワードを入力",
    INPUT_PASS1: "新しいパスワード",
    INPUT_PASS2: "新しいパスワード（確認）",
    SUBMIT_PASS: "新しいパスワードを設定",
    BACK_SIGN_IN: "サインイン画面に戻る"
  }
};

// console.logやalertで表示するメッセージ
export const MESSAGE = {
  LOADING: {
    START: "start loading",
    FINISH: "finish loading"
  },
  ERROR: {
    SIGN_IN: "sign in failed",
    NO_INPUT: "入力されていない項目があります",
    NO_INPUT_OR_PASS_NOSAME: "入力されていない項目があるか、パスワードが一致していません",
    PASS_NOSAME: "パスワードが一致していません"
  },
  ATTENTION: {
    PASS_POLICY:
      "※パスワードは8文字以上で、数字・特殊文字・大文字・小文字がそれぞれ1文字以上必要です。"
  }
};

// Amplify固有の定数
export const AMPLIFY = {
  AUTH_STATE: {
    // 初回は必ずこのStateになる。サインインページを表示（ブラウザのローカルストレージに認証情報が格納されていない場合？）
    SIGN_IN: "signIn",
    // サインインが完了し、トップページが表示される
    SIGNED_IN: "signedIn",
    MFA: {
      // MFAコードの入力画面に遷移する
      INPUT: "confirmSignIn",
      // 初回サインイン時にMFAデバイスのセットアップを行う（シークレットキーを登録する画面）
      SETUP: "TOTPSetup"
    },
    // Emailや電話番号の「検証」属性が「FALSE」の場合に発生。検証するかスキップしてサインインするかを選択する画面に遷移する
    VERIFY: "verifyContact",
    PASSWORD: {
      // 初回サインイン時に、管理者が決めたパスワードをユーザー自身が変更する画面
      FIRST_SIGN_IN: "requireNewPassword",
      // パスワードを忘れた場合にリセットを行う
      FORGOT: "forgotPassword"
    }
  },
  CHALLENGE: {
    MFA: {
      // MFAの種類が、仮想MFAデバイス
      SOFTWARE: "SOFTWARE_TOKEN_MFA",
      // MFAの種類がSMS（今回は使わないはず）
      SMS: "SMS_MFA",
      // 初回サインイン時に、MFAデバイスのセットアップを行う
      SETUP: "MFA_SETUP"
    },
    PASSWORD: {
      // 初回サインイン時に、管理者が決めたパスワードをユーザー自身が変更する画面
      FIRST_SIGN_IN: "NEW_PASSWORD_REQUIRED"
    }
  },
  ERROR: {
    // amplify公式より転記、詳細は要調査。ここに書いてないエラーもキャッチする必要あり
    SIGN_IN: {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      NO_CONFIRM: "UserNotCongirmedException",
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      RESET_PASS: "PasswordResetRequiredException",
      // The error happens when the incorrect password is provided
      NO_AUTHORIZED: "NotAuthorizedException",
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      NO_USER: "UserNotFoundException"
    }
  }
};

// JSDOCを書いてしまうと、何故か補完機能がおかしくなる（オブジェクト名などの補完が効かなくなる）
export const CONST = {
	API_NAME: API_NAME,
	API_PATH: API_PATH,
  ICON_TYPE: ICON_TYPE,
  BUTTON_TYPE: BUTTON_TYPE,
  CLASS: CLASS,
  INPUT_TYPE: INPUT_TYPE,
  ID: ID,
  VALUE: VALUE,
  MESSAGE: MESSAGE,
  AMPLIFY: AMPLIFY
};
