
// Cognitoのエラーによって、メッセージを変える
export function switchCognitoError(error) {
	let message = "エラーが発生しました。";
	// console.log(error)
	// console.log(error.message)
	if ("code" in error) {
		let code = error.code;
		switch (code) {
			// case "UserNotFoundException":
			// 	// ユーザーが存在しない
			// 	message = "このユーザーは存在しません。お手数ですが入力内容を見直し、再度お試しください。";
			// 	break;
			case "InvalidParameterException":
				// パラメータが不正
				if (error.message == "Cannot reset password for the user as there is no registered/verified email or phone_number") {
					// 連絡手段が未検証か未登録（メッセージの最後にドットは入っていない）
					message = "ユーザーのメールアドレスが有効化されていない可能性があります。お手数ですがご確認のうえ、再度お試しください。"
					break;					
				}
				message = "いずれかの入力項目が正しくありません。お手数ですが入力内容を見直し、再度お試しください。";
				break;
			case "NotAuthorizedException":
				// 認証失敗。複数のエラーが存在する
				if (error.message == "Unauthenticated access is not supported for this identity pool.") {
					// ユーザー名が違う場合に発生しうるエラー
					message = "ユーザー名かパスワードが違います。お手数ですが入力内容を見直し、再度お試しください。"
					break;
				}
				if (error.message == "User password cannot be reset in the current state.") {
					// ユーザーのメールアドレスが未検証の時に、パスワードをリセットしようとした際のエラー
					// パスワードの有効期限が切れている場合にも発生する
					message = "ユーザーのメールアドレスが有効化されていないか、初回サインインがされていない可能性があります。お手数ですがご確認のうえ、再度お試しください。"
					break;
				}
				if (error.message == "Incorrect username or password.") {
					// ユーザー名かパスワードが違う
					message = "ユーザー名かパスワードが違います。お手数ですが入力内容を見直し、再度お試しください。";
					break;
				}
				if (error.message == "Password attempts exceeded") {
					// パスワードの試行回数を超えた
					message = "パスワードの試行回数が上限に達しました。お手数ですが時間を置いて、再度お試しください。"					
					break;
				}
				if (error.message == "Invalid session for the user, session can only be used once.") {
					// ユーザー作成直後に発生する謎のエラー。時間を置くと治る場合がある
					message = "内部エラーが発生しました。お手数ですが時間を置くか、ブラウザを更新して再度お試しください。"
					break;
				}
				if (error.message == "Temporary password has expired and must be reset by an administrator.") {
					// 仮パスワードの有効期限が切れいている
					message = "パスワードの有効期限が切れています。お手数ですが管理者にお問い合わせください。"					
					break;
				}
				if (error.message == "Invalid session for the user, session is expired.") {
					// MFA入力画面を開いてから長時間放置され、セッションが切れた
					message = "セッションの有効期限が切れています。お手数ですがブラウザを更新して再度お試しください。"					
					break;
				}
				message = "内部エラーが発生しました。お手数ですが時間を置くか、ブラウザを更新して再度お試しください。"
				break;
			case "PasswordResetRequiredException":
				// パスワードのリセットが必要（？）
				message = "パスワードの有効期限が切れています。お手数ですが管理者にお問い合わせください。";
				break;
			case "TooManyRequestsException":
				// リクエスト回数が多い
				message = "リクエスト回数が多すぎます。お手数ですが時間を置いて、再度お試しください。";
				break;
			case "LimitExceededException":
				// パスワードの試行回数が多い
				message = "リクエスト回数が多すぎます。お手数ですが時間を置いて、再度お試しください。";
				break;
			case "InvalidPasswordException":
				// パスワードの書式が無効
				message = "パスワードが正しくありません。お手数ですが入力内容を見直し、再度お試しください。";
				break;
			case "CodeMismatchException":
				// ワンタイムパスワードが予期したものではない
				message =
					"ワンタイムパスワードが正しくありません。お手数ですが入力内容を見直し、再度お試しください。";
				break;
			case "EnableSoftwareTokenMFAException":
					// ワンタイムパスワードの設定に失敗
				message =
					"ワンタイムパスワードが正しくありません。お手数ですが入力内容を見直し、再度お試しください。";
				break;
			case "ExpiredCodeException":
					// ワンタイムパスワードの有効期限が切れている
				message =
					"ワンタイムパスワードの有効期限が切れています。お手数ですがワンタイムパスワードを更新し、再度お試しください。";
				/**パスワード再設定でOTPが切れると以下エラー
				 * {"__type":"ExpiredCodeException","message":"Invalid code provided, please request a code again."}
				 */
				break;

			default:
				message = "内部エラーが発生しました。お手数ですが時間を置くか、ブラウザを更新して再度お試しください。"
				break;
		}
	}else if ("message" in error) {
		if (error.message == "Only radix 2, 4, 8, 16, 32 are supported") {
			// Amplify Special Case (Bug)
			message = "ユーザー名かパスワードが違います。お手数ですが入力内容を見直し、再度お試しください。"
		}
	}
	return message
}

// サインイン等に失敗したときに、2回目のリクエストで返ってくるエラー。これに対してモーダルは出ない
// NotAuthorizedException
// Unauthenticated access is not supported for this identity pool.
