import React, { Component } from "react";


import "./App.css";

// App.css の中身を以下に分散させる。以下のみを読み込んでCSSが適用されることはある程度確認
// import "./css/handlerAccount.css";
// import "./css/handlerClientEnv.css";
// import "./css/handlerCommon.css";
// import "./css/handlerEnv.css";
// import "./css/handlerLoading.css";
// import "./css/handlerLogin.css";
// import "./css/handlerNotice.css";
// import "./css/handlerTable.css";
// import "./css/handlerTop.css";
// import "./css/handlerUser.css"

import SwitchComponent from "./containers/SwitchComponent";

import {withAuthenticator} from 'aws-amplify-react'
// import Amplify from 'aws-amplify-react'
import { 
	// ConfirmSignIn, 
	ConfirmSignUp, 
	// ForgotPassword, 
	// RequireNewPassword, 
	// SignIn, 
	SignUp, 
	// VerifyContact, 
	// TOTPSetup 
} from 'aws-amplify-react'

import MyLogin from './components/auth/MyLogin'
import MyTOTPSetup from './components/auth/MyTOTPSetup'
import MyConfirmSignIn from './components/auth/MyConfirmSignIn'
import MyForgotPassword from './components/auth/MyForgotPassword'
import MyRequireNewPassword from './components/auth/MyRequireNewPassword'
import MyVerifyContact from './components/auth/MyVerifyContact'

class App extends Component {
  render() {
    return (
      <div>
        {/* AppコンポーネントはProviderでWrapされている→単にContainerを呼べばよい */}
        <SwitchComponent />
      </div>
    );
  }
}

// export default App;
// export default withAuthenticator(App)
export default withAuthenticator(App, false, [
  // 1.サインイン画面　<SignIn />
	<MyLogin />,
	
  // 2.MFA付きサインイン画面　<ConfirmSignIn />,
	<MyConfirmSignIn />,

	// 3.Email検証画面  <VerifyContact />
	<MyVerifyContact />,

	// 4.ユーザー登録画面：登録させたくないので、仮の画面でも作っておく  <SignUp />
	<SignUp />,

	// 5.ユーザー登録画面（MFA付き？？？）：登録させたくないので、仮の画面でも作っておく  <ConfirmSignUp />
	<ConfirmSignUp />,

	// 6.パスワードリセット画面　　<ForgotPassword />
	<MyForgotPassword />,
	
	// 7.初回サインイン時のパスワード変更画面  <RequireNewPassword />
  <MyRequireNewPassword />,

	// 8.初回サインイン時に、MFAのシークレットキーを設定するための画面  <TOTPSetup />
  <MyTOTPSetup />,
]);
