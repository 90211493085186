import { connect } from "react-redux";

import ClientEnvListSection from "../components/ClientEnvListSection";
import { actionCreater } from "../reducers/root";

// Stateを対象コンポーネントのPropsに渡す
function mapStateToProps(state) {
  return {
    clientEnvInformation:{
			clientEnvList: state.clientEnvListSection.clientEnvList,
			loadingListFlag: state.clientEnvListSection.loadingListFlag,			
		},
  };
}

function mapDispatchToProps(dispatch) {
  return {
		// envList: list
		setClientEnvList(clientEnvList){
			dispatch(actionCreater.clientEnvListSection.setClientEnvList(clientEnvList))
		},
		// 
		clearClientEnvList(){
			dispatch(actionCreater.clientEnvListSection.clearClientEnvList())
		},
		// envList: list
		setRebootClientEnv(clientEnvList){
			dispatch(actionCreater.clientEnvListSection.setRebootClientEnv(clientEnvList))
		},
		// 
		clearRebootClientEnv(){
			dispatch(actionCreater.clientEnvListSection.clearRebootClientEnv())
		},
		// 
		setClientEnvListLoadingFlag(){
			dispatch(actionCreater.clientEnvListSection.setClientEnvList())
		},
		// 
		resetClientEnvListLoadingFlag(){
			dispatch(actionCreater.clientEnvListSection.resetClientEnvListNotExistFlag())
		},
  };
}

// StateやDispatch済の関数を、後ろのコンポーネントのPropsに渡す
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientEnvListSection);
