import React from 'react'

// ReactをimportしていないとJSX関連のエラーが出る（HTMLタグが反応した）
// thisを使う必要性が無いため右記方法は使用しない。（呼び出し元からは、　showLoadingSpinner.apply(this)　として呼び出す）
export function showLoadingToast(isLoading) {
	let component = null;
  if (isLoading) {
    component = (
      <p className="loading-toast">
				
        <i className="fas fa-spinner fa-spin mr-2" />
				now loading...
      </p>
    );
  }
  return component
}
