import {CONST} from '../../environments/environment'
import {checkContact} from './checkContact'

export function checkAuthFlowApply(user){
	switch (user.challengeName) {
		case CONST.AMPLIFY.CHALLENGE.MFA.SOFTWARE:
		case CONST.AMPLIFY.CHALLENGE.MFA.SMS:
			// SOFTWAREとSMSのどちらの場合でも実行される
			// MFAデバイスのセットアップが済んでいるため、その認証コードで認証を行う
			this.changeState(CONST.AMPLIFY.AUTH_STATE.MFA.INPUT, user);
			break;
		case CONST.AMPLIFY.CHALLENGE.PASSWORD.FIRST_SIGN_IN:
			// 最初のサインイン時に、管理者が決めたパスワードをユーザー自身が変更する
			this.changeState(CONST.AMPLIFY.AUTH_STATE.PASSWORD.FIRST_SIGN_IN, user);
			break;
		case CONST.AMPLIFY.CHALLENGE.MFA.SETUP:
			// 最初のサインイン時に、MFAデバイスのセットアップを行う
			this.changeState(CONST.AMPLIFY.AUTH_STATE.MFA.SETUP, user);
			break;
		default:
			// どのケースにも該当しない場合
			// メールアドレス検証画面かサインイン後のトップページに遷移する
			checkContact.apply(this,[user])
			break;
	}
}