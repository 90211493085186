import React from "react";
import { ForgotPassword } from "aws-amplify-react";
// どうやらAmplify　Configureは親コンポーネントにさえ書いてあればいいらしい
import { Auth } from "aws-amplify";

import NavbarForLogin from "../common/NavbarForLogin";
import Footer from "../common/Footer";

// import { showLoadingSpinner } from "../common/loadingSpinner";
import {showLoadingToast} from '../common/loadingToast'

import { CONST } from "../../environments/environment";
import $ from "jquery";
import { showModal } from "../common/showModal";
import {
  // パスワード用バリデーション
  checkPasswordLength,
  checkPasswordContainsSpecialSymbol,
  checkPasswordContainsNumber,
  checkPasswordContainsLowerCase,
  checkPasswordContainsUpperCase,
	checkPasswordContainsSafeChar,

	checkUserNameLength,
	checkUserNameContainsSafeChar,

	checkCodeLengthAndNumber,
} from "../common/cognitoInputValidation";
import { switchCognitoError } from "./switchCognitoError";

class MyForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // deliveryはNullか否か、恐らく中に何を入れるかは関係ない。Amplifyの名残
      delivery: null,
      forgot_user_name: "",
      code: "",
      password1: "",
			password2: "",

			// isDisabledButton: false,
			
			error_function: null,
			error_message: null,
    };
    this.sendUserName = this.sendUserName.bind(this);
		this.submitForgotPasswordFunction = this.submitForgotPasswordFunction.bind(this);
		this.setModalMessage = this.setModalMessage.bind(this)
	}
	
	// ### Stateを更新する関数群
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }
  handleChangeUserName(event) {
    this.setState({ forgot_user_name: event.target.value });
  }
  handleChangePassword1(event) {
    this.setState({ password1: event.target.value });
  }
  handleChangePassword2(event) {
    this.setState({ password2: event.target.value });
	}

	// モーダルで何も実行させない用の関数
	emptyReturn(){ return null}

	// ユーザー名入力ボックスの全バリデーション結果を返す関数
	userNameAllValidationFlag() {
    return (
      checkUserNameLength(this.state.forgot_user_name) &&
      checkUserNameContainsSafeChar(this.state.forgot_user_name)
    );
	}
	
	// パスワード入力ボックスの全バリデーション結果を返す関数（パスワード一致チェックも含む）
	passwordAllValidationFlag() {
    return (
      checkPasswordLength(this.state.password1) &&
      checkPasswordContainsLowerCase(this.state.password1) &&
      checkPasswordContainsUpperCase(this.state.password1) &&
      checkPasswordContainsNumber(this.state.password1) &&
      checkPasswordContainsSpecialSymbol(this.state.password1) &&
			checkPasswordContainsSafeChar(this.state.password1) &&
			this.state.password1 === this.state.password2
    );
	}

	// 入力規則チェックのアイコンを返す関数
	switchValidationIcon(flag) {
    let validationIcon = <i className="far fa-square text-danger mr-2" />;
    if (flag) {
      validationIcon = <i className="far fa-check-square text-success mr-2" />;
    }
    return validationIcon;
	}
	
	// パスワード再設定時に、入力規則チェック用セクションを表示する関数
	showValidationComponent() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
						<tr>
							<th className="custom-td-width-totp-verify">
								{this.switchValidationIcon(checkCodeLengthAndNumber(this.state.code))}検証用ワンタイムパスワード
							</th>
							<td>
							{this.switchValidationIcon(checkCodeLengthAndNumber(this.state.code))}半角6桁の数字です。
							</td>
						</tr>
            <tr>
              <th scope="row" rowSpan="7">
                {this.switchValidationIcon(this.passwordAllValidationFlag())}パスワード
              </th>
              <td>
                {this.switchValidationIcon(checkPasswordLength(this.state.password1))}
                長さは8文字以上、256文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsLowerCase(this.state.password1))}
                小文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsUpperCase(this.state.password1))}
                大文字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsNumber(this.state.password1))}
                数字が1文字以上必要です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(
                  checkPasswordContainsSpecialSymbol(this.state.password1)
                )}
                次の特殊文字が1文字以上必要です。　特殊文字：　
                {"^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ `"}
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkPasswordContainsSafeChar(this.state.password1))}
                半角アルファベットの小文字・大文字、数字、上記特殊文字、「+」、「=」以外の文字はご使用いただけません。
              </td>
            </tr>
						<tr>
              <td>
                {this.switchValidationIcon(this.state.password1 === this.state.password2)}
                「新しいパスワード」と「新しいパスワード（確認）」でご入力いただいたパスワードが一致している必要があります。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
	}

	// パスワードを再設定するユーザー名の入力時に、入力規則チェック用セクションを表示する関数
	showValidationComponentUser() {
    return (
      <div className="small">
        入力項目は全て必須です。また、以下の入力規則を満たす必要があります。
        <br />
        <table className="table table-bordered w-100 table-sm">
          <tbody>
					<tr>
              <th scope="row" rowSpan="2" className="custom-td-width">
                {this.switchValidationIcon(this.userNameAllValidationFlag())}
                ユーザー名
              </th>
              <td>
                {this.switchValidationIcon(checkUserNameLength(this.state.forgot_user_name))}
                長さは1文字以上、128文字以下です。
              </td>
            </tr>
            <tr>
              <td>
                {this.switchValidationIcon(checkUserNameContainsSafeChar(this.state.forgot_user_name))}
                半角アルファベットの小文字・大文字、数字、「-」、「_」以外の文字はご使用いただけません。
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

	// エラーモーダルにメッセージを表示する関数
	setModalMessage() {
		let message = "エラーが発生しました。";
		if (this.state.error_function !== null) {
			message = [
				`${this.state.error_function}に失敗しました。`,
				<br key={1}/>,
				`${this.state.error_message}`
			];
		}
		return message
	}

	// パスワードを再設定するユーザー名を送信する関数
  async sendUserName() {
    try {
      await this.setState({ loading: true });
      await this.authForgotPassword(this.state.forgot_user_name);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "ユーザーへのメール送信",
				error_message: switchCognitoError(error)
			})
      $("#generalFailure").modal();
    } finally {
			// ローディングを終える
      await this.setState({ loading: false });
    }
	}
	// ユーザー名を送信し、成功したらStateを更新する関数
  async authForgotPassword(userName) {
    const data = await Auth.forgotPassword(userName);
    await this.setState({
      forgot_user_name: userName,
      delivery: data.CodeDeliveryDetails
    });
  }

	// パスワードを再設定する関数
  async submitForgotPasswordFunction() {
		// await this.setState({ isDisabledButton: true})
    try {
			// 一応一致チェックをしているが、一致していないとボタンを押せないため、不要か。
			if (this.state.password1 !== this.state.password2) {
				throw "パスワードが一致していません。"
			}
      const userName = this.state.forgot_user_name;
      const password = this.state.password1;
      const code = this.state.code;
      await this.setState({ loading: true });
      await this.authForgotPasswordSubmit(userName, code, password);
    } catch (error) {
			// エラーモーダルを表示する
			await this.setState({
				error_function: "パスワード変更",
				error_message: switchCognitoError(error)
			})
			// await this.setState({ isDisabledButton: false})
			$("#generalFailure").modal();
			// OTPが切れている場合、再読み込みしないと再度メールを送信できないため、ここでメール送信画面に戻す
			if (error.code === "ExpiredCodeException") {
				await this.setState({ delivery: null})
			}
    } finally {
			// ローディングを終える
      await this.setState({ loading: false });
    }
  }
	// パスワードを再設定後、Stateを更新し、サインイン画面に戻す関数
  async authForgotPasswordSubmit(userName, code, password) {
		await Auth.forgotPasswordSubmit(userName, code, password);
		await this.setState({ delivery: null });
		// ここで成功モーダルを入れた方が良いか？→うまくいかない（グレーにだけなってモーダルが出ない）
    await this.changeState("signIn");
  }

	// サインインに戻るボタンで呼び出す関数
	showSignIn(event){
		// aタグのhrefを打ち消す（URLが変わらないようにする）
		event.preventDefault()
		this.changeState(CONST.AMPLIFY.AUTH_STATE.SIGN_IN)
	}

	// ユーザー名送信用カードのボディ
  cardBodyUser() {
    let component = null;
    component = (
      <React.Fragment>
        {/* パスワードを変更するユーザー名を入力 */}
        <div>
				<i className="fas fa-info-circle text-primary mr-2" />この画面では、パスワードを再設定していただくことができます。<br/>
				<i className="fas fa-info-circle text-primary mr-2" />その際、ご本人様確認のため、メールでパスワードの再設定に必要な検証用ワンタイムパスワードをお送りします。<br/>
					<br/>
          <label htmlFor="forgotUserName"> ユーザー名： </label>
					{/* 入力ボックスグループ */}
          <div className={`input-group mb-3`}>
						{/* 手前にアイコンを表示する */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.USER} />
              </span>
            </div>
						{/* 後ろに入力ボックスを表示する */}
            <input
              type="text"
              className="form-control"
              id="forgotUserName"
              placeholder="ユーザー名"
              autoComplete="new-password"
              value={this.state.forgot_user_name}
              onChange={event => this.handleChangeUserName(event)}
            />
          </div>
        </div>
				{/* ユーザー名送信用の入力チェック用セクション */}
				{this.showValidationComponentUser()}
				{/* 注意書き */}
        <p> <br/>
				※パスワードリマインダーをご利用いただくには、<strong className="text-danger">メールアドレスが有効化</strong>されている必要があります。<br/>
					※メールアドレスが有効化されているかどうかは、ユーザー管理画面にてご確認いただけます。<br/>
					※こちらでご入力いただいたユーザーのメールアドレスに検証用ワンタイムパスワードが送信されます。<br/>
					※メールが届くまで少々お時間がかかる場合がございます。あらかじめ、ご了承ください。<br/>
				</p>
        <div className="mt-4">
          {/* ユーザー名送信ボタン */}
					<button
            className="btn btn-block btn-primary"
						onClick={() => this.sendUserName()}
						disabled={!this.userNameAllValidationFlag() || this.state.loading}
          >
            ユーザーにメールを送信
          </button>
					<hr/>
          {/* サインイン画面に戻るボタン */}
					<a
						href="#"
            className="text-primary"
            onClick={(event) => this.showSignIn(event)}
          >
            サインイン画面に戻る
          </a>
        </div>
      </React.Fragment>
    );
    return component;
	}
	
	// パスワード再設定用カードのボディ
  cardBodyCode() {
    let component = null;
    component = (
      <React.Fragment>
        {/* 認証コード入力 */}
				{/* 入力ボックス */}
        <div>
				<i className="fas fa-info-circle text-primary mr-2" />パスワードはセキュリティ向上のため、ページ下部に記載されている入力規則を満たす必要があります。<br/>
				<br/>
          <label htmlFor="forgotCode"> メールでお送りした6桁の検証用ワンタイムパスワードを入力してください： </label>
					{/* 入力ボックスグループ */}
          <div className={`input-group mb-3`}>
						{/* 手前にアイコン */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.CODE} />
              </span>
            </div>
						{/* 後ろに入力ボックス */}
            <input
              type="text"
              className="form-control"
              id="forgotCode"
              placeholder="検証用ワンタイムパスワード"
              autoComplete="new-password"
              value={this.state.code}
              onChange={event => this.handleChangeCode(event)}
            />
          </div>
        </div>
				{/* 入力ボックス：終了 */}
        {/* 新しいパスワード入力 */}
				{/* 入力ボックス */}
        <div>
          {/* labelが指定されている場合のみ表示する */}
          <label htmlFor="forgotPassword"> 新しいパスワード： </label>
          <div className={`input-group mb-3`}>
            {/* iconが指定されている場合のみ表示する */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.PASSWORD} />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              id="forgotPassword"
              placeholder="新しいパスワード"
              autoComplete="new-password"
              value={this.state.password1}
              onChange={event => this.handleChangePassword1(event)}
            />
          </div>
        </div>
				{/* 入力ボックス：終了 */}
        {/* 新しいパスワード入力（確認） */}
        
				{/* 入力ボックス */}
        <div>
          {/* labelが指定されている場合のみ表示する */}
          <label htmlFor="forgotPasswordAgain"> 新しいパスワード（確認）： </label>
          <div className={`input-group mb-3`}>
            {/* iconが指定されている場合のみ表示する */}
            <div className="input-group-prepend">
              {/* iタグに直接input-group-textを書くと、アイコンがずれる */}
              <span className="input-group-text">
                <i className={CONST.ICON_TYPE.PASSWORD} />
              </span>
            </div>
            <input
              type="password"
              className="form-control"
              id="forgotPasswordAgain"
              placeholder="新しいパスワード（確認）"
              autoComplete="new-password"
              value={this.state.password2}
              onChange={event => this.handleChangePassword2(event)}
            />
          </div>
        </div>
				{/* 入力ボックス：終了 */}
				{/* パスワード再設定用の入力規則チェック用セクション */}
				{this.showValidationComponent()}
        <div className="mt-4">
					{/* 新しいパスワード設定ボタン */}
					<button
              className="btn btn-primary btn-block"
							onClick={() => this.submitForgotPasswordFunction()}
							disabled={!(this.passwordAllValidationFlag() && checkCodeLengthAndNumber(this.state.code) ) 
								|| this.state.loading}
            >
              新しいパスワードを設定
            </button>
						<hr/>
          {/* サインイン画面に戻るボタン */}
					<a
						href="#"
            className="text-primary"
            onClick={(event) => this.showSignIn(event)}
          >
            サインイン画面に戻る
          </a>
        </div>
      </React.Fragment>
    );
    return component;
  }

	// ユーザー名送信用カード全体
	showCardComponentUser() {
    return (
      <div className="section d-flex justify-content-center">
        {/* 全方向のマージンを入れているから中央寄せになっている？ */}
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
							<i className="fas fa-user mr-2" />
							パスワードを再設定するユーザー名を入力
            </div>
          </div>
          <div className="card-body custom-scroll test_small">
						{this.cardBodyUser()}
          </div>
        </div>
      </div>
    );
	}
	
	// ユーザー名送信用のコンポーネント全体
  showComponentUser() {
    let component = (
			<div className="">
					{/* ################################### */}
					{/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
          <div className="content mybgcolor-grey mb-4">
            <div className="">
              <NavbarForLogin />
              <div id="" className="">
                <div id="" className="">
                  <div id="" className="hiddenSidebarSignIn">
                    {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                    {showLoadingToast(this.state.loading)}
                  </div>
                  {/* カード */}
                  {this.showCardComponentUser()}
                </div>
              </div>
							<Footer />
            </div>
          </div>
          {/* ################################### */}
          {/* モーダル：エラー */}
          {showModal({
            modalId: "generalFailure",
            modalTitle: (
              <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                エラー
              </span>
            ),
            modalBody: this.setModalMessage(),
            executeButtonLabel: "OK",
            showCancelButtonFlag: false,
            executeFunctionObject: this.emptyReturn
					})}
        </div>
    );
    return component;
	}
	
	// パスワード再設定用カード全体
	showCardComponentCode() {
    return (
      <div className="section d-flex justify-content-center">
        {/* 全方向のマージンを入れているから中央寄せになっている？ */}
        <div className="card custom-card-height general-card w-75 mt-1">
          <div className="card-header h4 text-center">
            <div className="">
							<i className="fas fa-lock mr-2" />
							検証用ワンタイムパスワードと新しいパスワードを入力
            </div>
          </div>
          <div className="card-body custom-scroll test_small">
						{this.cardBodyCode()}
          </div>
        </div>

      </div>
    );
	}
	// パスワード再設定用コンポーネント全体
  showComponentCode() {
    let component = (
			<div className="">
					{/* ################################### */}
					{/* .contentで高さを確保している。mygbcolor-greyで背景をグレーにしている */}
          <div className="content mybgcolor-grey mb-4">
            <div className="">
              <NavbarForLogin />
              <div id="" className="">
                <div id="" className="">
                  <div id="" className="hiddenSidebarSignIn">
                    {/* Navbarの下にDivとしてローディング画面を入れると、Navbarの開閉に合わせて位置が変わる */}
                    {showLoadingToast(this.state.loading)}
                  </div>
                  {/* カード */}
                  {this.showCardComponentCode()}
                </div>
              </div>
							<Footer />
            </div>
          </div>
          {/* ################################### */}
          {/* モーダル：エラー */}
          {showModal({
            modalId: "generalFailure",
            modalTitle: (
              <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                エラー
              </span>
            ),
            modalBody: this.setModalMessage(),
            executeButtonLabel: "OK",
            showCancelButtonFlag: false,
            executeFunctionObject: this.emptyReturn
					})}
        </div>
    );
    return component;
  }

	// ユーザー名送信用とパスワード再設定用コンポーネントを切り替える
  showComponent() {
    let component = null;
    if (this.props.authState === CONST.AMPLIFY.AUTH_STATE.PASSWORD.FORGOT) {
      if (this.state.delivery == null) {
        component = this.showComponentUser();
      } else {
        component = this.showComponentCode();
      }
    }
    return component;
  }

  render() {
    return this.showComponent();
  }
}

export default MyForgotPassword;
