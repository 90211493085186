import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import { CONST, environment } from "../environments/environment";
import { showModal } from "./common/showModal";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

import { switchEnvHealthTop } from './common/switchEnvHealth'

// import { RESULT_LIMIT, NEW_NOTICE_DATE } from "./NoticeSection";
import {showTopEnvListByApplicationName, executeGetEnvList, SERVICE_TYPE} from './common/handlerEnv'
import {RESULT_LIMIT, NEW_NOTICE_DATE, executeGetNoticeList} from './common/handlerNotice'
import {executeGetUserList} from './common/handlerUser'
import {executeGetAccount} from './common/handlerAccount'

// トップページに表示するお知らせ件数の最大（例えばRESULT_LIMITが3の場合は、3件しか表示されない）
const NOTICE_LIMIT = 5;

class TopSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // お知らせ表示用
      noticeTitle: "",
			noticeDate: "",
			noticeStartPublishDate: "",
      noticeBody: "",
      // ページング1回あたりに取得するお知らせ件数
      result_limit: 3,
      // ページング番号（1~count(*)）
      page_number: 1,

      // エラー発生時のメッセージと発生機能名を管理
      error_message: null,
			error_function: null,
			
			// FOR TEST
			test_app_dict: {"app1": {}, "app2": {}},
			test_env_dict: {},
    };
		this.setModalMessage = this.setModalMessage.bind(this);
		this.recordCreater = this.recordCreater.bind(this)
  }
  // ここでユーザー名の取得を行う。しかしユーザー名はSwitchComponentで表示する
  componentDidMount() {
    // console.log(this.props.is_sign_out);
    // console.log(this.props);
    // ここでローディングスピナーを普通に表示しても微妙→やるならユーザー名専用のスピナー
    // ここでIf文を入れることで、サインアウト時にAuth関数を動かさなくて済む
    if (!this.props.is_sign_out && this.props.loginUserName === "") {
			// サインアウトしていない＋ユーザー名が取得できていない場合だけ実行
			// console.log("CognitoIdentityServiceProvider." + environment.amplify.Auth.userPoolWebClientId + ".LastAuthUser")
			// console.log( localStorage.getItem("CognitoIdentityServiceProvider." + environment.amplify.Auth.userPoolWebClientId + ".LastAuthUser" ) )
			// ローカルストレージからユーザー名を取得する
			let userName = localStorage.getItem("CognitoIdentityServiceProvider." + environment.amplify.Auth.userPoolWebClientId + ".LastAuthUser" )
			this.props.setLoginUserName(userName);
      // this.getUserName();
    }
    if (
      !this.props.is_sign_out &&
      !this.props.noticeInformation.loadingListFlag &&
      this.props.noticeInformation.noticeList.length === 0
    ) {
			// サインアウトしていない＋お知らせリストが無い＋お知らせ読み込み済みではない場合だけ実行
      this.getNoticeList(1);
    }
    if (
      !this.props.is_sign_out &&
      this.props.userList.length === 0 &&
      !this.props.loadingUserFlag
    ) {
			// サインアウトしていない＋ユーザーリストが無い＋ユーザー読み込み済みではない場合だけ実行
      this.getUserList();
    }
    if (
      !this.props.is_sign_out &&
      !this.props.ebInformation.loadingListFlag &&
      this.props.ebInformation.envList.length === 0
    ) {
			// サインアウトしていない＋環境リストが無い＋環境読み込み済みではない場合だけ実行
      this.getEnvList();
    }
    if (!this.props.is_sign_out && !this.props.loadingAccountFlag) {
			// サインアウトしていない＋契約情報読み込み済みではない場合だけ実行
      this.getAccount();
		}
	}
	
	// ログインユーザー名を取得する関数
  async getUserName() {
    await this.props.startLoading();
    let userInfo = await Auth.currentUserInfo();
    let userName = userInfo.username;
    await this.props.setLoginUserName(userName);
    await this.props.finishLoading();
  }

  // =====<お知らせ一覧>================================================================
  // 【機能1】お知らせ一覧取得
  async getNoticeList(pageNumber) {
    try {
      await this.props.startLoading();
      await this.props.setNoticeLoadingFlag();
      await this.props.setTopNoticeLoadingFlag();
			
			let response = await executeGetNoticeList(pageNumber)

      // Top, NoticeセクションのどちらのStoreにもセットする
      await this.props.setTopNoticeList(response, pageNumber);
      await this.props.setNoticeList(response, pageNumber);
    } catch (error) {
      // エラー発生時のみローディングフラグをリセットする
      await this.props.resetNoticeLoadingFlag();
			await this.props.resetTopNoticeLoadingFlag();
			// エラーモーダルを表示する
      await this.setState({
        error_function: "お知らせ一覧取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
    } finally {
      this.props.finishLoading();
    }
  }
  // お知らせ本文のモーダルボディ
  showBodyComponentModal() {
    let component = null;
    component = (
      <div className="">
        <div className="text-center h3">
          <strong className=" underline-blue">{this.state.noticeTitle}</strong>
        </div>
				{/* 更新日時は秒を含んでいる。「:SS」を切り取って表示 */}
        <div className="text-right h5 mt-4">{this.state.noticeDate.slice(0, -3)}</div>
        <div className="" />
        <hr />
        <div className="h4 mt-2">{this.newLineCreater(this.state.noticeBody)}</div>
      </div>
    );
    // }
    return component;
	}
	// 改行コード込みのお知らせ本文を受け取り、改行した状態で返す
  newLineCreater(string) {
    let lines = [];
    string.split("\n").forEach((line, index) => {
      lines.push(
        <div key={index}>
          {line}
          <br />
        </div>
      );
    });
    return lines;
	}
	// お知らせ本文のモーダルを表示する
  async setNoticeBody(event, date, title, body) {
		// リンクでURLが変わらないようにする
    event.preventDefault();
    await this.setState({
      noticeBody: body,
      noticeTitle: title,
			noticeDate: date,
    });
    $("#noticeBodyModal").modal();
	}
	// 現在は使っていないが、取得結果が0件だった時にメッセージを表示する用
  showNotExist(flag, message) {
    let notExistMessage = null;
    if (flag) {
      notExistMessage = message;
    }
    return notExistMessage;
	}
	// お知らせ一覧画面に遷移する
  showNotice(event) {
    event.preventDefault();
    this.props.showNotice();
	}
	// お知らせに「New」を付けるか切り替える
	switchNewIcon(start_date_string){
		let new_icon = null
		// ブラウザの言語設定によっては、日本時間を取得しない場合がある
		let now_date = new Date()
		let start_date = new Date(start_date_string)
		let delta_mili_seconds = now_date - start_date
		let delta_date = delta_mili_seconds / 1000 / 60 / 60 / 24
		// 掲載開始日からNEW_NOTICE_DATE日以内ならNewを表示する
		if ( delta_date <= NEW_NOTICE_DATE ) {
			new_icon = <strong><i className="text-danger ml-2">New !!</i></strong>
		}
		return new_icon
	}
  // =====<終了：お知らせ一覧>================================================================

  // =====<環境一覧>================================================================
  // 【機能2】EB環境一覧取得
  async getEnvList() {
    try {
      // await console.log("start env loading");
      await this.props.startLoading();
      await this.props.setListLoadingFlag();
      await this.props.setTopListLoadingFlag();
			
			let response = await executeGetEnvList()

      // Top, Envセクションの両方のStoreに格納する
      await this.props.setTopEbEnvList(response);
      await this.props.setEbEnvList(response);
      // await console.log(this.props.ebInformation.envList);
    } catch (error) {
			// エラーモーダルを表示する
      await this.setState({
        error_function: "実行環境一覧取得",
        error_message: "お手数ですが時間を置き、再度お試しください。"
      });
      $("#generalFailure").modal();
      // エラー発生時のみローディングフラグをリセットする
      await this.props.resetListLoadingFlag();
      await this.props.resetTopListLoadingFlag();
    } finally {
      // await console.log("stop env loading");
      await this.props.finishLoading();
    }
	}
	// 環境詳細テスト
	showEnvDetail(event, env_name, rds_name, env_endpoint, app_type){
		event.preventDefault()
		this.props.showEnvDetail(env_name, rds_name, env_endpoint, app_type)
	}
	// 環境リストを受け取り、表に展開する関数
  recordCreater(array) {
    let list = [];
    array.map((data, index) => {
      list.push(
        <tr key={index}>
					{/* ヘルスを独立した列に表示する */}
          <td>{switchEnvHealthTop(data.EnvHealth)}</td>
          <td>
						{/* ヘルスを環境名と一緒に表示する場合 */}
						{/* {this.switchEnvHealth(data.EnvHealth)} */}
						
						{/* 環境詳細テスト */}
						<strong>
						<a href="#" className="text-primary" onClick={(event) => this.showEnvDetail(event, data.EnvName, data.dbInstanceId, (data.EnvNumber === 0 ? "http://" : "https://") + data.CNAME, SERVICE_TYPE[data.applicationType])}>
						{data.EnvName}</a>
						</strong>
						{/* 環境詳細テスト */}
          </td>
					{/* 取ってきているが、表示しないデータ */}
          {/* <td> {data.EnvNumber} </td> */}
          {/* <td> {data.EnvHealth} </td>
          <td> {data.AppHealth} </td>
          <td> {data.EnvOperationalStatus} </td> */}
          <td> {data.VersionLabel} </td>
          {/* <td> {data.UpdateDate} </td> */}
          {/* <td> {data.dbInstanceId} </td> */}
          <td>
            <a
              href={`${data.EnvNumber === 0 ? "http://" : "https://"}${data.CNAME}`}
              className="text-primary"
              target="blank"
              rel="noreferrer noopener"
            >
              {/* https://{data.CNAME} */}
							{/* 表示するURLは、DBコネクト環境はHTTP、Web環境はHTTPS固定
							※環境作成時の申込書にHTTPSか否かを選択する欄は設けない。DBコネクト環境はWPの制約でHTTPアクセス固定のため */}
							{data.EnvNumber === 0 ? "http://" : "https://"}{data.CNAME}
            </a>
          </td>
        </tr>
      );
      return null;
    });
    return list;
	}
	showProductionEnvList(envList){
		let component = null
		// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
		envList = typeof envList === "undefined" ? [] : envList
		// 環境が存在しない場合はセクションごと表示しない
		if (envList.length > 0) {
			component = (
				<div className="card-body custom-scroll m-3 border border-primary">
					<div className="h5 mb-4">
						<i className="fas fa-window-restore mr-2" />
						{SERVICE_TYPE["P"]}
					</div>
					{/* AWSアプリケーションごとに環境一覧をテーブルで表示する */}
					{/* {this.showEnvListByApplicationName()} */}
					{showTopEnvListByApplicationName({envList: this.props.ebInformation.envList.productionEnvList, recordFunctionObject: this.recordCreater})}
					{/* 縦に表示したい場合 */}
					{/* {this.showEnvListByApplicationNameVertical()} */}
				</div>
			)
		}
		return component
	}
	showStagingEnvList(envList){
		let component = null
		// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
		envList = typeof envList === "undefined" ? [] : envList
		// 環境が存在しない場合はセクションごと表示しない
		if (envList.length > 0) {
			component = (
				<div className="card-body custom-scroll m-3 border border-warning">
					<div className="h5 mb-4">
						<i className="fas fa-window-restore mr-2" />
						{SERVICE_TYPE["S"]}
					</div>
					{/* AWSアプリケーションごとに環境一覧をテーブルで表示する */}
					{/* {this.showEnvListByApplicationName()} */}
					{showTopEnvListByApplicationName({envList: this.props.ebInformation.envList.stagingEnvList, recordFunctionObject: this.recordCreater})}
					{/* 縦に表示したい場合 */}
					{/* {this.showEnvListByApplicationNameVertical()} */}
				</div>
			)
		}
		return component
	}
  // =====<終了：環境一覧>================================================================

  // =====<ユーザー　一覧>================================================================
  // 【機能3】ユーザー一覧取得
  async getUserList() {
    try {
			// 裏で実行するため、ローディングスピナーを表示させない
      // await this.props.startLoading();
      await this.props.setUserLoadingFlag();
      await this.props.setTopUserLoadingFlag();
			
			let response = await executeGetUserList()

			// console.log(response.users);
			// トップページにユーザーを表示させなくしたため、廃止
      // await this.props.setTopUsers(response.users);
      await this.props.setUsers(response.users);
    } catch (error) {
      await this.props.resetUserLoadingFlag();
      await this.props.resetTopUserLoadingFlag();
      // 裏で処理させたいだけなので、エラーモーダルは表示させない
      // $("#generalFailure").modal();
    } finally {
      // this.props.finishLoading();
    }
  }
  // =====<終了：ユーザー　一覧>================================================================

	// =====<契約情報（裏で実行だけしておく）>================================================================
	// 【機能4：契約情報参照】
  async getAccount() {
    try {
			// 裏で処理させたいため、ローディングスピナーを表示させない
      // await this.props.startLoading();
      await this.props.setLoadingFlag();
      await this.props.setTopAccountLoadingFlag();
			
			let response = await executeGetAccount()

      await this.props.setAccountInformation(response);
    } catch (error) {
      // 裏で動かしたいだけのため、何もしない。
      // $("#generalFailure").modal();
      await this.props.resetLoadingFlag();
      await this.props.resetTopAccountLoadingFlag();
    } finally {
      // this.props.finishLoading();
    }
  }
  // =====<終了：契約情報>================================================================

	// エラーモーダル用
  emptyReturn() {
    return null;
	}
	// エラーモーダルにメッセージをセットする
  setModalMessage() {
    let message = "エラーが発生しました。";
    if (this.state.error_function !== null) {
      message = [
        `${this.state.error_function}に失敗しました。`,
        <br key={1}/>,
        `${this.state.error_message}`
      ];
    }
    return message;
  }
  render() {
    // お知らせリストを分解してテーブルのレコードを作成する
    const recordCreater = this.props.noticeInformation.noticeList.map((data, index) => {
			// お知らせをNOTICE_LIMITより多く表示させない
      if (index >= NOTICE_LIMIT) {
        return null;
      }
      return (
        <tr key={index}>
          {/* slice(始端、終端)：0文字目から始め、末尾3文字「:SS」を切り取る */}
					<td> {data.startPublishDate.slice(0, -3)} </td>
          <td>
						<strong>
            <a
              href="#noticeBody"
              className="text-primary"
              onClick={event =>
                this.setNoticeBody(event, data.startPublishDate, data.noticeTitle, data.noticeBody)
              }
            >
              {data.noticeTitle}
            </a>
						</strong>
						{this.switchNewIcon(data.startPublishDate)}
          </td>
        </tr>
      );
    });
    return (
      <div className="section containe-fluid">

				{/* トップページのタイトル表示用セクション */}
        <div className="row">
          <div className="col-sm-12">
            <span className="h2">
              <i className="fas fa-home mr-2" />
              トップページ
            </span>
          </div>
        </div>

				{/* お知らせ一覧表示用セクション */}
        <div className="row mt-2">
          <div className=" col-sm-12">
            <div className="card custom-top-card-height-dummy">
              <div className="card-header h5">
                <div className="float-left mt-2">
                  <i className="fas fa-bell mr-2" />
                  お知らせ（最新5件）
                </div>
                {/* 更新ボタン */}
                <button
                  className={`btn btn-light float-right`}
                  onClick={() => this.getNoticeList(1)}
                  data-tip="お知らせ一覧が更新されます。"
                  data-html={true}
                  data-for="refreshNoticeToolTip"
                >
                  <i className="fas fa-sync-alt" />
                  <ReactTooltip id="refreshNoticeToolTip" effect="solid" place="left" html={true} />
                </button>
              </div>
              <div className="card-body custom-scroll">
                <table className="table table-bordered custom-table-width table-striped h5">
                  <thead>
                    <tr>
                      <th scope="col" className="custom-td-width-eb-day-long">
                        日時
                      </th>
                      <th scope="col" className="custom-td-width-message">
                        タイトル
                        <a
                          data-tip="タイトルのリンクをクリックすると、そのお知らせの本文が表示されます。"
                          data-html={true}
                          data-for="noticeTitleToolTip"
                        >
                          <i className="fas fa-question-circle text-primary ml-2" />
                          <ReactTooltip
                            id="noticeTitleToolTip"
                            effect="solid"
                            place="top"
                            html={true}
                          />
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">{recordCreater}</tbody>
                </table>
                <a href="#" className="text-primary" onClick={event => this.showNotice(event)}>
                  過去のお知らせを確認するにはこちらをクリック
                </a>
              </div>
            </div>
          </div>
        </div>

				{/* 環境一覧用セクション */}
        <div className="row mt-2">
          <div className="col-sm-12 mb-3">
            <div className="card">
              <div className="card-header h5">
                <div className="float-left mt-2">
                  <i className="fas fa-cubes mr-2" />
                  実行環境一覧
                </div>
                {/* 更新ボタン */}
                <button
                  className={`btn btn-light float-right`}
                  onClick={() => this.getEnvList()}
                  data-tip="アプリケーション・実行環境の一覧が更新されます。"
                  data-html={true}
                  data-for="refreshEnvToolTip"
                >
                  <i className="fas fa-sync-alt" />

                  <ReactTooltip id="refreshEnvToolTip" effect="solid" place="left" html={true} />
                </button>
              </div>

							{/* small */}
							{/* 本番環境一覧 */}
							{this.showProductionEnvList(this.props.ebInformation.envList.productionEnvList)}

							{/* 検証環境一覧 */}
							{this.showStagingEnvList(this.props.ebInformation.envList.stagingEnvList)}
							
            </div>
          </div>
        </div>


        {/* モーダル：お知らせ本文 */}
        {showModal({
          modalId: "noticeBodyModal",
          modalTitle: "",
          modalBody: this.showBodyComponentModal(),
          executeButtonLabel: "OK",
          executeFunctionObject: this.emptyReturn,
          showCancelButtonFlag: false
        })}
        {/* モーダル：エラー */}
        {showModal({
          modalId: "generalFailure",
          modalTitle: (
            <span className="text-danger">
              <i className="fas fa-exclamation-circle mr-2" />
              エラー
            </span>
          ),
          modalBody: this.setModalMessage(),
          executeButtonLabel: "OK",
          showCancelButtonFlag: false,
          executeFunctionObject: this.emptyReturn
        })}
      </div>
    );
  }
}
export default TopSection;
