import { VAR_TYPE, actions } from "../actions/ClientEnvListSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	// 環境情報一覧のリストをそのまま入れる
	clientEnvList: [],
	loadingListFlag: false,
};

// Storeを更新するための関数
export const clientEnvReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  EB LIST
		// 情報をセット
    case VAR_TYPE.SET_CLIENT_ENV_LIST:
      return {
				...state,
				clientEnvList: action.payload.clientEnvList,
			};
		// リセット
		case VAR_TYPE.CLEAR_CLIENT_ENV_LIST:
      return {
				...state,
				clientEnvList: [],
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_CLIENT_ENV_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_CLIENT_ENV_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: false
			};
	
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
