import React, { Component } from "react";
import { showModal } from "../common/showModal";
import $ from "jquery";
// import logo from "../../webfonts/WebPerformerWhite.png";
import logo from '../../webfonts/WPC_Logotype_white.png'

class NavbarCSS extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-dark bg-dark sticky-top">
					{/* タイトル */}
          <span className="navbar-brand">
            <img className="image-resize mr-2" src={logo} alt="Web Performer" />
            {/* WebPerformer Cloud */}
          </span>

					{/* 左マージンをAutoにすると、表示が右に寄るらしい */}
          <ul className="nav ml-auto">
            {/* 画面幅を狭めると2行になってしまう。これはexpand-mdでレスポンシブが起動している */}
            <li className="nav-item">
              <span className="navbar-text text-white mr-3">
                {/* {console.log(this.props.login_user_name)} */}
                <i className="fas fa-user mr-2" />
                ユーザー名：{this.props.login_user_name}
              </span>
            </li>

            <li className="nav-item">
              <button
                className="btn btn-dark nav-link text-white"
                onClick={() => $("#signOutNavbar").modal()}
              >
                <i className="fas fa-sign-out-alt mr-2" />
                サインアウト
              </button>
            </li>
          </ul>
        </nav>
        {/* modal */}
        {showModal({
          modalId: "signOutNavbar",
          modalTitle: (
            <span>
              <i className="fas fa-info-circle text-primary" />
              　確認
            </span>
          ),
          modalBody: "サインアウトしてもよろしいですか？",
          executeFunctionObject: this.props.signOutFunction
        })}
      </React.Fragment>
    );
  }
}

export default NavbarCSS;
