import { VAR_TYPE, actions } from "../actions/TopSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	// Notice一覧のリストをそのまま入れる
	noticeList: [],
	totalRecordNumber: null,
	nowPageNumber: null,
	loadingNoticeFlag: false,

	// 環境情報一覧のリストをそのまま入れる
	envList: [],
	// envList: {
	// 	// 初期値が配列でないとundefinedが入りバグる
	// 	productionEnvList: [],
	// 	stagingEnvList: []
	// },
	loadingListFlag: false,

	userList: [],
	loadingUserFlag: false,

	loadingAccountFlag: false,
};

// Storeを更新するための関数
export const topReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  NOTICE LIST
		// 情報をセット
    case VAR_TYPE.SET_TOP_NOTICE_LIST:
      return {
				...state,
				noticeList: action.payload.response.noticeList,
				totalRecordNumber: action.payload.response.totalRecordNumber,
				nowPageNumber: action.payload.nowPageNumber,
			};
		// リセット
		case VAR_TYPE.CLEAR_TOP_NOTICE_LIST:
      return {
				...state,
				noticeList: [],
				totalRecordNumber: null,
				nowPageNumber: null,
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_TOP_NOTICE_LOADING_FLAG:
      return {
				...state,
				loadingNoticeFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_TOP_NOTICE_LOADING_FLAG:
      return {
				...state,
				loadingNoticeFlag: false
			};
		
		// ###################  EB LIST
		// 情報をセット
    case VAR_TYPE.SET_TOP_EB_ENV_LIST:
      return {
				...state,
				envList: action.payload.envList,
			};
		// リセット
		case VAR_TYPE.CLEAR_TOP_EB_ENV_LIST:
      return {
				...state,
				envList: [],
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_TOP_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_TOP_LIST_LOADING_FLAG:
      return {
				...state,
				loadingListFlag: false
			};

		// 全てのユーザー情報をセットする
    case VAR_TYPE.SET_TOP_USERS:
      return {
				...state,
				userList: action.payload.userList,
			};
		// ユーザー情報を更新する
		case VAR_TYPE.UPDATE_TOP_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を変更する
			let tmpUserList = state.userList
			tmpUserList[action.payload.index].email = action.payload.email
      return {
				...state,
				userList: tmpUserList,
			};
		// ユーザー情報を削除する
		case VAR_TYPE.DELETE_TOP_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を削除する
			// index番目のユーザーから1人を削除する
			state.userList.splice(action.payload.index,1)
      return {
				...state,
				// sliceすることで新しい配列にする（React画面を再描画させるため）
				userList: state.userList.slice(),
			};
		// ユーザー情報を追加する
		case VAR_TYPE.ADD_TOP_USER:
			// Actionから受け取った引数を用いて、ユーザー情報を追加する
      return {
				...state,
				// 恐らくリストとリストを連結する関数のため、リストで囲む
				userList: state.userList.concat([action.payload.user]),
			};
		// 全てのユーザー情報をリセットする
		case VAR_TYPE.CLEAR_TOP_USERS:
      return {
				...state,
				userList: [],
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_TOP_USER_LOADING_FLAG:
      return {
				...state,
				loadingUserFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_TOP_USER_LOADING_FLAG:
      return {
				...state,
				loadingUserFlag: false
			};


		// ローディングフラグをセット
		case VAR_TYPE.SET_TOP_ACCOUNT_LOADING_FLAG:
      return {
				...state,
				loadingAccountFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_TOP_ACCOUNT_LOADING_FLAG:
      return {
				...state,
				loadingAccountFlag: false
			};
	
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
