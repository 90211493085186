// 定数群を宣言
export const VAR_TYPE = {
  // Git Credentialのセット
  SET_BASTION_GIT_CREDENTIAL: "SET_BASTION_GIT_CREDENTIAL",
  CLEAR_BASTION_GIT_CREDENTIAL: "RESET_BASTION_GIT_CREDENTIAL",
  // ローディングフラグのセット
  SET_GIT_CREDENTIAL_LOADING_FLAG: "SET_GIT_CREDENTIAL_LOADING_FLAG",
	RESET_GIT_CREDENTIAL_LOADING_FLAG: "RESET_GIT_CREDENTIAL_LOADING_FLAG",
	
	SET_GIT_CREDENTIAL_NOT_EXIST_FLAG: "SET_GIT_CREDENTIAL_NOT_EXIST_FLAG",
	RESETGIT_CREDENTIAL_NOT_EXIST_FLAG: "RESET_GIT_CREDENTIAL_NOT_EXIST_FLAG"
};

// #########################   
export const setBastionGitCredential = bastionGitCredential => {
  return {
    type: VAR_TYPE.SET_BASTION_GIT_CREDENTIAL,
    payload: {bastionGitCredential: bastionGitCredential}
  };
};
export const clearBastionGitCredential = () => {
  return {
    type: VAR_TYPE.CLEAR_BASTION_GIT_CREDENTIAL,
    payload: {}
  };
};
export const setGitCredentialLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_GIT_CREDENTIAL_LOADING_FLAG,
    payload: {}
  };
};
export const resetGitCredentialLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_GIT_CREDENTIAL_LOADING_FLAG,
    payload: {}
  };
};
export const setGitCredentialNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_GIT_CREDENTIAL_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetGitCredentialNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_GIT_CREDENTIAL_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
	setBastionGitCredential: setBastionGitCredential,
  clearBastionGitCredential: clearBastionGitCredential,
  setGitCredentialLoadingFlag: setGitCredentialLoadingFlag,
	resetGitCredentialLoadingFlag: resetGitCredentialLoadingFlag,
	setGitCredentialNotExistFlag: setGitCredentialNotExistFlag,
	resetGitCredentialNotExistFlag: resetGitCredentialNotExistFlag,
};
