import React, { Component } from "react";
// import EbEnvListSection from "./EbEnvListSection";
import EbEnvListSection from '../containers/EbEnvListSection'
// import EbEnvDetailSection from "./EbEnvDetailSection";
import EbEnvDetailSection from '../containers/EbEnvDetailSection'
// import AccessKeySection from "./AccessKeySection";
import AccessKeySection from '../containers/AccessKeySection'
// import AppliVersionSection from "./AppliVersionSection";
import AppliVersionSection from '../old/container/AppliVersionSection'
// import AppliDeploySection from "./AppliDeploySection";
import AppliDeploySection from '../containers/AppliDeploySection'

const CONST_LOCAL = {
  SWITCH_TYPE: {
    LIST: "LIST",
    DETAIL: "DETAIL",
    ACCESS_KEY: "ACCESS_KEY",
    VERSION: "VERSION",
    DEPLOY: "DEPLOY"
  }
};

export default class EbSwitchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
			envName: "",
			envEndpoint: "",
			versionLabel: "",
			applicationName: "",
			rdsName: null,
			envIndex: null,
			appType: "環境",
      // 初期状態では環境一覧を表示する
      switchType: CONST_LOCAL.SWITCH_TYPE.LIST
		};
		this.showEnvList = this.showEnvList.bind(this)
		this.showEnvDetail = this.showEnvDetail.bind(this)
		this.showEnvAccessKey = this.showEnvAccessKey.bind(this)
		this.showEnvVersion = this.showEnvVersion.bind(this)
		this.showEnvDeploy = this.showEnvDeploy.bind(this)
  }

  componentWillMount() {
    // console.log(this.props);
		// console.log(this.props.ebInfomation);
    // this.props.setEbEnvList([1,2,3])
  }

	// 環境情報に関する各コンポーネントを表示する関数
  showEnvList() {
    this.setState({
      switchType: CONST_LOCAL.SWITCH_TYPE.LIST
    });
  }
  showEnvDetail(event, envName, envEndpoint, rdsName, appType) {
		event.preventDefault()
    this.setState({
      switchType: CONST_LOCAL.SWITCH_TYPE.DETAIL,
			envName: envName,
			envEndpoint: envEndpoint,
			rdsName: rdsName,
			appType: appType,
    });
  }
  showEnvAccessKey(event, applicationName) {
		event.preventDefault()
    this.setState({
      switchType: CONST_LOCAL.SWITCH_TYPE.ACCESS_KEY,
      applicationName: applicationName
    });
	}
  showEnvVersion(envName, rdsName) {
    this.setState({
      switchType: CONST_LOCAL.SWITCH_TYPE.VERSION,
      envName: envName,
			rdsName: rdsName,
    });
  }
  showEnvDeploy(event, envName, versionLabel, applicationName, applicationType) {
		event.preventDefault()
    this.setState({
      switchType: CONST_LOCAL.SWITCH_TYPE.DEPLOY,
      envName: envName,
			versionLabel: versionLabel,
			applicationName: applicationName,
			appType: applicationType,
    });
  }

  // 表示するコンポーネントをローカルStateで切り替える
  // ここで恐らくアクセスキー・バージョン管理・デプロイ・ログ・RDSログも切り替える必要がある
  switchComponent() {
		let component = null
		// 初期値では環境情報一覧コンポーネントを表示する
    let listSection = (
			<EbEnvListSection
            startLoading={this.props.startLoading}
            finishLoading={this.props.finishLoading}

						showEnvDetail={this.showEnvDetail}
						showEnvAccessKey={this.showEnvAccessKey}
						showEnvVersion={this.showEnvVersion}
						showEnvDeploy={this.showEnvDeploy}
          />
		);
    switch (this.state.switchType) {
			case CONST_LOCAL.SWITCH_TYPE.LIST:
				component = listSection
        break;
      case CONST_LOCAL.SWITCH_TYPE.DETAIL:
        component = (
          <EbEnvDetailSection
            startLoading={this.props.startLoading}
            finishLoading={this.props.finishLoading}

						showEnvList={this.showEnvList}
						envName={this.state.envName}
						envEndpoint={this.state.envEndpoint}
						rdsName={this.state.rdsName}
						appType={this.state.appType}
          />
        );
        break;
      case CONST_LOCAL.SWITCH_TYPE.ACCESS_KEY:
        component = (
          <AccessKeySection
            startLoading={this.props.startLoading}
            finishLoading={this.props.finishLoading}

						showEnvList={this.showEnvList}
						applicationName={this.state.applicationName}
          />
        );
        break;
      case CONST_LOCAL.SWITCH_TYPE.VERSION:
        component = (
          <AppliVersionSection
            startLoading={this.props.startLoading}
            finishLoading={this.props.finishLoading}

						showEnvList={this.showEnvList}
						envName={this.state.envName}
          />
        );
        break;
      case CONST_LOCAL.SWITCH_TYPE.DEPLOY:
        component = (
          <AppliDeploySection
            startLoading={this.props.startLoading}
            finishLoading={this.props.finishLoading}

						showEnvList={this.showEnvList}
            envName={this.state.envName}
						versionLabel={this.state.versionLabel}
						applicationName={this.state.applicationName}
						applicationType={this.state.appType}
          />
        );
        break;
			default:
				// 通常は起こりえない
				component = listSection
				break;
		}
		return component
  }

  render() {
    return this.switchComponent();
  }
}
