import { VAR_TYPE, actions } from "../actions/EbEnvDetailSection";

// Storeの定義
const initialState = {
	// 初期状態では何も設定されていない
	// 環境情報詳細の配列？オブジェクト？をそのまま入れる
	// envDetail: []
	envDetail: {},
	loadingDetailFlag: false,
};

// Storeを更新するための関数
export const envReducer = (state = initialState, action) => {
  switch (action.type) {
		// ###################  EB DETAIL
		// 情報をセット
		case VAR_TYPE.SET_EB_ENV_DETAIL:
			// getEnvHealthAndRdsInfo.pyより情報を取得
			let tmpEnvDetail = state.envDetail
			// 現在の辞書にKEY：環境名、VALUE：Lambdaのレスポンスそのまま　を加える
			tmpEnvDetail[action.payload.envDetail.envHealth.envName] = action.payload.envDetail
      return {
				...state,
				// envDetail: action.payload.envDetail,
				envDetail: tmpEnvDetail,
			};
		// リセット
		case VAR_TYPE.CLEAR_EB_ENV_DETAIL:
      return {
				...state,
				envDetail: {},
			};
		// ローディングフラグをセット
		case VAR_TYPE.SET_DETAIL_LOADING_FLAG:
      return {
				...state,
				loadingDetailFlag: true
			};
		// リセット
		case VAR_TYPE.RESET_DETAIL_LOADING_FLAG:
      return {
				...state,
				loadingDetailFlag: false
			};
		
    default:
      // 何も更新しない　→　stateをそのまま返す
      return state;
  }
};

// このReducerで使用するすべてのActionを、ActionCreaterという統一された名前でExportする
// →すると、どのReducerからも同じ名前でActionをすべてImportすることが可能になる
export const actionCreater = {
  actions
};
