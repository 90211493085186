// 定数群を宣言
export const VAR_TYPE = {
  // EB DEPLOYのセット
  SET_EB_ENV_DEPLOY: "SET_EB_ENV_DEPLOY",
  CLEAR_EB_ENV_DEPLOY: "RESET_EB_ENV_DEPLOY",
  // ローディングフラグのセット
  SET_DEPLOY_LOADING_FLAG: "SET_DEPLOY_LOADING_FLAG",
  RESET_DEPLOY_LOADING_FLAG: "RESET_DEPLOY_LOADING_FLAG"
};

// #########################   EB DEPLOY
export const setEbEnvDeploy = envDeploy => {
  return {
    type: VAR_TYPE.SET_EB_ENV_DEPLOY,
    payload: {envDeploy}
  };
};
export const clearEbEnvDeploy = () => {
  return {
    type: VAR_TYPE.CLEAR_EB_ENV_DEPLOY,
    payload: {}
  };
};
export const setDeployLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_DEPLOY_LOADING_FLAG,
    payload: {}
  };
};
export const resetDeployLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_DEPLOY_LOADING_FLAG,
    payload: {}
  };
};

export const actions = {	
	setEbEnvDeploy: setEbEnvDeploy,
  clearEbEnvDeploy: clearEbEnvDeploy,
  setDeployLoadingFlag: setDeployLoadingFlag,
  resetDeployLoadingFlag: resetDeployLoadingFlag,
};
