
import React from "react";
import ReactTooltip from "react-tooltip";
import {API, Auth} from 'aws-amplify'

import {CONST} from '../../environments/environment'

// 1つのアプリケーションに属する環境の数（Web環境＋DBコネクト環境。現時点では2固定）
export const NUMBER_OF_ENV = 2
// 本番・検証の区分を管理DBと対応づける
export const SERVICE_TYPE = {
	"P": "Multi",
	"S": "Single",
}

// 環境一覧取得APIの実行用
export async function executeGetEnvList(){
	// 認証情報を取得
	let session = await Auth.currentSession();
	// 
	let api_name = CONST.API_NAME;
	let path = CONST.API_PATH.ENV.LIST;
	let my_init = {
		headers: {
			Authorization: session.getIdToken().getJwtToken()
		},
		queryStringParameters: {
			accessToken: session.getAccessToken().getJwtToken()
		}
	};
	// たまに取れていないっぽい→Lambdaのタイムアウトが返ってきてる。account=30s,info=20sに設定
	let response = await API.get(api_name, path, my_init);
	// エラー時はThrowする
	if ("errorMessage" in response) {
		throw response;
	}
	return response
}

// 
const BG_COLOR = [
	"custom-bgcolor-even",
	"custom-bgcolor-odd"
]

// ### アプリケーションごとに環境一覧を表示するコンポーネント
export function showEnvListByApplicationName({
	envList, 
	recordFunctionObject, 
	// recordFunctionArgumentsArray
} = {}) {
	// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
	envList = typeof envList === "undefined" ? [] : envList
	let list = [];
	// 1契約ごとの環境数→現在「2」で固定
	let number_of_env = NUMBER_OF_ENV
	// アプリケーションごとにループ（環境2個区切り。本当はLambda側で処理した方が良い）
	for (let i = 0; i <= envList.length - 1; i += number_of_env) {
		// アプリケーション内の環境を切り出す（終端は含まない）
		let application_env_list = envList.slice(i, i + number_of_env);
		list.push(
			<div key={i} className={`${BG_COLOR[(i / number_of_env) % 2]}`}>
				<span className="h5 ml-2">
					<i className="fas fa-window-maximize mr-2" />
					{/* サービスタイプの表示（P：本番、S：検証） */}
					{/* <span className="mr-3">{SERVICE_TYPE[envList[i].applicationType]}</span> */}
					アプリケーション名: {envList[i].applicationName}
					{/* <button
						className="btn btn-primary ml-5"
						onClick={event =>
							this.props.showEnvAccessKey(
								event,
								this.props.ebInformation.envList[i].applicationName
							)
						}
						data-tip="アプリケーションに対応するアクセスキー管理画面に遷移します。"
						data-html={true}
						data-for="accessKeyToolTip"
					>
						<i className="fas fa-key mr-2" />
						アクセスキー管理
						<ReactTooltip id="accessKeyToolTip" effect="solid" place="top" html={true} />
					</button> */}
				</span>

				<table className="table table-bordered custom-table-width table-striped mt-2 mb-4">
					<thead>
						<tr>
							{/* <th scope="col" className="custom-td-width-4">環境ID</th> */}
							<th scope="col" className="custom-td-width-eb-env-name-long">
								実行環境名
								<a
									data-tip="実行環境名のリンクをクリックすると、その実行環境の詳細情報（ログやイベントなど）の表示画面に遷移します。"
									data-html={true}
									data-for="envNameToolTip"
								>
									<i className="fas fa-question-circle text-primary ml-2" />
									<ReactTooltip id="envNameToolTip" effect="solid" place="top" html={true} />
								</a>
							</th>
							{/* <th scope="col" className="custom-td-width-eb-env-num">
									環境番号
								</th> */}
							{/* <th scope="col" className="custom-td-width-eb-env-health">
									環境のヘルス
								</th>
								<th scope="col" className="custom-td-width-eb-app-health">
									アプリのヘルス
								</th>
								<th scope="col" className="custom-td-width-eb-env-status">
									環境構成
								</th> */}
							<th scope="col" className="custom-td-width-eb-app-ver">
								バージョンラベル
							</th>
							{/* <th scope="col" className="custom-td-width-eb-day-long">
									更新日時
								</th> */}
							{/* 1か所幅未指定のカラムが無いと、幅の固定が出来ない */}
							<th scope="col" className="custom-td-width-eb-env-name-long">
								データベース名
							</th>
							<th scope="col" className="custom-td-width-eb-env-name-long">
								URL
							</th>
							{/* <th scope="col" className="custom-td-width-eb-access">
									アクセスキー
								</th> */}
							<th scope="col" className="custom-td-width-eb-deploy">
								デプロイ・
								<br />
								バージョン管理
								<a
									data-tip="リンクをクリックすると、その実行環境のデプロイ・バージョン管理画面に遷移します。"
									data-html={true}
									data-for="versionToolTip"
								>
									<i className="fas fa-question-circle text-primary ml-2" />
									<ReactTooltip id="versionToolTip" effect="solid" place="top" html={true} />
								</a>
							</th>
						</tr>
					</thead>
					<tbody>
						{/* {this.recordCreater(application_env_list)} */}
						{recordFunctionObject.apply(this, [application_env_list])}
					</tbody>
				</table>
			</div>
		);
	}
	return list;
}

// アプリケーションごとに環境一覧を表示するコンポーネント
export function showTopEnvListByApplicationName({envList, recordFunctionObject} = {}) {
	// envListにデータがセットされるまではundefinedが渡ってきてエラーになる
	envList = typeof envList === "undefined" ? [] : envList
	let list = [];
	// 1契約ごとの環境数→現在「2」で固定
	let number_of_env = NUMBER_OF_ENV
	// アプリケーションごとにループ（環境2個区切り。本当はLambda側で処理した方が良い）
	for (let i = 0; i <= envList.length - 1; i += number_of_env) {
		// アプリケーション内の環境を切り出す（終端は含まない）
		let application_env_list = envList.slice(i, i + number_of_env);
		list.push(
			<div key={i} className={`${BG_COLOR[(i / number_of_env) % 2]}`}>
				<span className="h5 ml-2">
					<i className="fas fa-window-maximize mr-2" />
					{/* サービスタイプの表示（P：本番、S：検証） */}
					{/* <span className="mr-3">{SERVICE_TYPE[envList[i].applicationType]}</span> */}
					アプリケーション名: {envList[i].applicationName}{" "}
				</span>

				<table className="table table-bordered custom-table-width table-striped mt-2 mb-4">
					<thead>
						<tr>
							<th scope="col" className="custom-td-width-top-env">
								ヘルス
							</th>
							<th scope="col" className="custom-td-width-eb-env-name-long">
								実行環境名
								<a
									data-tip="実行環境名のリンクをクリックすると、その実行環境の詳細情報（ログやイベントなど）の表示画面に遷移します。"
									data-html={true}
									data-for="envNameTopToolTip"
								>
									<i className="fas fa-question-circle text-primary ml-2" />
									<ReactTooltip id="envNameTopToolTip" effect="solid" place="top" html={true} />
								</a>
							</th>
							<th scope="col" className="custom-td-width-eb-app-ver">
								バージョンラベル
							</th>
							{/* <th scope="col" className="custom-td-width-eb-env-name-long">
								データベース名
							</th> */}
							<th scope="col" className="custom-td-width-eb-env-name-long">
								URL
							</th>
						</tr>
					</thead>
					<tbody>
						{/* {this.recordCreater(application_env_list)} */}
						{/* apply(this, arg_list)で「this」を関数に渡せる。arg_listは引数の配列 */}
						{recordFunctionObject.apply(this, [application_env_list])}
					</tbody>
				</table>
			</div>
		);
	}
	return list;
}


// 現在は使用していないが、環境リストを縦に表示する用コンポーネント
// export function showTopEnvListByApplicationNameVertical() {
// 	let list = [];
// 	for (let i = 0; i <= this.props.ebInformation.envList.length - 1; i += 2) {
// 		let application_env_list = this.props.ebInformation.envList.slice(i, i + 2);
// 		list.push(
// 			<React.Fragment key={i}>
// 				<span className="h5">
// 					<i className="fas fa-window-restore mr-2" />
// 					アプリケーション名: {this.props.ebInformation.envList[i].applicationName}{" "}
// 				</span>

// 				<table className="table table-bordered custom-table-width mt-2 mb-2">
// 					<tbody>
// 						<tr>
// 							<th scope="row" className="custom-td-width-top-env">
// 								ヘルス</th>
// 							<td>{switchEnvHealthTop(application_env_list[0].EnvHealth)}</td>
// 							<td>{switchEnvHealthTop(application_env_list[1].EnvHealth)}</td>
// 						</tr>
// 						<tr>
// 							<th scope="row" className="">
// 								実行環境名
// 							</th>
// 							<td>{application_env_list[0].EnvName}</td>
// 							<td>{application_env_list[1].EnvName}</td>
// 						</tr>
// 						<tr>
// 							<th scope="row" className="">
// 								バージョンラベル
// 							</th>
// 							<td>{application_env_list[0].VersionLabel}</td>
// 							<td>{application_env_list[1].VersionLabel}</td>
// 						</tr>
// 						<tr>
// 							{/* <th scope="col" className="custom-td-width-eb-env-name-long">
// 								データベース名
// 							</th> */}
// 							<th scope="row" className="">
// 								URL
// 							</th>
// 							<td>{application_env_list[0].CNAME}</td>
// 							<td>{application_env_list[1].CNAME}</td>
// 						</tr>
// 					</tbody>
// 				</table>
// 			</React.Fragment>
// 		);
// 	}
// 	return list;
// }