// 定数群を宣言
export const VAR_TYPE = {
  // 環境一覧のセット
  SET_CLIENT_ENV_LIST: "SET_CLIENT_ENV_LIST",
  CLEAR_CLIENT_ENV_LIST: "RESET_CLIENT_ENV_LIST:",

  // 再起動のセット
  SET_REBOOT_CLIENT_ENV: "SET_REBOOT_CLIENT_ENV",
  CLEAR_REBOOT_CLIENT_ENV: "CLEAR_REBOOT_CLIENT_ENV:",

  // ローディングフラグのセット
  SET_CLIENT_ENV_LIST_LOADING_FLAG: "SET_CLIENT_ENV_LIST_LOADING_FLAG",
	RESET_CLIENT_ENV_LIST_LOADING_FLAG: "RESET_CLIENT_ENV_LIST_LOADING_FLAG",
	
	SET_CLIENT_ENV_LIST_NOT_EXIST_FLAG: "SET_CLIENT_ENV_LIST_NOT_EXIST_FLAG",
	RESET_CLIENT_ENV_LIST_NOT_EXIST_FLAG: "RESET_CLIENT_ENV_LIST_NOT_EXIST_FLAG"
};

// #########################   EB LIST

export const setClientEnvList = clientEnvList => {
  return {
    type: VAR_TYPE.SET_CLIENT_ENV_LIST,
    payload: {clientEnvList}
  };
};
export const clearClientEnvList = () => {
  return {
    type: VAR_TYPE.CLEAR_CLIENT_ENV_LIST,
    payload: {}
  };
};

export const setRebootClientEnv = clientEnvList => {
  return {
    type: VAR_TYPE.SET_REBOOT_CLIENT_ENV,
    payload: {clientEnvList}
  };
};
export const clearRebootClientEnv = () => {
  return {
    type: VAR_TYPE.CLEAR_REBOOT_CLIENT_ENV,
    payload: {}
  };
};

export const setClientEnvListLoadingFlag = () => {
  return {
    type: VAR_TYPE.SET_CLIENT_ENV_LIST_LOADING_FLAG,
    payload: {}
  };
};
export const resetClientEnvListLoadingFlag = () => {
  return {
    type: VAR_TYPE.RESET_CLIENT_ENV_LIST_LOADING_FLAG,
    payload: {}
  };
};
export const setClientEnvListNotExistFlag = () => {
  return {
    type: VAR_TYPE.SET_CLIENT_ENV_LIST_NOT_EXIST_FLAG,
    payload: {}
  };
};
export const resetClientEnvListNotExistFlag = () => {
  return {
    type: VAR_TYPE.RESET_CLIENT_ENV_LIST_NOT_EXIST_FLAG,
    payload: {}
  };
};

export const actions = {
  setClientEnvList: setClientEnvList,
  clearClientEnvList: clearClientEnvList,
  setRebootClientEnv: setRebootClientEnv,
  clearRebootClientEnv: clearRebootClientEnv,
	setListLoadingFlag: setClientEnvListLoadingFlag,
	resetListLoadingFlag: resetClientEnvListLoadingFlag,
	setClientEnvListNotExistFlag: setClientEnvListNotExistFlag,
  resetClientEnvListNotExistFlag: resetClientEnvListNotExistFlag,
};
